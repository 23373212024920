import keycloak from "../../keycloak";


export const hasWritePermission = (participants, marketNode) => {
    return hasPermission(participants, marketNode, "Write")
}

export const hasDetailedPermission = (participants, marketNode) => {
    return hasPermission(participants, marketNode, "Detailed")

}

const hasPermission = (participants, marketNode, permissionName) => {
    const group = keycloak.idTokenParsed.fullPathGroup.flatMap(e => e.split("/"));
    const res = participants
        .filter(
            e => e.marketNode.id === marketNode.id
                && group.includes(e.participant.name)
                && e.permissionName.name === permissionName)
    return res.length > 0;
}

