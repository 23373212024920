import React from "react";
import {Button} from "primereact/button";
import InfoLabel from "../../Label/InfoLabel";
import {downloadExcel, downloadValidationReportZip} from "../../../../../../api/file/download-service";
import {showError} from "../../../../../../api/notification/ToastManager";

const ValidationReport = ({filePath, btnStyle, status, summary, validationMsg, validationMsgStyle}) => {
    const fileName = filePath !== null ? filePath.replace(/^.*[\\\/]/, '') : "";
    const downloadExcelByPath = async () => {
        try {
            if (fileName.toLowerCase().endsWith(".xlsx")) {
                let fileNameWithoutExtension = fileName.split(".")[0];
                await downloadExcel(fileNameWithoutExtension + status + ".xlsx", filePath);
            } else if (fileName.toLowerCase().endsWith(".zip")) {
                await downloadValidationReportZip({
                    "filename": fileName,
                    "filePath": filePath
                }, fileName)
            } else {
                showError("Error", `An error occurs when downloading validation Report.`);
            }
        } catch (e) {
            showError("Error", `An error occurs when downloading validation Report.`);
        }
    }


    return (filePath !== null && filePath !== '') ?
        <Button label="Report"
                onClick={downloadExcelByPath}
                className={btnStyle}
                icon="pi pi-download"
                iconPos="left"
                tooltip={summary}
                tooltipOptions={{position: 'top'}}
        />
        : <InfoLabel textStyle={validationMsgStyle} message={validationMsg}/>;
}
export default ValidationReport;
