import React, {useCallback, useEffect, useState} from "react";
import {Fieldset} from "primereact/fieldset";
import {Button} from "primereact/button";
import classes from "./Template.module.css";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {showError} from "../../../../api/notification/ToastManager";
import {simulateTemplateExcel} from "../../../../api/file/download-service";
import Aux from "../../../../hoc/Auxiliary";
import {sortAlphabetically} from "../../../../api/utils/sortArrays";


const Template = (props) => {
    const [category, setCategory] = useState({});
    const [marketNode, setMarketNode] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [path, setPath] = useState(props.modelVersion.path);
    const [marketNodes, setMarketNodes] = useState(props.modelVersion.marketNodes);

    useEffect(() => {
        let nc = props.dataCollection.categories.filter((e) => e.name === category.name)[0]
        if (nc !== undefined) {
            setCategory(nc);
        }

    }, [props.dataCollection])

    useEffect(() => {
        setPath(props.modelVersion.path)
        setMarketNodes(props.modelVersion.marketNodes)
        setMarketNode({});
    }, [props.modelVersion])

    const checkGeneralSettingsField = (field) => {
        return field === undefined || field === false;
    }

    const getDCName = () => {
        const n = props.dataCollection.name.trim();
        if (n.length > 0) {
            return n.replaceAll(" ", "_") + "_"
        } else {
            return "";
        }
    }


    const downloadTemplate = async () => {
        if (path === undefined) {
            showError("unknown PEMMDB version", `Please select PEMMDB Version`);
        }  else if (Object.keys(category).length === 0) {
            showError("Unknown category", `Please select Category is not selected`);
        } else if (category.sheets.filter(ct => ct === undefined).length === category.sheets.length) {
            showError("No sheets in the selected category", `Category ${category.name} must have at least 1 sheet`);
        } else if (Object.keys(marketNode).length === 0) {
            showError("Unknown market node", `Market Node is not selected`);
        } else {
            setIsLoading(true);
            const fileName = `${(getDCName())}${marketNode.name.trim()}_${category.name.trim().replaceAll(" ", "_")}.xlsx`
            await simulateTemplateExcel({
                "modelPath": path,
                "sheets": category.sheets.map(e => e.name),
                "category": {id: 0, name: category.name},
                "copiedFrom": {
                    dcId: props.dataCollection.copiedFrom?.copiedFromDc!=null?props.dataCollection.copiedFrom.copiedFromDc.id:0,
                    reuseData:props.dataCollection.copiedFrom!=null?props.dataCollection.copiedFrom.reuseData:false
                },
                "marketNode": marketNode,
                "dataCollection": {name: "Simulation"}
            }, fileName)
                .catch(_ => showError("Error", `An error occurs when generating ${fileName} template.`));
            setIsLoading(false);
        }
    }

    const updateMarketNode = useCallback((e) => {
        setMarketNode(e.target.value)
    }, [marketNode])
    const updateCategory = useCallback((e) => {
        setCategory(e.target.value)
    }, [category])
    return (<Aux>
        <div>
            <br/>
            <Fieldset legend="Template" toggleable>
                <div className="grid">
                    <div className="col-1"/>
                    <div className={`col-6 ${classes.padding10}`}>
                        <div>
                            <p><b>Simulate template generation for </b></p>
                        </div>
                        <div className={classes.padding}>
                            <Dropdown
                                name="Category"
                                onChange={updateCategory}
                                value={category}
                                options={sortAlphabetically(props.dataCollection.categories.filter(e => e.name.length > 0), 'name')}
                                optionLabel="name"
                                placeholder="Select Categories"
                                filter/>
                        </div>
                        <div className={classes.padding}>
                            <Dropdown
                                name="Market Node"
                                onChange={updateMarketNode}
                                value={marketNode}
                                options={marketNodes}
                                optionLabel="name"
                                placeholder="Select Market Nodes"
                                appendTo="self"
                                filter/>
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="grid">
                            <div className="col-5">
                                <Button
                                    type="button"
                                    className={classNames(classes.actionBtn, classes.rightButton)} label="Generate"
                                    icon="pi pi-download"
                                    onClick={downloadTemplate}
                                    loading={isLoading}
                                />
                            </div>
                            <div className="col-7"/>
                        </div>
                    </div>
                </div>
            </Fieldset>
        </div>
    </Aux>)
}

export default Template;
