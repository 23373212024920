import React, {useEffect, useRef, useState} from "react";

import Aux from "../../../../../../../hoc/Auxiliary"
import SheetConfiguration from "./SheetConfiguration";
import {deleteSheetsQueries, getAllQueryFileNamesPerDataCollection} from "../../../../../../../api/file/upload-service";
import {Divider} from 'primereact/divider';
import {Checkbox} from "primereact/checkbox";
import {Button} from 'primereact/button';
import {ConfirmPopup} from "primereact/confirmpopup";
import {Toast} from "primereact/toast";
import classes from "../../../DataVisualisation.module.css"

const SheetsConfigurations = (props) => {

    const [checkedSheets, setCheckedSheets] = useState([]);
    const [sheetsDetails, setSheetsDetails] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const toast = useRef(null);
    const buttonEl = useRef(null);

    useEffect(() => {
        getAllQueryFileNamesPerDataCollection(props.dataCollection.id).then(res => {
            if (res !== null) {
                setSheetsDetails(res)
            }
        });
    }, [])

    let addCheckedSheet = (category, sheet) => {
        let el = {
            category: category.id, sheet: sheet.id
        }
        setCheckedSheets([...checkedSheets, el])
    }

    let removeCheckedSheet = (category, sheet) => {
        let el = {
            category: category.id, sheet: sheet.id
        }
        let index = checkedSheets.findIndex(z => z.sheet === el.sheet);
        checkedSheets.splice(index, 1)
        setCheckedSheets([...checkedSheets])
    }
    let deleteSelectedSheet = () => {
        let el = {
            dataCollectionId: props.dataCollection.id, details: checkedSheets
        }
        deleteSheetsQueries(el).then(res => {
            if (res.status === 200) {
                setSheetsDetails(res.content);
            }
            setDeleteInProgress(false)
        })
    }
    let selectAll = () => {
        if (selectAllChecked) {
            setSelectAllChecked(false);
            setCheckedSheets([]);
        } else {
            setSelectAllChecked(true);
            let arr = [];
            props.category.sheets.forEach((s) => {
                let el = {
                    category: props.category.id, sheet: s.id
                }
                arr.push(el)
            })
            setCheckedSheets(arr)
        }
    }

    let accept = () => {
        setDeleteInProgress(true)
        setConfirmPopupVisible(false)
        deleteSelectedSheet();

    }

    let reject = () => {
        setConfirmPopupVisible(false)
    }

    return (<Aux>

        <div className="grid">
            <div className={"col-6 p-0 m-0"}>
                <div>
                    <Checkbox inputId="selectAll"
                              checked={selectAllChecked}
                              onChange={e => selectAll()}
                              value="Select all"/>
                    {selectAllChecked ? <label htmlFor="selectAll" className="ml-2">Unselect all</label> :
                        <label htmlFor="selectAll" className="ml-2">Select all</label>}

                </div>
            </div>
            <div className={"col-6 p-0 m-0"}>
                <div className={"flex flex-row-reverse"}>
                    {deleteInProgress ?
                        <Button icon="pi pi-spin pi-spinner"
                                disabled={true}
                                data-pr-at="left+5 top"
                                className=" p-button-rounded-sm p-button-text"
                                style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                        />
                        : <Button icon="pi pi-trash"
                                  ref={buttonEl}
                                  visible={!deleteInProgress}
                                  tooltip="Delete queries for selected sheets"
                                  tooltipOptions={{position: 'top', showOnDisabled: true}}
                                  disabled={!checkedSheets.length > 0}
                                  onClick={() => setConfirmPopupVisible(true)}
                                  className=" p-button-rounded-sm p-button-text"
                                  style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                        />}

                    <Toast ref={toast}/>
                    <ConfirmPopup target={buttonEl.current} visible={confirmPopupVisible}
                                  message="Are you sure you want to delete selected queries ?"
                                  icon="pi pi-exclamation-triangle"
                                  dismissable={false}
                                  accept={accept}
                                  acceptClassName={classes.confirmBtn}
                                  reject={reject}
                                  rejectClassName={classes.rejectBtn}
                    />
                </div>
            </div>
            <div className={"col-12 p-0 m-0 "}>
                <Divider/>
            </div>
            {props.category.sheets.map((s, i) => {
                return (<div className="col-6" key={props.category.id + "" + s.id + "" + i}>
                    <SheetConfiguration sheet={s} category={props.category}
                                        dataCollection={props.dataCollection}
                                        checkedSheets={checkedSheets}
                                        sheetDetails={sheetsDetails}
                                        selectSheet={(cat, sheet) => addCheckedSheet(cat, sheet)}
                                        removeSheet={(cat, sheet) => removeCheckedSheet(cat, sheet)}
                    />
                </div>)
            })}
        </div>
    </Aux>)
}

export default SheetsConfigurations;
