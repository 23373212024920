import React, {useState} from "react";
import {MultiSelect} from "primereact/multiselect";

const DCStatusFilter = ({dataCollectionsList, filterDataCollections, statusList}) => {

    const [selectedStatus, setSelectedStatus] = useState("");
    const onFilter = (e) => {
        let result = [...dataCollectionsList]
        if (e.value.length !== 0) {
            result = [...dataCollectionsList
                .filter(e1 => e.value.map(v => v.code).some(val => e1.dataStatus.code.includes(val)))]
        }
        setSelectedStatus(e.value);
        filterDataCollections(result);
    };
    return <MultiSelect value={selectedStatus} options={statusList}
                        onChange={onFilter}
                        optionLabel="name" placeholder="Select a status" className="p-column-filter"/>;

}

export default DCStatusFilter;
