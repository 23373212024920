import React, {useCallback, useState} from "react";
import classes from "../AdminTools.module.css";
import {Button} from "primereact/button";
import Auxiliary from "../../../../hoc/Auxiliary";
import {ConfirmPopup} from "primereact/confirmpopup";
import CloseDCConfirmDialog from "../CloseDCConfirmDialog/CloseDCConfirmDialog";
import classesConfirm from "../CloseDCConfirmDialog/CloseDCConfirmDialog.module.css";
import {closeDataCollection} from "../../../../api/dataCollection/dataCollection-service";
import {showError} from "../../../../api/notification/ToastManager";
import {isPermanentlyClosed, isTemporaryClosed} from "../../../../api/dataCollection/dataCollection-status";
import {useNavigate} from "react-router-dom";
import {canCloseDataCollection, canCloseDC} from "../../../../api/authorization/rules";

const CloseDCButton = ({dataCollection, setAdminToolsButtonsDisabled}) => {
    const [confirmIsVisible, setConfirmIsVisible] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(isPermanentlyClosed(dataCollection?.status));
    const [closedTemporary, setClosedTemporary] = useState(isTemporaryClosed(dataCollection?.status));
    const [closedStatus, setClosedStatus] = useState("");
    const navigate = useNavigate();

    const closeDC = useCallback(() => {
        if (confirmIsVisible === true) {
            hideConfirm();
        }
        setTimeout(() => {
            setConfirmIsVisible(true);
        }, 100)
    }, [confirmIsVisible]);

    const accept = () => {
        let status = closedStatus;
        if (status.length === 0) {
            status = isTemporaryClosed(dataCollection?.status) ? "Permanently" : "Temporarily"
        }
        if (canCloseDataCollection(status)) {
            closeDataCollection(dataCollection, status)
                .then((s) => {
                    setBtnDisabled(s);
                    setAdminToolsButtonsDisabled(s);
                    navigate("/data_collections")
                })
                .catch(_ => {
                    setBtnDisabled(false);
                    setAdminToolsButtonsDisabled(false);
                    showError("Error", "An error was occurred when closing data collection " + dataCollection.name);
                })
        } else {
            showError("Unauthorized", "You are not authorized to close data collection " + dataCollection.name + " " + status.toLowerCase())
        }
        hideConfirm();
    }
    const reject = useCallback(() => {
        hideConfirm()
    }, [])

    const hideConfirm = useCallback(() => {
        setConfirmIsVisible(false);
    }, [])

    const updateClosedStatus = (e) => {
        setClosedStatus(e);
    };

    const closeDialog = () => <CloseDCConfirmDialog updateStatus={updateClosedStatus}
                                                    dataCollectionStatus={dataCollection?.status}
                                                    name={dataCollection.name}/>;


    return (<Auxiliary>
        <Button
            id="cDcButton"
            label="Close data collection"
            className={classes.btn}
            icon="pi pi-times-circle"
            onClick={closeDC}
            disabled={btnDisabled}
            visible={canCloseDC() && !closedTemporary}
        />
        <Button
            id="cDcButton"
            label="Close data collection permanently"
            className={classes.btn}
            icon="pi pi-times-circle"
            onClick={closeDC}
            disabled={btnDisabled}
            visible={canCloseDC() && closedTemporary}
        />
        <ConfirmPopup
            target={document.getElementById('cDcButton')}
            visible={confirmIsVisible}
            accept={accept} reject={reject}
            onHide={hideConfirm}
            message={closeDialog}
            acceptLabel="Confirm"
            rejectLabel="Cancel"
            acceptClassName={`${classesConfirm.button} ${classesConfirm.actionBtn}`}
            rejectClassName={`${classesConfirm.button} ${classesConfirm.actionBtn}`}
            dismissable={true}
        />
    </Auxiliary>)
}

export default CloseDCButton;
