import React from "react";

const StatusBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="vertical-align-middle ml-2"> {rowData.dataStatus.name}</span>
        </React.Fragment>
    );
}
export default StatusBodyTemplate;
