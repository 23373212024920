import React, {useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import classes from "../ParticipantsSetting/ParticipantsSetting.module.css";
import {findDataCollectionById} from "../../../../api/dataCollection/dataCollection-service";
import {emptyDataCollection, getPermissionFromOtherDC} from "../../../../api/dataCollection/dataCollection-utils";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import keycloak from "../../../../keycloak";
import {sortById} from "../../../../api/utils/sortArrays";

const ExistingDataCollections = (props) => {
    const [selectedPreviousDc, setSelectedPreviousDc] = useState({});
    const [dcId, setDcId] = useState(null);
    const [permissionChecked, setPermissionChecked] = useState(false);
    const [generalChecked, setGeneralChecked] = useState(false);
    const [categoryChecked, setCategoryChecked] = useState(false);
    const [allSettingsChecked, setAllSettingsChecked] = useState(false);
    const [othersDisabled, setOthersDisabled] = useState(false);
    const [reuseData, setReuseData] = useState(false);
    const [persistQueriesChecked,setPersistQueriesChecked]= useState(false);
    const [exportQueriesChecked,setExportQueriesChecked]= useState(false);
    const changeDcCopied = (e) => {
        let dc = (props.dcList.filter(x => x.name === e.value));
        setDcId(dc[0].id)
        setSelectedPreviousDc(e.value);
    }

    const copy = () => {
        findDataCollectionById(dcId).then(res => {

            let dc = emptyDataCollection;
            props.setDataCollection(dc);
            dc.openFrom = ""
            dc.deadline = ""
            dc.categories = [{name: "", sheets: []}]
            dc.description = ""
            props.setParticipantsFromOtherDC({});
            props.setNewPermission(getPermissionFromOtherDC([], []));
            props.onChange("openFrom", null)
            props.onChange("deadline", null)
            props.setSelectedVersion(res.model)
            props.onChange("model", res.model)
            props.onChange("categories", res.categories)
            if (allSettingsChecked) {
                props.setParticipantsFromOtherDC(res.participants);
                props.setNewPermission(getPermissionFromOtherDC(res.participants, props.permissionsData));
                props.onChange("openFrom", new Date(res.openFrom))
                props.onChange("deadline", null)
                dc.openFrom = new Date(res.openFrom)
                dc.deadline = null
                dc.description = res.description;
                dc.mailMessage = res.mailMessage;
                res.categories.forEach(e => {
                    delete e['id']
                });
                dc.categories = res.categories
                dc.model = res.model;
                dc.createdBy = keycloak.idTokenParsed.name

            } else {
                if (permissionChecked) {
                    props.setParticipantsFromOtherDC(res.participants);
                    props.setNewPermission(getPermissionFromOtherDC(res.participants, props.permissionsData));
                    dc.model = res.model;
                    dc.participants = res.participants;
                }
                if (categoryChecked) {
                    res.categories.forEach(e => {
                        delete e['id']
                    });
                    dc.categories = res.categories;
                    dc.model = res.model;
                }
                if (generalChecked) {
                    props.onChange("openFrom", new Date(res.openFrom))
                    props.onChange("deadline", null)
                    dc.model = res.model;
                    dc.openFrom = new Date(res.openFrom)
                    dc.deadline = null
                }

            }
                dc.copiedFrom = {
                    reuseData:reuseData,
                    copiedFromDc: {
                        id: res.id,
                        name: res.name,
                        description: res.description,
                        startFrom: res.openFrom,
                        deadline: res.deadline,
                        dataStatus: res.dataStatus,
                        dataCollectionStatus: res.status,
                        modelVersionName: res.model.name,

                    },
                    copyGeneralSettings :allSettingsChecked||generalChecked,
                    copyParticipants : allSettingsChecked||permissionChecked,
                    copyCategories : allSettingsChecked||categoryChecked,
                    copyPersistQuery: allSettingsChecked||persistQueriesChecked,
                    copyExportQuery : allSettingsChecked||exportQueriesChecked

                };
                props.onChange("copiedFrom", res)

            props.setDataCollection(dc)

        })
        props.setDisplay(false)

    }


    return (
        <div>
            <Dropdown options={sortById(props.dcList, 'id').map(e => e.name)} value={selectedPreviousDc}
                      className={classNames(classes.selectStyle, classes.dcPermission)}
                      onChange={e => changeDcCopied(e)} filter placeholder="Select a data collection"
            />
            <br/>
            <br/>
            {/*all settings*/}
            <Checkbox style={{marginTop: "20px"}} inputId="allSettings" onChange={e => {
                setAllSettingsChecked(e.checked);
                setOthersDisabled(e.checked);
                setPermissionChecked(false);
                setGeneralChecked(false);
                setCategoryChecked(false);
                setPersistQueriesChecked(false);
                setExportQueriesChecked(false);
            }}
                      checked={allSettingsChecked}
            />
            <label htmlFor="allSettings" className={classes.checkboxLabel}>All settings</label>

            {/*Reuse data*/}

            <label style={{marginTop: "20px", float: "right", marginRight: "35%"}} htmlFor="ReuseData"
                   className={classes.checkboxLabel}>Reuse Data</label>

            <Checkbox style={{marginTop: "20px", float: "right",}} inputId="ReuseData" onChange={e => {
                setReuseData(e.checked)
            }}
                      checked={reuseData}
            />

            <br/>
            {/*General settings*/}

            <Checkbox style={{marginTop: "20px"}} inputId="general" onChange={e => {
                setGeneralChecked(e.checked)
            }}
                      checked={generalChecked}
                      disabled={othersDisabled}/>
            <label htmlFor="general" className={classes.checkboxLabel}>General settings</label>
            <br/>

            {/*Permissions*/}
            <Checkbox style={{marginTop: "20px"}} inputId="permission" onChange={e => {
                setPermissionChecked(e.checked);
            }}
                      checked={permissionChecked}
                      disabled={othersDisabled}/>
            <label htmlFor="categories" className={classes.checkboxLabel}>Participants and permissions</label>
            <br/>

            {/*Categories*/}
            <Checkbox style={{marginTop: "20px"}} inputId="categories" onChange={e => {
                setCategoryChecked(e.checked)
            }}
                      checked={categoryChecked}
                      disabled={othersDisabled}/>
            <label htmlFor="categories" className={classes.checkboxLabel}>Categories</label>
            <br/>

            {/*Persist queries*/}
            <Checkbox style={{marginTop: "20px"}} inputId="persistQueries" onChange={e => {
                setPersistQueriesChecked(e.checked)
            }}
                      checked={persistQueriesChecked}
                      disabled={othersDisabled}/>
            <label htmlFor="persistQueries" className={classes.checkboxLabel}>Persist Queries</label>
            <br/>
            {/*Export queries*/}
            <Checkbox style={{marginTop: "20px"}} inputId="exportQueries" onChange={e => {
                setExportQueriesChecked(e.checked)
            }}
                      checked={exportQueriesChecked}
                      disabled={othersDisabled}/>
            <label htmlFor="exportQueries" className={classes.checkboxLabel}>Export Queries</label>
            {/*Button copy*/}
            <Button className={classes.btnStyle}
                    disabled={!(allSettingsChecked || permissionChecked || categoryChecked || generalChecked ||persistQueriesChecked ||exportQueriesChecked) || selectedPreviousDc.length === undefined}
                    label="Copy" icon="pi pi-copy"
                    onClick={copy}
            />

        </div>


    )
}
export default ExistingDataCollections;
