import keycloak from "../../keycloak";
import axios from "axios";
import {showError, showInfo, showSuccess} from "../notification/ToastManager";
import {findByDCAndMarketNode} from "../Category/category-service";
axios.defaults.withCredentials = true
const runMarketNodeValidation = async (dataCollection, marketNode) => {
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    };
    let data = [];
    await axios.post(global.config.BACK_URL + "dataCollection/marketnode/validate", {
        "dataCollectionId": dataCollection.id,
        "marketNodeId": marketNode.id,
        "modelPath": dataCollection.modelPath,
        "dataCollectionName": dataCollection.name
    }, header)
        .then(res => {
            if (res.status === 200) {
                data = res.data.content;
                findByDCAndMarketNode(dataCollection, marketNode).then(res => {
                    if (res.status === 200) {
                        let stsInProgress = res.content.map(e => e['statusDTO'].code).filter(e => e !== "VALIDATION_IN_PROGRESS");
                        let stsTechError = res.content.map(e => e['validationDTO'].status).filter(e => e.code === "TECHNICAL_ERROR");
                        if (stsTechError.length > 0) {
                            showError("Error", stsTechError[0].name)
                        } else if (stsInProgress.length > 0) {
                            showSuccess("Success", `Validation is completed`);
                        }
                    } else if (res.status === 504) {
                        showInfo("Info", "The validation might take several minutes")
                    } else {
                        showError("Error", "An error was occurred when searching categories data")
                    }
                })
            }
        })
        .catch(e => {
            if (e.response.status === 504) {
                showInfo("Info", "The validation might take several minutes")
            } else {
                showError("Error", `A problem was occurred when running market node validation.`);

            }
        });
    return data;
}

export default runMarketNodeValidation;
