import React, {useEffect, useState} from "react";
import Aux from "../../../../../../../hoc/Auxiliary"
import {Checkbox} from "primereact/checkbox";


const Query = (props) => {

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        let exist = props.checkedQueries.filter(qId => qId === props.query.id);
        if (exist.length > 0) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }, [props.checkedQueries])

    let checkQuery = (e) => {
        setIsChecked(!isChecked)
        if (isChecked) {
            props.unselectQuery(props.query.id)
        } else {
            props.selectQuery(props.query.id)

        }
    }

    return (
        <Aux>
            <div className="grid max-w-full">
                <div className=" flex align-items-center ">
                    <Checkbox onChange={e => checkQuery(e)} checked={isChecked}/>
                </div>
                <div className="col-10 mr-2 flex align-items-center ">
                    <label>{props.query.name} </label>
                </div>

            </div>
        </Aux>
    )
}

export default Query;




