export const StatusContext = {
    CATEGORY_STEPS: "CATEGORY_STEPS",
    PUBLISHED_IN_ES: "DATA_PERSISTED",
    MARKET_NODE_STEPS: "MARKET_NODE_STEPS",
    LEVEL4_STEPS:"LEVEL4_STEPS"
}
export const StatusCode = {
    DATA_PERSISTED: "DATA_PERSISTED",
    MANUEL_CHECK_IN_PROGRESS: "MANUEL_CHECK_IN_PROGRESS",
    MANUAL_CHECK_COMPLETED: "MANUAL_CHECK_COMPLETED",
    PUBLISHED: "PUBLISHED",
    FILES_SKIPPED :"FILES_SKIPPED"
}
