import React, {useEffect, useState} from "react";
import Auxiliary from "../../../hoc/Auxiliary";
import HeaderBreadCrumb, {normalizePathItem} from "../../../components/BreadCrumb/Breadcrumb";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {loadState, removeFromSessionStorage} from "../../../api/State/manage-state-storage-service";
import SparqlQueries from "../../../components/DataCollectionQueries/QueriesConfiguration/SparqlQueries";
import {generateNewQuery, updateSavedQueries} from "../../../api/utils/SparqlQueries";
import {retrieveSparqlQueries} from "../../../api/dataCollection/sparql-queries-service";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import {showError} from "../../../api/notification/ToastManager";


const DataCollectionQueriesBuilder = () => {
    const {state} = useLocation();
    const dataCollection = loadState(state, "dataCollection");
    const urlParams = useParams();
    const navigate = useNavigate();
    const [queriesList, setQueriesList] = useState(generateNewQuery)
    const [initialQueries, setInitialQueries] = useState(generateNewQuery)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (urlParams?.dcName && urlParams.dcName.toLowerCase() !== normalizePathItem(dataCollection?.name)) {
            onResponseError("Access to dataCollection queries details page from address bar is not authorized")
        }
    }, [])

    useEffect(() => {
        try {
            retrieveSparqlQueries(dataCollection?.id)
                .then((data) => {
                    if (data.status === 200 && data.content.length > 0) {
                        const qs = [...updateSavedQueries(data.content)].map(e => {
                            e['dataCollection'] = {}
                            return e;
                        })
                        setQueriesList([...qs])
                        setInitialQueries([...qs])
                    }
                    setLoading(false);
                }).catch(_ => {
                onResponseError("An error occurs while retrieving queries for dataCollection '" + dataCollection?.name + "'")
            })
        } catch (e) {
            onResponseError("An error occurs while displaying 'Queries' page for dataCollection ")
        }
    }, [])

    const onResponseError = (message) => {
        showError("Error loading page", message)
        removeFromSessionStorage("dataCollection")
        navigate("/data_collections");
    }

    return loading
        ? (<LoadingScreen/>)
        : (<Auxiliary>
            <HeaderBreadCrumb items={["Data Collections",dataCollection?.name, "Queries" ]}/>
            <h3>Setup data collection queries</h3>
            <SparqlQueries
                queriesList={queriesList}
                initialQueries={JSON.parse(JSON.stringify(initialQueries))}
                dataCollection={dataCollection}
            />
        </Auxiliary>);
}
export default DataCollectionQueriesBuilder;
