import React from "react";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import {showError, showSuccess} from "../../../api/notification/ToastManager";
import {deactivateToken, generateTokenForUser} from "../../../api/tokenGenerator/tokenGenerator-service";
import classes from "../Token.module.css";
import {formatDate} from "../../../api/utils/DateFormatter";
import {CopyToClipboard} from "react-copy-to-clipboard";

const ActionColumnToken = (props) => {
    const confirmDeleteDc = (tokenId, username) => {
        confirmDialog({
            message: 'Are you sure you want to deactivated access key for "' + username + '" ?',
            header: 'Confirmation',
            accept: async () => {
                deactivateToken(tokenId).then(r => {
                    if (r === 200) {
                        showSuccess("Access key deactivated successfully !")
                        props.setReload(!props.reload)
                    } else {
                        showError("Error", "An error was occurred when generating token");
                    }
                })
            },
            reject: () => {
            },
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: `${classes.buttons} ${classes.actionBtn}`,
            rejectClassName: `${classes.buttons} ${classes.actionBtn}`
        });

    }
    const generate = (preferredName) => {
        generateTokenForUser(preferredName)
            .then(res => {
                props.setReload(!props.reload)
            })
    }
    const handleCopy = () => {
        showSuccess("Access key copied to clipboard")
    };
    return (
        <div className="grid p-">
            <div className="col p-0 m-0 flex align-items-center justify-content-center">
                <div style = {props.rowData.accessKey ?{visibility:"visible"} :{visibility:"hidden"}}>
                    <CopyToClipboard text={props.rowData.accessKey} onCopy={handleCopy}>
                    <Button icon="pi pi-copy"
                            tooltip="Copy"
                            tooltipOptions={{
                                position: 'bottom',
                                showOnDisabled: true
                            }}
                            className=" p-button-rounded-sm p-button-text m-1"
                            style={{
                                height: '0.5rem',
                                width: '1.5rem',
                                color: '#487c84'
                            }}/>
                </CopyToClipboard>
                </div>
                <Button icon=" pi pi-sync"
                        tooltip="Regenerate"
                        tooltipOptions={{
                            position: 'bottom',
                            showOnDisabled: true
                        }}
                        className=" p-button-rounded-sm p-button-text m-1"
                        onClick={() => generate(props.rowData.preferredName)}
                        style={{
                            height: '0.5rem',
                            width: '1.5rem',
                            color: '#487c84'
                        }}/>
                { props.rowData.deactivated && props.rowData.status!=="EXPIRED" ? <Button icon=" pi pi-info-circle"
                                                     tooltip={"Deactivated by: " + props.rowData.deactivatedBy + "\n" + formatDate(new Date(Date.parse(props.rowData.deactivationDate)))}
                                                     tooltipOptions={{
                                                         position: 'left',
                                                         showOnDisabled: true
                                                     }}
                                                     className=" p-button-rounded-sm p-button-text m-1"
                                                     style={{
                                                         height: '0.5rem',
                                                         width: '1.5rem',
                                                         color: '#487c84'
                                                     }}/> :<Button icon=" pi pi-ban"
                                                                    tooltip="Deactivate"
                                                                    tooltipOptions={{
                                                                        position: 'bottom',
                                                                        showOnDisabled: true
                                                                    }}
                                                                    className=" p-button-rounded-sm p-button-text m-1"
                                                                    onClick={() => confirmDeleteDc(props.rowData.id, props.rowData.username)}
                                                                    style={{
                                                                        height: '0.5rem',
                                                                        width: '1.5rem',
                                                                        color: '#487c84'
                                                                    }}
                                                                    disabled={props.rowData.status==="EXPIRED"}/>}

            </div>
        </div>
    )

}
export default ActionColumnToken;
