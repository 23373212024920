import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import classes from "./SparqlQueries.module.css";
import Aux from "../../../hoc/Auxiliary";
import SparqlQuery from "./Query/SparqlQuery";
import {
    checkAllQueries,
    checkQuery,
    compareArrays,
    deleteEmptyParameters,
    generateNewQuery
} from "../../../api/utils/SparqlQueries";
import {useNavigate} from "react-router-dom";
import {saveSparqlQueries} from "../../../api/dataCollection/sparql-queries-service";
import {updateProperty} from "../../../api/utils/conditions-utils";

const SparqlQueries = ({queriesList, dataCollection, initialQueries}) => {
    const [queries, setQueries] = useState(queriesList)
    const [addButtonDisabled, setAddButtonDisabled] = useState(true);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [deletedQueriesId, setDeletedQueriesId] = useState([]);

    const navigate = useNavigate();
    const save = () => {
        setIsLoading(true);
        setAddButtonDisabled(true)
        const nonEmpty = queries.filter(q => checkQuery(q, queries) === '');
        const nqs = updateProperty(nonEmpty.length > 0, [...deleteEmptyParameters(nonEmpty)], [])
        saveSparqlQueries(dataCollection, nqs, deletedQueriesId)
            .then((data) => {
                setIsLoading(false);
                setAddButtonDisabled(true);
                if (data.status === 200 && data.content.length > 0) {
                    let names = data.content.map(e => e.queryName);
                    setQueries([...queries.filter(e => names.includes(e.name))])
                } else {
                    navigate("/data_collections")
                }
            })
    }

    const deleteCurrentQuery = (queryIndex) => {
        if (queries.length === 1) {
            setDeletedQueriesId([...deletedQueriesId, queries[0]['queryId']].filter(e => e !== undefined))
            setQueries(generateNewQuery)
        } else {
            setDeletedQueriesId([...deletedQueriesId, queries[queryIndex]['queryId']].filter(e => e !== undefined))
            const nq = [...queries.filter((e, i) => i !== queryIndex)];
            setQueries(nq)
            setSaveButtonDisabled(isSaveDisable(nq))
        }
    }

    useEffect(() => {
        setAddButtonDisabled(checkAllQueries(queries).length > 0)
    }, [queries])

    const addNewQuery = () => {
        const nq = generateNewQuery();
        setQueries([...queries, ...nq])
    }
    const emptyQueries = (qList) => {
        return qList.length === 1 && qList[0].name === "" && qList[0].content === ""
    }
    const isSaveDisable = (qList) => {
        return checkAllQueries(qList).length > 0 || emptyQueries(qList) || compareArrays(qList, initialQueries);
    }


    return (
        <Aux>
            <div>
                {
                    queries.map((c, i) =>
                        <SparqlQuery key={`sparql-${i + 1}`}
                                     queryIndex={i}
                                     allQueries={queries}
                                     updateQueries={setQueries}
                                     deleteCurrentQuery={() => deleteCurrentQuery(i)}
                                     updateQueryButtons={(qList) => {
                                         setAddButtonDisabled(checkAllQueries(qList).length > 0)
                                         setSaveButtonDisabled(isSaveDisable(qList))
                                     }}
                                     readOnly={c['queryId'] >= 0}
                        />
                    )}
                <div className="flex">
                    <div className="col-12">
                        <div className="grid">
                            <div className="col-9"/>
                            <div className="flex col-3 justify-content-end pr-0">
                                <Button
                                    type="button"
                                    className={classes.actionBtn}
                                    label="Add Query"
                                    onClick={addNewQuery}
                                    disabled={addButtonDisabled}
                                />
                                <Button
                                    type="button"
                                    className={`${classes.actionBtn} ml-3`}
                                    label={queries.length > 1 ? "Save Queries" : "Save Query"}
                                    onClick={save}
                                    disabled={saveButtonDisabled}
                                    loading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    )
}

export default SparqlQueries;
