import React, {useCallback, useEffect, useState} from "react";
import Aux from "../../../hoc/Auxiliary";
import sendManualCheckResult from "../../../api/MarketNode/manualcheck-service";
import {Button} from "primereact/button";
import {classNames} from 'primereact/utils';
import {Dialog} from 'primereact/dialog';
import {SelectButton} from 'primereact/selectbutton';
import {InputTextarea} from "primereact/inputtextarea";
import classes from "../ManuelCheckStatus/ManualCheckStatus.module.css";
import {canSendManualCheckResult} from "../../../api/authorization/rules";
import ManualCheckTooltip from "./StatusTooltip/ManualCheckTooltip";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import classesP from "../../DataCollection/DataCollectionCreator/ParticipantsSetting/ParticipantsSetting.module.css";

const ManualCheckStatus = (props) => {
    const [status, setStatus] = useState(props.rowData?.manualCheck.status);
    const [sendDisability, setSendDisability] = useState(false)
    const [displaySendManualCheck, setDisplaySendManualCheck] = useState(false);
    const [value, setValue] = useState('Yes');
    const [reason, setReason] = useState('')
    const [response, setResponse] = useState(true)
    const [isManualCheckResponseSent, setIsManualCheckResponseSent] = useState(false);
    const options = ['Yes', 'No'];
    const canUserSendManualCheckResult = canSendManualCheckResult(props.dataCollection.participants, props.rowData.marketNode);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        setStatus(props.rowData?.manualCheck.status)
        setShowButton(checkSendResultButtonVisibility)
    }, [props.isMCResultButtonDisable])

    const checkSendResultButtonVisibility = () => {
        return canUserSendManualCheckResult
            && props.rowData?.manualCheck.status?.code === "WAITING_FOR_MC"
            && !isManualCheckResponseSent
    }
    const sendMCResult = useCallback(async () => {
        const params = {
            "manualCheck": props.rowData.manualCheck,
            "response": response,
            "reason": reason,
            "dataCollectionId": props.dataCollection.id

        }
        setDisplaySendManualCheck(false);
        await sendManualCheckResult(params).then(res => {
            if (res.data.status === 200) {
                if (response) {
                    setStatus({code: "VALIDATION_SUCCESS", color: "#3f9c35"})
                } else {
                    setStatus({code: "VALIDATION_FAILED", color: "red"})
                }

                setShowButton(false);
                setIsManualCheckResponseSent(true);
                props.rowData.manualCheck.reason = reason;

            }
        })

    }, [props.rowData, status, displaySendManualCheck, response, reason])

    const changeValue = useCallback((e) => {
        if (e.value === 'Yes') {
            setSendDisability(false);
            setResponse(true)
        } else if (e.value === 'No') {
            setResponse(false);
            setSendDisability(false);
        } else {
            setSendDisability(true);
        }
        setValue(e.value);
    }, [response, value])

    const showManualCheckButton = useCallback(() => {
        setDisplaySendManualCheck(true)
    }, [])

    const hideManualCheckButton = useCallback(() => {
        setDisplaySendManualCheck(false)
        setReason('');
        setResponse(true);
        setValue('Yes');
    }, [])

    const updateReason = useCallback((e) => {
        setReason(e.target.value);
    }, [])

    const confirm = () => {
        confirmDialog({
            message: 'Are you sure to reject manual check?',
            header: 'Confirmation',
            accept: () => sendMCResult(),
            reject: () => {
            },
            icon: 'pi pi-exclamation-triangle',
            appendTo: "self",
            acceptClassName: `${classes.button} ${classesP.actionBtn}`,
            rejectClassName: `${classes.button} ${classesP.actionBtn}`
        });
    }

    return showButton
        ? (<Aux>
            <div className="grid">
                <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                    <Button type="button"
                            className={classes.buttonSendMC}
                            icon=" pi pi-envelope" label="   Send result"
                            onClick={showManualCheckButton}
                    />
                </div>
            </div>
            <Dialog header="Send manual check result" visible={displaySendManualCheck} modal
                    onHide={hideManualCheckButton} style={{width: '50vw'}}>
                <p><b>Accept data?</b></p>
                <SelectButton value={value} options={options} onChange={changeValue}/>
                <p style={{color: "red", visibility: !sendDisability ? "hidden" : "visible"}}>You must choose a
                    response</p>
                <p><b>Details</b></p>
                <InputTextarea name="description"
                               rows={5}
                               value={reason}
                               cols={30}
                               autoResize
                               onChange={updateReason}
                               className={classes.textAreaStyle}/>
                <br/>
                <br/>
                <Button label="Send"
                        disabled={sendDisability}
                        className={classNames(classes.actionBtn, classes.saveButton)}
                        onClick={() => {
                            if (response)
                                sendMCResult()
                            else
                                confirm()
                        }}/>
                <Button label="Close" onClick={hideManualCheckButton}
                        className={classNames(classes.actionBtn, classes.closeButton)}/>
            </Dialog>
            <ConfirmDialog/>
        </Aux>)
        : (<ManualCheckTooltip rowData={props.rowData} status={status}/>)
}

export default ManualCheckStatus;
