import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import {ReactKeycloakProvider} from '@react-keycloak/web'
import keycloak from './keycloak'
import Layout from "./hoc/Layout/Layout";
import './config';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{onLoad: 'login-required'}}
    >
        <BrowserRouter>
            <Layout>
                <App/>
            </Layout>
        </BrowserRouter>
    </ReactKeycloakProvider>
);

reportWebVitals();
