import React from "react";
import classes from "../DataProvisionning.module.css";
import {Button} from "primereact/button";
import Aux from "../../../../../../hoc/Auxiliary";

const Spinner = ({fileName}) => {
    return (
        <Aux>
            <div className="grid">
                <div className="col-12 flex align-items-center justify-content-center">
            <span>
                <label className={classes.title}>Data provisioning</label>
            </span>
                </div>
                <div className="col-12 flex align-items-center justify-content-center">
                    <Button className={classes.uploadSpinnerBtn} label={fileName} loading={true}/>
                </div>
            </div>
        </Aux>)

}
export default Spinner;
