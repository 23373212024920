import React from "react";
import Aux from "../../hoc/Auxiliary";
import {Route, Routes} from "react-router-dom";
import ErrorServer from "../../components/ErrorServer/ErrorServer";
import NotAuthorized from "../../components/NotAuthorized/NotAuthorized";
import {ERROR_PATH, NOT_AUTHORIZED_PATH} from "../../api/static-data/Configurations";

const AuthRoutes = () => {
    return (
        <Aux>
            <Routes>
                <Route path={ERROR_PATH} exact element={<ErrorServer/>}/>
                <Route path={NOT_AUTHORIZED_PATH} exact element={<NotAuthorized/>}/>
                <Route path="*" exact element={<NotAuthorized/>}/>
            </Routes>
        </Aux>
    )
}
export default AuthRoutes;
