export const loadState = (currentState, key) => {
    let value;
    if (!currentState) {
        try {
            return JSON.parse(sessionStorage.getItem(key));
        } catch (e) {
            return null;
        }
    } else {
        value = currentState[key];
        saveToSessionStorage(key, currentState[key])
    }
    return value;
}

export const loadFromSessionStorage = (key) => {
    return loadState(null, key)
}

export const removeFromSessionStorage = (key) => {
    sessionStorage.removeItem(key);
}

export const saveToSessionStorage = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
}
