import {StatusCode} from "../../entities/StatusContext";

/**
 * [19/01/2023] Current status before publishing Data in table "Status",
 * if a new status is added in Status table, this constant should be updated.
 * */
const notPublished = [
    "NO_DATA_AVAILABLE",
    "DATA_SUBMISSION",
    "VALIDATION_IN_PROGRESS",
    "VALIDATION_SUCCESS",
    "VALIDATION_FAILED"];
export const checkIfMarketNodeIsPublished = (statusCode) => {
    return !notPublished.includes(statusCode);
}

export const isLevel4ButtonDisabled = (data) => {
    const lv4 = data.filter(e => e.status.code === "TECHNICAL_ERROR"
        && e.status.context === "LEVEL4_STEPS")
    if (lv4.length > 0) {
        return false;
    }
    const lv3 = data.filter(e => e.status.code !== "VALIDATION_SUCCESS" &&  e.status.code !== StatusCode.FILES_SKIPPED
        && e.status.context === "CATEGORY_STEPS");
    return lv3.length > 0
}

export const isValidationInProgress = (categoriesData) => {
    return categoriesData.filter(e => e['validationDTO'].status.code === "VALIDATION_IN_PROGRESS").length > 0
}

export const isTemplatesInProgress = (categoriesData) => {
    return categoriesData.filter(e => isTemplateGenerationInProgress(e)).length > 0
}
export const isTemplateGenerationInProgress = (category) => {
    return category?.template === "IN_PROGRESS";
}
export const isTemplateGenerationFailed = (category) => {
    return category?.template === "FAILED";
}
