import React, {useState} from "react";
import Aux from "../../../hoc/Auxiliary"
import classes from "./PageDetails.module.css";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {downloadBackupForMarketNode, getBackupByMarketNode} from "../../../api/MarketNode/backup-service";
import {DataTable} from "primereact/datatable";
import TemplatePaginator from "../../commun/TemplatePaginator/TemplatePaginator";
import {Column} from "primereact/column";
import {showError} from "../../../api/notification/ToastManager";
import {formatDate} from "../../../api/utils/DateFormatter";

const PageDetails = (props) => {
    const [display, setDisplay] = useState(false);
    const [backupList, setBackupList] = useState([])
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }
    const downloadBackup = (rowData) => {
        downloadBackupForMarketNode({
            "id": rowData.id,
            "createdBy": rowData.createdBy,
            "createdIn": rowData.createdIn,
            "dcMarketNode": rowData.dcMarketNode,
            "name": rowData.name,
            "path": rowData.path
        }).catch(_ => {
            showError("Download failed", "An error occurs when downloading backup.")
        });
    }
    const actionsBodyTemplate = (rowData) => {
        return (
            <Aux>
                <Button type="button"
                        className={classes.buttonDownload}
                        icon=" pi pi-download" label="   Download"
                        onClick={() => downloadBackup(rowData)}
                />
            </Aux>
        )

    }
    const searchBackupList = () => {
        getBackupByMarketNode(props.dcMarketNodeId)
            .then(res => {
                setBackupList(res);
            })
    }
    const createdIn = (rowData) => {

        return (
            <p>{formatDate(new Date(Date.parse(rowData.createdIn)))}</p>
        )
    }
    const manageHideDialog = () => {
        setDisplay(false);
    }
    return (
        <Aux>
            <div className="grid md:gap-0 ml-0 mr-0 p-0">
                <div className="col md:gap-0 p-0">
                    <h3 className={classes.DC}>Data Collection : {props.dcName}</h3>
                </div>

                <div className="col md:gap-0 p-0">
                    <h3 className={classes.MN}>Country : {props.marketNode}</h3>

                </div>
            </div>
            <div className="grid md:gap-0 ml-0 mr-0 p-0">
                <div className="col md:gap-0 p-0">

                    <h3 className={classes.C487c84}>Overview</h3>

                </div>
                <div>
                    <Button className={classes.btnStyle}
                            label="Backup list" icon="pi pi-history"
                            onClick={() => {
                                searchBackupList();
                                setDisplay(true)
                            }}
                    />
                </div>
            </div>

            <Dialog header="Backup list" visible={display} style={{width: '70vw'}}
                    onHide={() => manageHideDialog()}>
                <DataTable
                    value={backupList}
                    size="small"
                    resizableColumns
                    stripedRows
                    showGridlines
                    paginator paginatorTemplate={TemplatePaginator}
                    first={first} rows={rows} onPage={onCustomPage}
                    paginatorClassName="justify-content-end"
                    className="mt-6"
                >

                    <Column body={createdIn} sortable header="Created in"/>
                    <Column field="createdBy" sortable header="Created by"/>
                    <Column header=" Actions" body={actionsBodyTemplate}></Column>
                </DataTable>
            </Dialog>
        </Aux>
    )
};


export default PageDetails;
