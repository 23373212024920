import React from "react";
import DCButton from "../Button/DCButton";
import {normalizePathItem} from "../../../BreadCrumb/Breadcrumb";

const DataCollectionQueriesButton = ({rowData}) => {
    return (<DCButton
        rowData={rowData}
        icon={"pi pi-wrench"}
        tooltipText={"Data collection queries"}

        navigationPath={"/data_collections/" + normalizePathItem(rowData?.name) + "/queries/"}

    />)
}
export default DataCollectionQueriesButton;
