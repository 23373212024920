import {generateRandomKey} from "./Random";
import {updateProperty} from "./conditions-utils";

export const XSDTypes = [
    "Number <xsd:integer>", "Decimal <xsd:decimal>", "Date (dd/MM/yyyy) <xsd:date>", "Date (dd/MM) <xsd:gMonthDay>", "String <xsd:string>", "Hour (HH:mm) <xsd:time>", "Boolean (Yes/No) <xsd:boolean>"
]

export const categories = [
    {name: 'PEMMDB Class', key: 'rdf'},
    {name: 'Resource', key: 'uri'},
    {name: 'Literal', key: 'lit'}
];

export const defaultParameters = [
    {name: 'MarketNodes', key: 'mn'},
    {name: 'CategorySheets', key: 'sh'}
];

export const sparqlExtensions = [
    "txt", "rq", "sparql", "ttl", "turtle"
]
export const generateNewQuery = () => {
    return [{
        name: '',
        description: '',
        parameters: [defaultParameters[0]],
        sparqlParameters: [
            {name: '', key: generateRandomKey(), properties: {}}
        ],
        content: ''
    }]
}


export const checkPropertiesValidity = (propsArray) => {
    const type = propsArray?.type;
    let result = false;
    switch (type) {
        case '' :
        case undefined :
        case null:
            break;
        case categories[0].name:
        case categories[2].name:
            result = propsArray?.uri !== undefined && propsArray?.uri.length > 0;
            break;
        case categories[1].name:
            result = true;
            break
    }
    return result;
}

export const checkAllQueries = (queriesList) => {
    let message = ""
    message += queriesList.length === 0 ? "No Queries" : "";
    queriesList.forEach(q => {
        message += checkQuery(q, queriesList);
    })
    return message;
}

export const checkQuery = (query, allQueries) => {
    let message = ""
    message += allQueries.filter(q => q.name === query.name).length > 1 ? "⚠️ Query name is duplicated.\n" : '';
    message += query.name === '' ? "⚠️ Query name should not be empty.\n" : '';
    message += query.content === '' ? "⚠️ Query file is required, Please select a file.\n" : '';
    query.sparqlParameters
        .filter(p => p.name.replaceAll(" ", "") !== '')
        .forEach(p => {
            message += checkPropertiesValidity(p.properties) ? '' : "⚠️ When SPARQL Parameter type is different from 'Resource' ,an additional property should be defined : click on ✏️ to add it.\n"
        })
    return message;
}

export const deleteEmptyParameters = (queries) => {
    queries.map(nq => {
        nq.parameters = [...nq.parameters.filter(e => e.name.length > 0)];
        nq.sparqlParameters = [...nq.sparqlParameters.filter(e => e.name.length > 0)];
    })
    return queries;
}
export const updateSavedQueries = (queries) => {
    const nq = [...queries];
    nq.map(q => {
        q.parameters = updateParameters(q.parameters)
        q.sparqlParameters = updateSparqlParameters(q.sparqlParameters);
    })
    return nq;
}

const updateParameters = (parameters) => {
    const ps = [...parameters]
    ps.map(e => {
        e.key = defaultParameters.filter(k => k.name === e.name)[0].key
    })
    return ps;
}
const updateClassParameters = (e) => {
    if (e?.properties?.type === 'PEMMDB Class' && e?.properties?.uri.startsWith("[")) {
        let uris = JSON.parse(e.properties.uri);
        if (uris.length > 0) {
            let first = uris[0].uri;
            e.properties.uri = first.substring(0, first.lastIndexOf('.'))
        }
    }
}
const updateSparqlParameters = (parameters) => {
    const ps = [...parameters]
    ps.map(e => {
        updateClassParameters(e);
        e.key = generateRandomKey();
    })
    return ps;
}

export const compareArrays = (a, b) => {
    const strA = JSON.stringify(a);
    const strB = JSON.stringify(b);
    if (strA.length !== strB.length) {
        return false;
    }
    return strA.split('')
        .filter((c, i) => c.charCodeAt(0) === strB.charCodeAt(i))
        .length === strB.length
}

export const adaptQueryName = (name) => {
    const n = name.replace(/\s\s+/g, ' ')
    return updateProperty(n.length === 1 && n === ' ', "", n)
}
