import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {onServerCallError} from "../../api/utils/errors";
import {ERROR_PATH} from "../../api/static-data/Configurations";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {generateToken, getAllAPIToken} from "../../api/tokenGenerator/tokenGenerator-service";
import {formatDate} from "../../api/utils/DateFormatter";
import Auxiliary from "../../hoc/Auxiliary";
import TokenTable from "../../components/Token/TokenTable/TokenTable";
import keycloak from "../../keycloak";

const TokenListBuilder = () => {
    const [accessKeys, setAccessKeys] = useState([])
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);

    const fetchData = async () => {
        getAllAPIToken().then((res) => {
            setTableLoading(true);
            let token=res.content.filter(token => token.preferredName===keycloak.idTokenParsed.preferred_username)
            if(token.length===0){
                    generateToken()
                        .then(res => {
                            setReload(!reload)
                        })
            }
            res.content.map(token => token.expirationDate = formatDate(new Date(Date.parse(token.expirationDate))))
            setAccessKeys(res.content);
            setTableLoading(false);
            setIsLoading(false);
        })
    }
    const onResponseError = () => {
        onServerCallError("Error loading page", "An error occurs while retrieving access keys", () => {
            navigate(ERROR_PATH);
        })
    }
    useEffect(() => {

        try {
            fetchData().then();
        } catch (e) {
            onResponseError()
        }
    }, [reload]);


    let content = isLoading
        ? (<LoadingScreen/>)
        : (<Auxiliary>
            <TokenTable
                accessKeys={accessKeys}
                reload={reload}
                setReload={setReload}
                tableLoading={tableLoading}
            />

        </Auxiliary>)
    return <div>{content}</div>

}
export default TokenListBuilder;