import React, {useCallback, useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import ManualCheckStatus from "../ManuelCheckStatus/ManualCheckStatus";
import BodyTemplate from "../BodyTemplate/BodyTemplate";
import Aux from "../../../hoc/Auxiliary";
import {MultiSelect} from "primereact/multiselect";
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import TemplatePaginator from "../../commun/TemplatePaginator/TemplatePaginator";
import {ProgressBar} from "primereact/progressbar";
import classes from "../../DataCollection/DataCollections/DataCollectionList.module.css";
import {canUploadData} from "../../../api/authorization/rules";
import {getUniqueObjectsByProperty} from "../../../api/utils/UniqueObjectsByProperty";

const DetailsTable = (marketNodesDetails) => {
    const [rows, setRows] = useState(20);
    const [first, setFirst] = useState(0);
    const [status, setStatus] = useState([]);
    const [filters, setFilters] = useState({});
    const [isMCResultButtonDisable, setIsMCResultButtonDisable] = useState(true);
    const [expandedMarketNodes, setExpandedMarketNodes] = useState({});
    const [dataCollection, setDataCollection] = useState(marketNodesDetails.dataCollection);
    const initFilters = () => {
        setFilters({
            global: {value: null, matchMode: FilterMatchMode.CONTAINS},
            'marketNodeStatus.name': {value: null, matchMode: FilterMatchMode.IN},
            'marketNode.name': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
            },
            lastEdit: {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
            },
            lastStatusChange: {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
            },
            lastChangedBy: {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
            }


        });
    }
    const [marketNodesData, setMarketNodesData] = useState(marketNodesDetails.marketNodesDetails)

    const [value, setValue] = useState();

    useEffect(() => {
        setMarketNodesData(marketNodesDetails.marketNodesDetails);
        setDataCollection(marketNodesDetails.dataCollection);
        setIsMCResultButtonDisable(!isMCResultButtonDisable)
    }, [marketNodesDetails])
    const onFilter = (e) => {
        let result = marketNodesDetails.marketNodesDetails.filter(e1 =>
            e.value.map(v => v.code)
                .some(val => e1['marketNodeStatus'].code
                    .includes(val)))
        if (e.value.length === 0) {
            setMarketNodesData(marketNodesDetails.marketNodesDetails);
        } else {
            setMarketNodesData(result);
        }
        setValue(e.value);
    };


    const onCustomPage = useCallback((event) => {
        setFirst(event.first);
        setRows(event.rows);
    }, [])


    useEffect(() => {
        let st = marketNodesData.map((mn) => mn['marketNodeStatus']);

        setStatus(getUniqueObjectsByProperty(st, "name"));
        initFilters();
    }, [])


    const statusFilterTemplate = () => {
        return <MultiSelect value={value} options={status}
                            onChange={onFilter}
                            optionLabel="name" placeholder="Select a status" className="p-column-filter"/>;

    }
    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="vertical-align-middle ml-2"> {rowData['marketNodeStatus'].name}</span>
            </React.Fragment>
        );
    }
    const marketNodeDetailsTemplate = (data) => {
        return (
            <div className="p-3">
                {data.manualCheck.reason !== "" &&
                    <div className={classes.description}><h5>Manual check details :</h5>{data.manualCheck.reason}</div>}
            </div>
        );
    };
    const allowExpansion = (rowData) => {
        return rowData.manualCheck.reason !== "" && canUploadData(dataCollection.participants, rowData.marketNode);
    };
    const manualCheckStatusBody = (row) => (
        <ManualCheckStatus rowData={row} dataCollection={dataCollection}
                           isMCResultButtonDisable={isMCResultButtonDisable}/>)
    const getProgressBackground = (val) => {
        if (val === 1) {
            return "green"
        } else return "orange"
    }
    const validationProgress = (row) => (<ProgressBar value={parseFloat(row['averageValidation'].toFixed(2)) * 100}

                                                      color={getProgressBackground(row['averageValidation'])}/>)
    return (
        <Aux>
            <div>
                <div className=" card">

                    <DataTable
                        expandedRows={expandedMarketNodes} onRowToggle={(e) => {
                        setExpandedMarketNodes(e.data)
                    }}
                        rowExpansionTemplate={marketNodeDetailsTemplate}
                        expandedRowIcon="pi pi-chevron-up"
                        collapsedRowIcon="pi pi-chevron-down"
                        dataKey="id"
                        tableClassName={"justify-content-center"}
                        value={marketNodesData}
                        size="small"
                        resizableColumns
                        stripedRows
                        showGridlines
                        paginator
                        paginatorTemplate={TemplatePaginator}
                        first={first}
                        rows={rows}
                        onPage={onCustomPage}
                        filters={filters}
                        paginatorClassName="justify-content-end" className="mt-6"
                    >
                        <Column expander={allowExpansion} style={{minWidth: '2rem', maxWidth: '2rem'}}/>
                        <Column sortable field="marketNode.name" header=" Market node"
                                style={{minWidth: "10rem"}} filter/>
                        <Column sortable filterField="marketNodeStatus.name"
                                header="Last Status"
                                filterMenuStyle={{width: '14rem', height: '6rem'}}
                                style={{minWidth: '12rem'}}
                                showFilterMatchModes={false}
                                body={statusBodyTemplate}
                                filter
                                showApplyButton={false}
                                showClearButton={false}
                                filterElement={statusFilterTemplate}
                        />
                        <Column sortable field="lastStatusChange" header="Last Status Change" style={{minWidth: '10rem'}} filter/>
                        <Column header="Validation Progress"
                                style={{minWidth: '10rem'}}
                                body={validationProgress}
                        />
                        <Column sortable field="lastEdit" header="Last Edit" style={{minWidth: '10rem'}} filter/>
                        <Column sortable field="lastChangedBy" header="Last changed by"
                                style={{minWidth: '12rem'}} filter/>
                        <Column header="Manual check status"
                                body={manualCheckStatusBody}
                                style={{minWidth: "11rem"}}
                                alignHeader={"center"}/>
                        <Column header="Actions" body={r => BodyTemplate({
                            "dataCollection": marketNodesDetails.dataCollection,
                            "marketNodeData": r
                        })} style={{minWidth: '10rem'}}
                                headerStyle={{minWidth: "10rem"}} alignHeader={"center"}/>
                    </DataTable>
                </div>
            </div>
        </Aux>
    )
}

export default DetailsTable;
