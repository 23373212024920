import React from "react";
import classes from './LoadingSpinner.module.css'
import Aux from "../../hoc/Auxiliary";

const LoadingSpinner = () => {

    return (
        <Aux>
            <div className={classes.loader}>Loading...</div>
        </Aux>
    )


}

export default LoadingSpinner;