import React from "react";
import Aux from "../../../../../../hoc/Auxiliary";

const ColumnHeader = (props) => {
    return (<Aux>
        <div className={`col p-0 flex align-items-center justify-content-center`}>
            <h3>{props.name}</h3>
        </div>
    </Aux>)
}
export default ColumnHeader;
