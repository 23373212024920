import React, {useCallback, useEffect, useState} from "react";
import {Button} from "primereact/button";
import {isClosed, isDraft} from "../../../../api/dataCollection/dataCollection-status";
import {useNavigate} from "react-router-dom";
import {normalizePathItem} from "../../../BreadCrumb/Breadcrumb";

const EditDataCollectionButton = ({rowData}) => {

    const [disableEditButton, setDisableEditButton] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setDisableEditButton(isClosed(rowData?.dataCollectionStatus))
    }, [rowData])


    const editDataCollection = useCallback(() => {
        if (isDraft(rowData?.dataCollectionStatus)) {
            navigate("/data_collections/edit/" + normalizePathItem(rowData?.name),
                {state: {dataCollection: {id: rowData?.id, name: rowData?.name}}})
        } else {
            navigate("/data_collections/edit/published/" + normalizePathItem(rowData?.name),
                {state: {dataCollection: {id: rowData?.id, name: rowData?.name}}})
        }
    }, [])


    return (<Button icon="pi pi-pencil"
                    tooltip="Edit data collection settings"
                    tooltipOptions={{position: 'top', showOnDisabled: true}}
                    className=" p-button-rounded-sm p-button-text"
                    style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                    disabled={disableEditButton}
                    onClick={editDataCollection}


    />)
}
export default EditDataCollectionButton;
