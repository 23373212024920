import Auxiliary from "../../../../../hoc/Auxiliary";
import CalendarField from "./CalendarField/CalendarField";
import {useEffect, useState} from "react";
import {checkOpenFromDeadLineValidity, executeOnScroll} from "../../../../../api/utils/data-collection-form";
import {updateProperty} from "../../../../../api/utils/conditions-utils";

const OpenFromDeadLineFields = ({dataCollection, handleChange, updateOpenFromDeadLineFields, collapsed, scroll}) => {
    const [opv, opm, dlv, dlm] = checkOpenFromDeadLineValidity(dataCollection.openFrom, dataCollection.deadline)
    const [isOpenFromValid, setIsOpenFromValid] = useState(updateProperty(collapsed, opv, true));
    const [openFromValidErrorMessage, setOpenFromValidErrorMessage] = useState(updateProperty(collapsed, opm, ""));
    const [isDeadlineValid, setIsDeadlineValid] = useState(updateProperty(collapsed, dlv, true));
    const [deadlineValidErrorMessage, setDeadlineValidErrorMessage] = useState(updateProperty(collapsed, dlm, ""));

    /**
     * When data collection is copied from another one deadline is defined as null
     * */
    useEffect(() => {
        if (dataCollection.deadline === null) {
            checkOpenFromDeadLine();
        }
    }, [dataCollection.deadline])

    /**
     * Update validation status
     * */
    useEffect(() => {
        updateOpenFromDeadLineFields({
            isOpenFromValid: isOpenFromValid,
            openFromValidErrorMessage: openFromValidErrorMessage,
            isDeadlineValid: isDeadlineValid,
            deadlineValidErrorMessage: deadlineValidErrorMessage,
        })
    }, [isOpenFromValid, isDeadlineValid])

    /**
     * When user scroll in screen, run validation
     * */
    useEffect(() => {
        executeOnScroll(scroll, checkOpenFromDeadLine)
    }, [scroll])

    /**
     * Run validation on openFrom deadline field
     * */
    const checkOpenFromDeadLine = () => {
        // openFrom isValid - openFrom errorMessage - deadline isValid - deadline errorMessage
        const [opv, opm, dlv, dlm] = checkOpenFromDeadLineValidity(dataCollection.openFrom, dataCollection.deadline)
        setIsOpenFromValid(opv);
        setOpenFromValidErrorMessage(opm);
        setIsDeadlineValid(dlv);
        setDeadlineValidErrorMessage(dlm);
    };

    return (
        <Auxiliary>
            <div className="col-4">
                <CalendarField
                    title={"Open from"}
                    name={"openFrom"}
                    value={dataCollection.openFrom}
                    onChange={handleChange}
                    checkIsValid={checkOpenFromDeadLine}
                    isValid={isOpenFromValid}
                    errorMessage={openFromValidErrorMessage}
                />
            </div>
            <div className="col-4">
                <CalendarField
                    title={"Deadline"}
                    name={"deadline"}
                    value={dataCollection.deadline}
                    onChange={handleChange}
                    minDate={new Date()}
                    checkIsValid={checkOpenFromDeadLine}
                    isValid={isDeadlineValid}
                    errorMessage={deadlineValidErrorMessage}
                />
            </div>
        </Auxiliary>
    )
}

export default OpenFromDeadLineFields;
