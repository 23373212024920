import React, {useState} from "react";
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import Header from "../../../commun/Header/Header";
import Auxiliary from "../../../../hoc/Auxiliary";
import classes from './ManagePermissions.module.css';
import classesP from '../ParticipantsSetting/ParticipantsSetting.module.css'
import Row from "./Row/Row";
import {Divider} from "primereact/divider";
import TabHeader from "./TabHeader/TabHeader";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";

const ManagePermissions = (props) => {
    const [permission, setPermission] = useState({...props.permissions});
    const [oldConfig, setOldConfig] = useState({});
    const [couter, setCounter] = useState(0);
    const permissionNames = props.permissionsData.map(e => e.name);
    const defaultDataPermissionVisibility = false;
    const accept = () => {
        setPermission(oldConfig);
        props.setPermissionPerParticipant(oldConfig)
        props.setDialogVisible(false)
    };

    const reject = () => {
        props.setPermissionPerParticipant(permission)
    };

    const confirm = () => {
        confirmDialog({
            message: 'Changes are not saved, are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(),
            reject: () => reject(),
            appendTo: "self",
            acceptClassName: `${classes.button} ${classesP.actionBtn}`,
            rejectClassName: `${classes.button} ${classesP.actionBtn}`
        });
    }


    const renderHeader = () => {

        return (<Auxiliary>
            <Header title={"Configure data collection permission"}
                    description={"Set data collection permission here."}/>
            <div className={classes.buttons} id={"dialog-header"}>
                <Button key="default-permission-btn" visible={defaultDataPermissionVisibility}
                        className={`${classes.button} ${classesP.actionBtn}`}
                        type="button"
                        label="Use default permissions"
                        onClick={() => {
                            // implement default permissions here
                            props.setDialogVisible(false)
                        }} disabled={true}/>
                <Button key="save-btn" className={`${classes.button} ${classesP.actionBtn}`} label="Save"
                        type="button"
                        onClick={() => {
                            props.setPermissionPerParticipant(permission)
                            props.setDialogVisible(false)
                        }}
                />
                <Button id={"close-btn"}
                        key="close-btn"
                        type="button"
                        className={`${classes.button} ${classesP.actionBtn}`}
                        label="Close"
                        onClick={() => {
                            if (couter > 0) {
                                confirm();
                            } else {
                                reject();
                                props.setDialogVisible(false)
                            }
                        }
                        }
                ></Button>
                <ConfirmDialog/>
            </div>

        </Auxiliary>);
    }

    return (
        <Dialog header={renderHeader}
                visible={props.isDialogVisible}
                onHide={() => props.setDialogVisible(false)}
                breakpoints={{'960px': '75vw'}}
                style={{width: '80vw', height: '50vw'}}
                onShow={() => {
                    setPermission({...props.permissions})
                    setOldConfig(JSON.parse(JSON.stringify(props.permissions)))
                    setCounter(0);
                }}
                closable={false}
                closeOnEscape={false}
                modal={true}

        >
            <div className={`card ${classes.marginBottom}`}>
                <TabHeader permissionNames={permissionNames}/>
            </div>
            <div className="card">
                {React.Children.toArray(
                    props.participants.map((participant, i) => (
                        <Auxiliary key={`c-${i + 1}`}>
                            <Row permissionNames={permissionNames}
                                 participantPermissions={permission}
                                 participant={participant}
                                 setPermissionPerParticipant={(e) => {
                                     setCounter(couter + 1)
                                     setPermission(e);
                                 }}
                                 version={props.version}
                                 rowId={i}
                            />
                            <div className={classes.marginHorizontal}>
                                <Divider/>
                            </div>
                        </Auxiliary>))
                )}
            </div>
        </Dialog>
    )
}


export default ManagePermissions;
