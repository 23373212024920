/**
 * Format {@link Date} to dd/MM/yyyy
 * @param date : Date to be formatted
 * */
export const formatDate = (date) => {
    return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
}

/**
 * convert String date "dd/MM/YYYY" to {@link Date}
 * @param date String date
 * @param separator  default  is '/'
 *
 * */
export const convert_dd_mm_yyyy_to_Date = (date, separator = "/") => {
    const x = date.split(separator)
    return new Date(`${x[1]}/${x[0]}/${x[2]}`)
}
