import React from "react";
import Aux from "../../../../../hoc/Auxiliary";
import {Button} from "primereact/button";
import EditDataCollectionButton from "../../EditButton/EditDataCollectionButton";
import {confirmDialog} from "primereact/confirmdialog";
import {deleteDataCollectionDraft} from "../../../../../api/dataCollection/dataCollection-service";
import {showSuccess} from "../../../../../api/notification/ToastManager";
import classes from "../../DataCollectionList.module.css";
import classesP from "../../../DataCollectionCreator/ParticipantsSetting/ParticipantsSetting.module.css";

const DraftActionButtons = ({rowData, setDraftList, dcListDraft,setIdDeleting,idDeleting}) => {
    const confirmDeleteDc = (dcId, dcName) => {
        confirmDialog({
            message: 'Are you sure you want to delete data collection draft "' + dcName + '" ?',
            header: 'Confirmation',
            accept: () => {
                setIdDeleting([dcId, ...idDeleting])
                deleteDataCollectionDraft({"dcId": dcId, "dcName": dcName}).then(r => {
                    if (r.status === 200) {
                        setDraftList(dcListDraft.filter(x => x.id !== dcId))
                        showSuccess("Data collection deleted successfully !")
                    }
                })
            },
            reject: () => {
            },
            icon: 'pi pi-exclamation-triangle',
            appendTo: "self",
            acceptClassName: `${classes.buttons} ${classesP.actionBtn}`,
            rejectClassName: `${classes.buttons} ${classesP.actionBtn}`

        });
    }
    return (
        <Aux>
            <div className=" grid p-0 ">
                <div className="col p-0 m-0 flex align-items-center justify-content-center">
                    <Button icon=" pi pi-trash"
                            tooltip=" Delete data collection draft"
                            tooltipOptions={{position: 'top', showOnDisabled: true}}
                            className=" p-button-rounded-sm p-button-text"
                            onClick={() => confirmDeleteDc(rowData.id, rowData.name)}
                            style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}/>
                </div>

                <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                    <EditDataCollectionButton rowData={rowData}/>
                </div>
            </div>
        </Aux>
    )
}
export default DraftActionButtons;
