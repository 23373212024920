import React from "react";
import Aux from "../../../../hoc/Auxiliary";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {normalizePathItem} from "../../../BreadCrumb/Breadcrumb";
import {STUDY_TEAM_SPACE_PATH} from "../../../../api/static-data/Configurations";

const ActionTemplate = ({row}) => {
    const navigate = useNavigate();

    const searchQueries = () => {
        navigate(STUDY_TEAM_SPACE_PATH + '/' + normalizePathItem(row?.name) + '/queries', {
            state: {
                dataCollection: {
                    name: row.name,
                    id: row.id
                }
            }
        })
    }

    return (
        <Aux>
            <div className=" grid p-0 ">
                <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                    <Button icon=" pi pi-angle-right"
                            tooltip="Data collection Queries"
                            tooltipOptions={{position: 'top', showOnDisabled: true}}
                            className=" p-button-rounded-sm p-button-text"
                            onClick={searchQueries}
                            style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                            disabled={false}/>
                </div>
            </div>
        </Aux>
    )
}
export default ActionTemplate;
