import React from "react";
import Cell from "./Cell/Cell";

import classes from "./Row.module.css"
import Aux from "../../../../../hoc/Auxiliary";

const Row = (props) => {
    return (
        <Aux>
            <div className={`grid ${classes.marginHorizontal}`}>

                <div className={`col p-0 ${classes.center}`}>
                    <h3>{props.participant.name}</h3>

                </div>
                {React.Children.toArray(
                    props.permissionNames.map((permission, i) => (
                        <Aux key={`${props.rowId}-${i + 1}`}>
                            <div className="col p-0">
                                <Cell marketNodePermissions={props.participantPermissions}
                                      currentGroupID={props.participant.groupId}
                                      setPermissionPerParticipant={(e) => props.setPermissionPerParticipant(e)}
                                      name={permission}
                                      marketNodes={props.version.marketNodes}
                                      showSelectAll={permission !== "Write"}
                                />
                            </div>
                        </Aux>
                    ))
                )
                }
                <div className={`col p-0 ${classes.center}`}>
                    {props.participant.isEntsoeMember || props.participant.entsoeMember
                        ? <i className="pi pi-check" style={{paddingTop: "20px"}}/>
                        : <i className="pi pi-times" style={{paddingTop: "20px"}}/>}
                </div>
            </div>
        </Aux>)
}

export default Row;
