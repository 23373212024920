import React, {useEffect, useState} from "react";
import "../../../../QueryConfiguration.css"
import {Dropdown} from "primereact/dropdown";
import Auxiliary from "../../../../../../../../../hoc/Auxiliary";
import {MultiSelect} from "primereact/multiselect";


const ClassComponent = (props) => {
    const [items, setItems] = useState([]);
    const [cU, setCU] = useState(null);

    useEffect(() => {
        setItems(JSON.parse(props.param.properties?.paramClsItems))
    }, [])

    const transformCheckedClasses = (e) => {
        if (props.param.properties.isMultiple) {
            setCU(e)
        } else {
            setCU(e[0])
        }
        props.setValue(e.map(v => v.uri), props.param);
    }

    const oneValue = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <Dropdown key={props.keyEl}
                      value={cU}
                      onChange={(e) => transformCheckedClasses([e.value])}
                      options={items} optionLabel="label"
                      placeholder="PEMMDB Class" className="w-full md:w-13rem"/>
        </div>
    )
    const multipleValues = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <MultiSelect key={props.keyEl}
                         value={cU}
                         onChange={(e) => transformCheckedClasses(e.value)}
                         options={items}
                         optionLabel="label"
                         placeholder="PEMMDB Class"
                         className="w-full md:w-13rem"/>
        </div>
    )

    const content = props.param.properties.isMultiple ? multipleValues : oneValue;

    return (
        <Auxiliary>
            {content}
        </Auxiliary>
    );
}

export default ClassComponent;
