import axios from 'axios';
import keycloak from "../../keycloak";
import {loadFromSessionStorage} from "../State/manage-state-storage-service";
import {RULES_KEY} from "../static-data/Configurations";
axios.defaults.withCredentials = true

export async function findAllRulesPermission() {
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        await axios.get(global.config.BACK_URL + "permissions/roles/details", header)
            .then(res => {
                if (res.status === 200) {
                    response.status = res.data.status;
                    response.content = res.data.content;
                }
            })
        return response;
    } catch (e) {
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
}

export function isAuthorized(ruleCode) {
    const rules = loadFromSessionStorage(RULES_KEY)
    return rules !== null ? rules.includes(ruleCode) : false;
}
