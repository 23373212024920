import React from "react";
import "../../../../QueryConfiguration/QueryConfiguration.css"
import {InputText} from "primereact/inputtext";
import Aux from "../../../../../../../../hoc/Auxiliary";
import Auxiliary from "../../../../../../../../hoc/Auxiliary";
import SheetsComponent from "./GeneralParams/SheetsComponent";
import MarketNodesComponent from "./GeneralParams/MarketNodesComponent";
import ComponentByType from "./ComponentByType";
import {MAX_NAME_LENGTH} from "../../../../../../../../api/static-data/Configurations";


const ExecutionStructure = (props) => {

    return (
        <Aux>
            <h4 className="titleH4Query">General Parameters :</h4>
            <div className="grid">
                <div className="col-6">
                    <h5>Execution Name</h5>
                    <InputText key="exec-name"
                               value={props.executionName !== null ? props.executionName : ""}
                               className="w-full md:w-10"
                               onChange={(e) => props.setExecutionName(e.target.value.substring(0, MAX_NAME_LENGTH))}/>
                    <br/>
                    <small className="pi pi-info-circle ml-2" style={{fontSize: "13px"}}> Execution name length must not
                        exceed {MAX_NAME_LENGTH} characters</small>
                </div>
                {props.query.parameters.map(p => {
                    if (p.name === "MarketNodes") {
                        return (
                            <Auxiliary>
                                <MarketNodesComponent keyEl={`${props.query.queryId + p.name}`} p={p}
                                                      query={props.query}
                                                      inProgress={props.inProgress}
                                                      setInProgress={props.setInProgress}
                                                      marketNodes={props.marketNodes}
                                                      dcMarketNodes={props.dcMarketNodes}
                                                      setMarketNodes={props.setMarketNodes}
                                />
                                <div className="col-1"></div>
                            </Auxiliary>)
                    }

                    if (p.name === "CategorySheets") {
                        return <SheetsComponent keyEl={`${props.query.queryId + p.name}Sh`} p={p} query={props.query}
                                                inProgress={props.inProgress} setInProgress={props.setInProgress}
                                                sheets={props.sheets} setSheets={props.setSheets}/>
                    }

                })}
            </div>
            {
                props.sparqlParameters.length > 0 && <h4>SPARQL Parameters :</h4>
            }
            <div className="grid">
                {props.sparqlParameters.map(p => {
                    return (
                        <ComponentByType keyEl={p.name} param={p} value={null}
                                         setValue={(e, p) => props.setupParameter(e, p)}/>
                    )
                })}
            </div>
        </Aux>
    )
}

export default ExecutionStructure;
