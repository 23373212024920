import React, {useRef, useState} from "react";
import Aux from "../../../../../../../hoc/Auxiliary"
import {InputText} from "primereact/inputtext";
import classes from "../../../DataVisualisation.module.css";
import {Button} from "primereact/button";
import {FileUpload} from "primereact/fileupload";
import {Tooltip} from "primereact/tooltip";
import {showError} from "../../../../../../../api/notification/ToastManager";
import {saveOverviewQuery} from "../../../../../../../api/file/upload-service";
import keycloak from "../../../../../../../keycloak";
import {classNames} from "primereact/utils";


const NewQuery = (props) => {
    const fileUploadRef = useRef(null);
    const [fileIsUploaded, setFileIsUploaded] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isPersistLoading, setIsPersistLoading] = useState(false);
    const [fileContent, setFileContent] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fileName, setFileName] = useState();
    const [queryName, setQueryName] = useState();

    const chooseOptions = {
        icon: 'pi pi-upload'
    };
    const getBase64 = file => {
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    }
    let setButtonUpload = () => {
        return (
            <Aux>
                <div className={"grid"}>
                    <div className={"col-2 mr-3"}>
                        <FileUpload
                            ref={fileUploadRef}
                            name="demo[]"
                            accept=".sparql"
                            maxFileSize={100000000000}
                            mode="basic"
                            className={classes.uploadBtn}
                            chooseOptions={chooseOptions}
                            chooseLabel={" "}
                            onSelect={(e) => handleFileInputChange(e)}
                        />
                    </div>
                    <div className={"col-8"}>
                        <Tooltip target=".custom-tooltip"/>
                        <p className={classes.fileName}
                           data-pr-tooltip="No notifications"
                           data-pr-position="bottom">
                                <span className="custom-tooltip"
                                      data-pr-tooltip={fileName}
                                      data-pr-position="bottom">{fileName}</span>
                        </p>
                    </div>
                </div>
            </Aux>
        );

    }

    const handleFileInputChange = (e) => {

        let file = e.files[0];

        if (file.name.toString().length < 100) {
            let ext = file.name.split(".").pop();
            if (ext.toLowerCase() !== "sparql") {
                showError("Uploaded file must be SPARQL")
                setFileIsUploaded(false);
                fileUploadRef.current.clear();
                setFileName("");
            } else {
                setFileIsUploaded(true);
                setFileName(file['name']);
                getBase64(file).then((res) => {
                    setFileContent(res);
                    setFileIsUploaded(false);
                });
            }
        } else {
            setFileIsUploaded(false);
            fileUploadRef.current.clear();
            setFileName("");
            showError("Uploaded file name must not exceed 100 characters")
        }


    };


    const updateValue = (e) => {
        setQueryName(e.target.value)
        if (e.target.value.length > 0) {
            let exist = props.queries.filter(q => q.name === e.target.value);

            if (exist.length > 0) {
                setIsValid(false);
                setErrorMessage("Query name exist : " + e.target.value)
            } else {
                setIsValid(true)
            }
            if (e.target.value.length > 100) {
                setIsValid(false)
                setErrorMessage("Query name must not exceed 100 characters");

            } else {
                if (e.target.value.match(/^[-_ a-zA-Z0-9]+$/) === null || new RegExp("\\s+").test(e.target.value)) {
                    setIsValid(false)
                    setErrorMessage("Query name may only contain letters, numbers, underscores '_' or hyphens '-'");
                }
            }


        } else {
            setIsValid(true);
            setErrorMessage(null)
        }


    }
    const persistQuery = () => {
        try {
            props.setCreateQueryInProgress(true)
            setIsPersistLoading(true)
            saveOverviewQuery({
                    file: {
                        filename: queryName + "-" + fileName,
                        contentFile: fileContent,
                        filePath: ""
                    },
                    dcId: props.dataCollectionId,
                    queryName: queryName,
                    createdBy: keycloak.idTokenParsed.name,
                    createdIn: new Date(),
                }
            ).then((res) => {
                props.setCreateQueryInProgress(false)
                setIsPersistLoading(false)
                if (res.status === 200) {
                    props.setQueries(res.content);
                    abortQuery();
                }
            })
        } catch (err) {
            fileUploadRef.current.clear();
        }
    }

    function abortQuery() {
        setFileIsUploaded(false);
        setFileContent(null);
        setFileName("");
        setQueryName("");
        props.setCreateQueryInProgress(false)
        fileUploadRef.current.clear();

    }

    return (
        <Aux>
            {props.createQueryInProgress ?
                <div className="col-12 border-round border-transparent shadow-1 max-w-full mb-2">

                    <div className="grid m-2">
                        <h3 className={classes.title}>Add new SPARQL query</h3>
                    </div>
                    <div className="grid m-2">
                        <div className={"col-4"}>
                            <div className="field">
                                <span className="p-float-label"><b>Query Name</b></span>
                                <br/>
                                <InputText
                                    id={"name"}
                                    name={"name"}
                                    className={classNames({'p-invalid': !isValid}, classes.inputStyle)}
                                    value={props.newQuery?.name === null ? "" : props.newQuery}
                                    onChange={updateValue}
                                />
                                {isValid ? null
                                    : <small
                                        className="p-error block">{errorMessage}</small>}
                            </div>
                        </div>
                        <div className="col-4">
                            <span className="p-float-label"><b>Upload SPARQL query</b></span>
                            <br/>
                            {
                                fileIsUploaded ?
                                    <Button
                                        tooltip={fileName}
                                        className={classes.uploadSpinnerBtn}
                                        label={fileName} loading={true}/> :
                                    setButtonUpload()
                            }
                        </div>
                    </div>
                    <div className="grid flex flex-row-reverse m-2">
                        <div className={"col-2"}>
                            <Button
                                loading={isPersistLoading}
                                name="Persist query"
                                className={classes.confirmBtn}
                                label="Save query"
                                disabled={queryName === undefined || queryName === "" || fileContent === null || !isValid}
                                onClick={persistQuery}
                            />
                        </div>
                        <div className={"col-1"}>
                            <Button
                                name="Quit without saving"
                                tooltipOptions={{position: 'bottom', showOnDisabled: true}}
                                tooltip={"Quit without saving"}
                                className={classes.rejectBtn}
                                label="Abort"
                                onClick={abortQuery}
                            />
                        </div>
                    </div>
                </div>
                : null}

        </Aux>
    )
}

export default NewQuery;




