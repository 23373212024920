import classes from "../Token.module.css";
import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import TemplatePaginator from "../../commun/TemplatePaginator/TemplatePaginator";
import {Column} from "primereact/column";
import TokenListHeader from "../Header/TokenListHeader";
import ActionColumnToken from "../ActionBody/ActionColumnToken";
import {Skeleton} from "primereact/skeleton";
import {MultiSelect} from "primereact/multiselect";
import {getUniqueObjectsByProperty} from "../../../api/utils/UniqueObjectsByProperty";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import HeaderBreadCrumb from "../../BreadCrumb/Breadcrumb";
import {ConfirmDialog} from "primereact/confirmdialog";

const TokenTable = (props) => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [accessKeyList, setAccessKeyList] = useState(props.accessKeys);
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }
    useEffect(() => {
        setAccessKeyList(props.accessKeys)
    }, [props.accessKeys])
    const actionsBodyTemplate = (row) => (
        <div><ActionColumnToken rowData={row} setReload={props.setReload} reload={props.reload}/></div>)

    const items = Array.from({length: 5}, (v, i) => i);
    const statusFilterTemplate = () => {
        return <MultiSelect value={selectedStatus} options={getUniqueObjectsByProperty(props.accessKeys, "status")}
                            onChange={onFilter}
                            optionLabel="status" placeholder="Select a status" className="p-column-filter"/>
    }
    const accessKeyTemplate = (row) => {
        return row.accessKey ? row.accessKey : <p className="m-0"  style={{color:"#dee2e6", cursor:"not-allowed"}}>●●●●●●●●●●●●●●●●●●●●●●●●●●●●</p>
    }
    const onFilter = (e) => {
        let result = [...props.accessKeys]
        if (e.value.length !== 0) {
            result = [...props.accessKeys
                .filter(e1 => e.value.map(v => v.status).some(val => e1.status.includes(val)))]
        }
        setSelectedStatus(e.value);
        setAccessKeyList(result);
    };
    const statusFilterProperties = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        'status': {value: null, matchMode: FilterMatchMode.IN},
        'username': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}
    };

    return (
        <>
            <HeaderBreadCrumb items={["Access Keys"]}/>
            <div className=" card">
                <DataTable value={props.tableLoading ? items : accessKeyList}
                           header={<TokenListHeader/>}
                           size="small"
                           resizableColumns
                           stripedRows
                           showGridlines
                           filters={statusFilterProperties}
                           paginator paginatorTemplate={TemplatePaginator}
                           first={first} rows={rows} onPage={onCustomPage}
                           paginatorClassName="justify-content-end" className="mt-6">
                    <Column className={classes.cellWidth} field="username" sortable
                            header="Username"
                            filter body={props.tableLoading && <Skeleton/>}/>
                    <Column className={classes.bigCell}
                            header="Access key" body={props.tableLoading ? <Skeleton/> : accessKeyTemplate}/>
                    <Column field="expirationDate" header="Expiration date" className={classes.dateCellWidth}
                            sortable body={props.tableLoading && <Skeleton/>}/>
                    <Column className={classes.cellWidth}
                            header="Status" body={props.tableLoading && <Skeleton/>}
                            field="status"
                            showApplyButton={false}
                            showClearButton={false}
                            showFilterMatchModes={false}
                            filter
                            filterMenuStyle={{width: '14rem', height: '6rem'}}
                            filterElement={statusFilterTemplate}/>
                    <Column className={classes.actionCell} header="Actions"
                            body={props.tableLoading ? <Skeleton/> : actionsBodyTemplate}/>
                </DataTable>
            </div>
            <ConfirmDialog/>
        </>
    )


}
export default TokenTable;