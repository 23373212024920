import React from "react";
import Aux from "../../../hoc/Auxiliary";
import ExportButton from "./ExportButton/ExportButton";
import EditButton from "./EditButton/EditButton";
import CheckInButton from "./CheckInButton/CheckInButton";
import {canExportDataWithSpecificFormat} from "../../../api/authorization/rules";
import {checkIfMarketNodeIsPublished} from "../../../api/MarketNode/marketNode-status";

const BodyTemplate = (data) => {

    const checkVisibility = () => {
        return canExportDataWithSpecificFormat(data.dataCollection.participants, data.marketNodeData.marketNode);
    }

    const isDisabled = () => {
        return !checkIfMarketNodeIsPublished(data.marketNodeData.marketNodeStatus.code)
    }

    return (<Aux>
        <div className="grid">
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <CheckInButton rowData={data}/>
            </div>
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <ExportButton
                    listOfMarketNodes={[data.marketNodeData.marketNode]}
                    dcName={data.dataCollection.name}
                    dcId={data.dataCollection.id}
                    isDisplayed={checkVisibility()}
                    disabled={isDisabled()}
                    messageOnDisable={"Will be enabled when market node is published."}
                />
            </div>
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <EditButton rowData={data}/>
            </div>
        </div>
    </Aux>)
}

export default BodyTemplate;
