import React, {useEffect, useState} from "react";
import HeaderBreadCrumb from "../../../BreadCrumb/Breadcrumb";
import {useLocation, useNavigate} from "react-router-dom";
import LoadingScreen from "../../../../containers/LoadingScreen/LoadingScreen";
import Aux from "../../../../hoc/Auxiliary"
import {findDataCollectionById} from "../../../../api/dataCollection/dataCollection-service";
import Dashboards from "./Dashboards/Dashboards";
import {Fieldset} from "primereact/fieldset";
import classes from "./DataVisualisation.module.css";
import keycloak from "../../../../keycloak";
import {isAuthorized} from "../../../../api/authorization/authorization-service";
import ErrorServer from "../../../ErrorServer/ErrorServer";
import QueriesDetailsConfigurations from "./QueriesConfigurations/DetailedQueries/QueriesDetailsConfigurations";
import QueriesOverviewConfigurations from "./QueriesConfigurations/OverviewQueries/QueriesOverviewConfigurations";
import {updateProperty} from "../../../../api/utils/conditions-utils";
import {loadState, removeFromSessionStorage} from "../../../../api/State/manage-state-storage-service";
import {onServerCallError} from "../../../../api/utils/errors";

const DataVisualisation = () => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [dcName, setDcName] = useState(null)
    const [groups, setGroups] = useState(null)
    const [crumbs, setCrumbs] = useState([])
    const [dashboardSectionTitle, setDashboardSectionTitle] = useState("")
    const [dashboardSectionCollapsed, setDashboardSectionCollapsed] = useState(true)
    const [categories, setCategories] = useState([]);
    const [dataCollection, setDataCollection] = useState({});

    useEffect(() => {
        setIsLoading(true);
        try {
            const group = keycloak.idTokenParsed.fullPathGroup.flatMap(e => e.split("/"));
            const dc = loadState(state, "dataCollection");
            if (dc?.name && dc?.id && group !== null) {
                setDcName(dc?.name);
                setGroups(group)
                setCrumbs(["Data Collections", "Dashboards"]);
                findDataCollectionById(dc?.id).then(res => {
                    if (res.categories) {
                        setDataCollection(res)
                        setCategories(res.categories);
                    } else {
                        setIsError(true)
                    }
                }).catch(_ => {
                    onServerCallError("Data collection not find", "Could not find a data collection with name '" + dc?.name + "'", goBack)
                })
                setIsLoading(false);
            } else {
                goBack()
            }
            const isAuth = isAuthorized("AR08") === true;
            setDashboardSectionTitle(updateProperty(isAuth, "Manage Dashboards", "Data Collection Dashboards"))
            setDashboardSectionCollapsed(isAuth)
        } catch (e) {
            onServerCallError("Error loading page", "An error occurs while displaying Dashboards page", goBack)
        }
    }, [])

    const goBack = () => {
        removeFromSessionStorage("dataCollection");
        navigate('/data_collections');
    }

    const headerInfo = (
        <Aux>
            <h3 className={classes.title}>{dcName}</h3>
        </Aux>
    )

    const error = isError
        ? (<ErrorServer/>)
        : (<Aux>
            <HeaderBreadCrumb items={crumbs}/>
            <br/>
            {headerInfo}
            <br/>
            {updateProperty(isAuthorized("AR01"),
                <Aux>
                    <Fieldset legend="Manage queries to persist detailed data" toggleable collapsed={true}>
                        {updateProperty(isAuthorized("AR01"),
                            <p className={classes.text}>⚠️ Sheet without query will not be persisted.</p>, null)}
                        <QueriesDetailsConfigurations categories={categories} dataCollection={dataCollection}/>
                    </Fieldset>
                    <br/>
                    <Fieldset legend="Manage queries to persist overview data" toggleable collapsed={true}>
                        <QueriesOverviewConfigurations dataCollectionId={dataCollection.id}
                                                       dataCollectionName={dataCollection.name}/>
                    </Fieldset>
                    <br/>
                </Aux>
                , null)}
            <Fieldset legend={dashboardSectionTitle} toggleable={dashboardSectionCollapsed}
                      collapsed={dashboardSectionCollapsed}>
                {updateProperty(
                    isAuthorized("AR08"),
                    <div>
                        <p className={classes.text}>⚠️ Dashboard tag name in kibana must be equal to the data collection
                            name.
                            Otherwise,
                            dashboard will not be attached to the data collection. </p>
                        <p className={classes.text}>⚠️ Dashboard will be shared with all participants.
                        </p>
                    </div>, null
                )}
                <Dashboards setIsLoading={setIsLoading} setIsError={setIsError} dcName={dcName} groups={groups}/>
            </Fieldset>
        </Aux>);

    return updateProperty(isLoading, <LoadingScreen/>, error);
}

export default DataVisualisation;
