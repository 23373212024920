import React, {useEffect, useState} from "react";
import HeaderBreadCrumb, {normalizePathItem} from "../../BreadCrumb/Breadcrumb";
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import Aux from "../../../hoc/Auxiliary";
import {getModelTags, getModelVersion, searchAllModels} from "../../../api/DataModel/data-model";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import LoadingScreen from "../../../containers/LoadingScreen/LoadingScreen";
import {
    loadFromSessionStorage,
    loadState,
    removeFromSessionStorage
} from "../../../api/State/manage-state-storage-service";
import {updateProperty} from "../../../api/utils/conditions-utils";
import {onServerCallError} from "../../../api/utils/errors";

const NewVersion = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [roots, setRoots] = useState(["PEMMDB Versions"]);
    const [modelId, setModelId] = useState(0);
    const [modelName, setModelName] = useState('');
    const [modelDescription, setModelDescription] = useState('');
    const [modelTag, setModelTag] = useState();
    const [modelPath, setModelPath] = useState('');
    const [modelCreationDate, setModelCreationDate] = useState('');
    const [modelStatus, setModelStatus] = useState("DRAFT");
    const [documentation, setDocumentation] = useState({});
    const [accessWrite, setAccessWrite] = useState(false);
    const [oldModel, setOldModel] = useState({});
    const [btnLabel, setButtonLabel] = useState("Validate Model")
    const [isValid, setIsValid] = useState(false);
    const [templateName, setTemplateName] = useState('Upload Template');
    const [modelsName, setModelsName] = useState([]);

    const [isDraft, setIsDraft] = useState(false);
    const {state} = useLocation();
    let {version} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            searchAllModels().then((res) => {
                setModelsName(res.content.map((c) => c.name));
                getModelTags().then(res => {
                    setData(updateProperty(res != null, [...res.data.content], []))
                    setIsLoading(false);
                }).catch(_ => {
                    onServerCallError("Failed to retrieve PEMMDB model tags", "Un error occurs while retrieving PEMMDB Model tags", goBack)
                })
            }).catch(_ => {
                onServerCallError("Failed to retrieve PEMMDB models", "Un error occurs while retrieving existing PEMMDB versions", goBack)
            })
        } catch (e) {
            onServerCallError("Failed to retrieve PEMMDB models", "Un error occurs while retrieving existing PEMMDB versions", goBack)
        }
    }, []);

    const goBack = () => {
        navigate('/pemmdb_versions')
    }

    useEffect(() => {
        if (loadFromSessionStorage("version")) {
            let selectedModel = loadState(state, "version")
            getModelVersion(selectedModel.model.versionName).then(r => {
                selectedModel = {model:r};
                if (normalizePathItem(selectedModel?.model.name) === version) {
                    setRoots([roots[roots.length - 1], selectedModel.model.name]);
                    setModelName(selectedModel.model.name);
                    setModelId(selectedModel.model.id);
                    setModelTag(selectedModel.model.tag);
                    setModelPath(selectedModel.model.path);
                    setModelDescription(selectedModel.model.description);
                    setModelStatus(selectedModel.model.modelStatus.code);
                    setModelCreationDate(selectedModel.model.createdIn)
                    setOldModel({...selectedModel});
                    setDocumentation(selectedModel.model.documentation)
                    setAccessWrite(true);
                    setIsDraft(selectedModel.model.modelStatus.code === 'DRAFT');
                    setIsValid(selectedModel.model.documentation.validationResult);
                    if (selectedModel.model.documentation.validationResult) {
                        setTemplateName(selectedModel.model.documentation.uploadedFile)
                    } else {
                        setTemplateName(updateProperty(
                            selectedModel.model.documentation.uploadedFile === '',
                            "Upload Template",
                            selectedModel.model.documentation.uploadedFile
                        ))
                    }

                } else {
                    removeFromSessionStorage("version");
                    goBack()
                }
            });
        } else {
            removeFromSessionStorage("version");
            setRoots([roots.pop(), "new"]);
            setAccessWrite(true);
            setIsDraft(true);
        }
    }, [])

    let content = isLoading
        ? (<LoadingScreen/>)
        : (<Aux>
            <HeaderBreadCrumb items={roots}/>
            <h3>Setup PEMMDB Version</h3>
            <p>Create new PEMMDB Version or edit an existing PEMMDB Version. </p>
            <GeneralSettings models={data}
                             id={modelId}
                             name={modelName}
                             tag={modelTag}
                             path={modelPath}
                             modelStatus={modelStatus}
                             accessWrite={accessWrite}
                             description={modelDescription}
                             creationDate={modelCreationDate}
                             oldModel={oldModel}
                             buttonLabel={btnLabel}
                             isValid={isValid}
                             templateName={templateName}
                             setTemplateName={setTemplateName}
                             setIsValid={setIsValid}
                             setButtonLabel={setButtonLabel}
                             isDraft={isDraft}
                             setIsDraft={setIsDraft}
                             documentation={documentation}
                             setDocumentation={setDocumentation}
                             modelsName={modelsName}
            />
        </Aux>)
    return <div>{content}</div>
}

export default NewVersion;
