import React, {useState} from "react";
import HeaderBreadCrumb, {normalizePathItem} from "../BreadCrumb/Breadcrumb";
import Aux from "../../hoc/Auxiliary"
import classes from "./PemmdbVersionsControl.module.css";
import {DataTable} from 'primereact/datatable';
import {Column} from "primereact/column";
import {Tag} from 'primereact/tag';
import {Button} from 'primereact/button';
import {ConfirmDialog} from 'primereact/confirmdialog';
import {useNavigate} from "react-router-dom";
import {deleteModelVersion} from "../../api/DataModel/data-model";
import {showError, showSuccess} from "../../api/notification/ToastManager";
import {isAuthorized} from "../../api/authorization/authorization-service";
import {sortByDate} from "../../api/utils/sortArrays";
import TemplatePaginator from "../commun/TemplatePaginator/TemplatePaginator";
import LongTextColumn from "../commun/Table/LongTextColumn/LongTextColumn";
import {removeFromSessionStorage, saveToSessionStorage} from "../../api/State/manage-state-storage-service";

const ModelVersions = (props) => {

    const [visible, setVisible] = useState(false);
    const [elToBeDeleted, setElToBeDeleted] = useState({});
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const navigate = useNavigate();

    const [idDeleting, setIdDeleting] = useState([]);
    const isSelectable = (id) => !idDeleting.includes(id);
    const rowClassName = (data) => {
        return isSelectable(data.id) ? '' : 'p-disabled'
    };
    const accept = async () => {
        setIdDeleting([elToBeDeleted.id, ...idDeleting])
        await deleteModelVersion(elToBeDeleted.name).then(result => {
            if (result !== null) {
                props.setVersions(result);
                showSuccess("Success", elToBeDeleted.name + " is deleted successfully !")

            } else {
                showError("Error", "A problem was occurred when deleting model version.")
            }
            setElToBeDeleted({})
        });
    }

    const reject = () => {
        setElToBeDeleted({})
    }

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.modelStatus.code}
                    style={{width: '100%', 'backgroundColor': rowData.modelStatus.color}}></Tag>
    }


    const creatVersionBTN = () => {
        if (isAuthorized("AR015")) {
            return (
                <div className="right-0 md-0 text-red font-bold flex align-items-center">
                    <Button label="Create new version"
                            onClick={() => {
                                removeFromSessionStorage("version");
                                navigate('/pemmdb_versions/new');
                            }}
                            className={classes.acceptDelete}
                            icon="pi pi-plus"/>
                </div>
            )
        } else {
            return null;
        }

    }
    const header = (
        <div className="grid p-0 m-0">
            <div className="col">
                PEMMDB VERSIONS
            </div>
            {creatVersionBTN()}
        </div>
    );
    const deleteModelVersionBTN = (rowData) => {
        if (isAuthorized("AR014")) {
            return (
                <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                    <Button icon=" pi pi-trash"
                            tooltip={rowData.used ? `'${rowData.name}' is in use by one or more dataCollections and cannot be deleted` : "Delete"}
                            tooltipOptions={{position: 'top', showOnDisabled: true}}
                            className=" p-button-rounded-sm p-button-text"
                            disabled={rowData.used}
                            onClick={() => {
                                setVisible(true);
                                setElToBeDeleted(rowData);
                            }}
                            style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}/>
                </div>
            );
        } else {
            return null;
        }

    }

    const updateModelVersionBTN = (rowData) => {
        if (isAuthorized("AR015")) {
            return (
                <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                    <Button icon="pi pi-pencil"
                            tooltip=" Edit version"
                            tooltipOptions={{position: 'top', showOnDisabled: true}}
                            className=" p-button-rounded-sm p-button-text"
                            onClick={() => {
                                saveToSessionStorage("version",  {model: {versionName:rowData.name}})
                                navigate('/pemmdb_versions/' + normalizePathItem(rowData?.name), {state: {version: {model:{versionName:rowData.name}}}})
                            }}
                            style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                    />
                </div>
            );
        } else {
            return null;
        }

    }

    const readModelDocumentation = (rowData) => {
        return (
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <Button icon="pi pi-book"
                        tooltip=" Model Documentation"
                        tooltipOptions={{position: 'top', showOnDisabled: true}}
                        onClick={() => window.open("/doc/" + rowData.name,'_blank')}
                        className=" p-button-rounded-sm p-button-text"
                        style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                />
            </div>
        );

    }
    const actionsBodyTemplate = (rowData) => {
        return (
            idDeleting.includes(rowData.id) ?
                <div className="flex justify-content-between">
                    <span className="vertical-align-middle">Deleting</span>
                    <i className="pi pi-spin pi-spinner" style={{fontSize: '1.2rem',padding:'0.15rem'}}></i></div> :
            <Aux>
                <div className=" grid p-0 ">
                    {deleteModelVersionBTN(rowData)}
                    {updateModelVersionBTN(rowData)}
                    {readModelDocumentation(rowData)}

                </div>
            </Aux>
        )

    }



    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    const sortVersionsByCreationDate = (data) => {
        return sortByDate(data, "createdIn");
    }


    const descriptionBody = (row) => (<LongTextColumn row={row} fieldName={"description"} idField={"id"}/>)

    return (
        <Aux>
            <ConfirmDialog style={{zIndex: 999}}
                           visible={visible}
                           onHide={() => setVisible(false)}
                           message={"Are you sure you want to proceed deleting " + elToBeDeleted.name + " ?"}
                           header="Confirmation"
                           icon="pi pi-exclamation-triangle"
                           acceptClassName={classes.acceptDelete}
                           rejectClassName={classes.rejectDelete}
                           accept={accept}
                           reject={reject}/>

            <HeaderBreadCrumb items={["PEMMDB Versions"]}/>
            <div>
                <div className=" card">
                    <DataTable value={sortVersionsByCreationDate(props.allVersions)}
                               header={header}
                               size="small"
                               resizableColumns
                               stripedRows
                               showGridlines
                               paginator paginatorTemplate={TemplatePaginator}
                               first={first} rows={rows} onPage={onCustomPage}
                               paginatorClassName="justify-content-end"
                               className="mt-6"
                               rowClassName={rowClassName}
                    >
                        <Column sortable field="name" header=" Name" style={{width: '400px'}}></Column>
                        <Column sortable field="tag" header="Tag name" style={{width: '400px'}}></Column>
                        <Column
                            field="description"
                            style={{
                                maxWidth: '500px',
                                width: '500px'
                            }}
                            header="Description" body={descriptionBody}></Column>
                        <Column header=" Status" body={statusBodyTemplate} style={{width: '7%'}}></Column>
                        <Column header=" Actions" style={{width: '7%'}} body={actionsBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>


        </Aux>
    )
}

export default ModelVersions;
