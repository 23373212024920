import React, {useEffect, useState} from "react";
import {
    findDataCollectionById, getWriteAccessMarketNodesDetailsLight, saveDataCollectionInES
} from "../../../../api/dataCollection/dataCollection-service";
import {Button} from "primereact/button";
import classes from "./SetupQueries.module.css";
import keycloak from "../../../../keycloak";
import {isAuthorized} from "../../../../api/authorization/authorization-service";
import {getAllQueryFileNamesPerDataCollection} from "../../../../api/file/upload-service";
import {Dialog} from 'primereact/dialog';
import {useNavigate} from "react-router-dom";
import {normalizePathItem} from "../../../BreadCrumb/Breadcrumb";
import {removeFromSessionStorage} from "../../../../api/State/manage-state-storage-service";
import {isInProgressPersisted} from "../../../../api/dataCollection/dataCollection-status";
import {StatusCode, StatusContext} from "../../../../entities/StatusContext";
import {MultiSelect} from "primereact/multiselect";

const SetupQueriesButton = (props) => {
    const [visible, setVisible] = useState(false);
    const [btnSavedLoading, setBtnSavedLoading] = useState(false);
    const [noQuery, setNoQuery] = useState(true);
    const [missingQuery, setMissingQuery] = useState(false);
    const [publishedMarketNodes, setPublishedMarketNodes] = useState([]);
    const [selectedMN, setSelectedMN] = useState([]);
    const navigate = useNavigate();

    const checkSaveEsButtonVisibility = () => {
        return isAuthorized("AR01");
    }
    useEffect(() => {
        let marketNodeList = [];
        props.marketNodes.forEach(i => {
            if ((i.marketNodeStatus.code === StatusCode.PUBLISHED || i.marketNodeStatus.code === StatusCode.DATA_PERSISTED) && i.marketNodeStatus.context === StatusContext.MARKET_NODE_STEPS) {
                marketNodeList.push(i.marketNode);
            }
        })
        setPublishedMarketNodes(marketNodeList);
    }, []);

    function getAllSheetsInDataCollection(categories) {
        let sheets = [];
        categories.map((e) => e['sheets']).forEach((sheet) => {
            sheet.forEach((sh) => {
                sheets.push(sh)
            })
        })
        return sheets;
    }

    const persistData = () => {
        setBtnSavedLoading(true);
        getWriteAccessMarketNodesDetailsLight(props.dataCollectionId)
            .then(data => {
                let marketNodeList = [];
                data.marketNodeLightList.forEach(i => {
                    if ((i.marketNodeStatus.code === StatusCode.PUBLISHED || i.marketNodeStatus.code === StatusCode.DATA_PERSISTED) && i.marketNodeStatus.context === StatusContext.MARKET_NODE_STEPS) {
                        marketNodeList.push(i.marketNode);
                    }
                })
                setPublishedMarketNodes(marketNodeList);
            }).then(() => {
            findDataCollectionById(props.dataCollectionId).then(dcResult => {
                getAllQueryFileNamesPerDataCollection(props.dataCollectionId).then(res => {
                    setNoQuery(res.length === 0);
                    setVisible(true);
                    if (dcResult['categories'] === undefined || dcResult['categories'] === null || getAllSheetsInDataCollection(dcResult['categories']).length !== res.length) {
                        setMissingQuery(true);
                    }
                });
            })
        })
            .catch(_ => {
                setVisible(false);
            })
    }


    const getDashboards = (dcName, dcID, _) => {
        removeFromSessionStorage("dataCollection")
        navigate("/data_collections/" + normalizePathItem(dcName) + "/visualisation/dashboards", {
            state: {
                dataCollection: {
                    name: dcName, id: dcID
                }
            }
        })
    }
    const accept = () => {
        setVisible(false)
        setBtnSavedLoading(true);
        setSelectedMN([]);
        saveDataCollectionInES({
            dcInfo: {
                id: props.dataCollectionId,
                user: keycloak.idTokenParsed.name
            },
            marketNodesCodes: selectedMN.map(m => m.name)
        }).then(res => {
            setBtnSavedLoading(res.isTimeOut)
            if (res.isError) {
                global.toastRef.current.show({
                    severity: 'error',
                    summary: "Error",
                    detail: res.message,
                    life: 60000
                });
            } else {
                props.setReload(!props.reload);
            }
        }).catch(() => {
            setBtnSavedLoading(false);
        })
    }

    return (<div className={classes.divSaveBtn}>
        <Dialog header={"Persist configuration"}
                visible={visible}
                onHide={() => {
                    setVisible(false);
                    setSelectedMN([]);
                    setBtnSavedLoading(false);
                }}
                breakpoints={{'960px': '75vw'}}
                className={classes.dialogStyle}
                closable={true}
                closeOnEscape={false}
                modal={true}

        >
            {noQuery || missingQuery ?
                <div className="grid">
                    <div className="col-1 mt-3 ">
                        <span className="pi pi-exclamation-triangle" style={{fontSize: '1.5rem'}}></span>
                    </div>
                    <div className="col-8">
                        {noQuery ? <h5>There is no query. You should configure at least one query!</h5> : missingQuery ?
                            <h5>There is a Missing Query,are you sure you want to proceed persisting data in
                                database?</h5> : null}
                    </div>
                </div>
                : null}

            <div className="grid mt-6">
                <div className="col-4">
                    <Button label="Configure queries"
                            className={classes.linkBtn}
                            link
                            onClick={(e) => getDashboards(props.dataCollectionName, props.dataCollectionId, e)}
                    />

                </div>
                <div className="col-5">
                    <MultiSelect value={selectedMN}
                                 onChange={(e) => setSelectedMN(e.value)}
                                 options={publishedMarketNodes}
                                 tooltip="Select market node to be persisted"
                                 optionLabel="name"
                                 placeholder="Select market node to be persisted" maxSelectedLabels={3}
                                 className="w-full md:w-20rem"
                                 filter
                    />
                </div>

                <div className="col-3">
                    <Button className={classes.acceptDelete}
                            label="Persist data"
                            disabled={noQuery || selectedMN.length === 0}
                            onClick={() => accept()}
                    />
                </div>
            </div>
        </Dialog>
        <Button className={classes.saveBtn} label="Persist data"
                onClick={() => persistData()}
                loading={btnSavedLoading || isInProgressPersisted(props.dataCollectionStatus)}
                visible={checkSaveEsButtonVisibility()}
                disabled={publishedMarketNodes.length === 0}
                icon="pi pi-database"/>
    </div>)
}


export default SetupQueriesButton;
