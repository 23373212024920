import classes from "./LongTextColumn.module.css";
import React, {useLayoutEffect, useRef, useState} from "react";


const LongTextColumn = ({row, fieldName, idField}) => {
    const targetRef = useRef(null);
    const [properties, setProperties] = useState({
        whiteSpaceStyle: classes.nowrap,
        icon: "pi-chevron-down",
        title: "Show more"
    })
    const [visible, setVisible] = useState(false)

    useLayoutEffect(() => {
        if (targetRef.current) {
            setVisible(targetRef.current.textContent.length > 60)
        }
    }, [])

    const updateStyle = () => {
        if (properties.whiteSpaceStyle === classes.nowrap) {
            setProperties({
                whiteSpaceStyle: classes.normal,
                icon: "pi-chevron-up",
                title: "Show less"
            })
        } else {
            setProperties({
                whiteSpaceStyle: classes.nowrap,
                icon: "pi-chevron-down",
                title: "Show more"
            })
        }
    }
    return (
        <div style={{display: "flex"}}>
            <p className={`${classes.description} ${properties.whiteSpaceStyle}`} ref={targetRef}>{row[fieldName]}</p>
            {visible
                ? <i className={`pi ${properties.icon}`} id={`desc-${row[idField]}`} onClick={updateStyle}
                     title={properties.title}/>
                : null}
        </div>
    )
}
export default LongTextColumn;
