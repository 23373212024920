import keycloak from "../../keycloak";
import axios from "axios";
import {showError, showInfo} from "../notification/ToastManager";
axios.defaults.withCredentials = true
export async function saveDataModel(dataModel) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    return await axios.post(global.config.BACK_URL + "model", dataModel, header);
}

export async function validateModel(dataModel) {
    let header = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        return await axios.post(global.config.BACK_URL + "model/validate", dataModel, header);
    } catch (e) {
        showError("Error", "An error was occurred when validating Pemmdb version");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}


export async function getAllDataModelsWithStatusPublished() {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        let data = null;
        await axios.get(global.config.BACK_URL + "model/list/PUBLISHED", header).then(response => {
            if (response.status === 200 && response.data.status === 200) {
                data = response.data.content;
                if (data.length === 0) {
                    showInfo("Info", "there is no pemmdb version saved in database");
                }
            } else {
                showError("Error", "An error was occurred when retrieving list pemmdb version as status published");
            }
        })
        return data;
    } catch (e) {
        showError("Error", "An error was occurred when retrieving list published pemmdb version");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}

export async function getModelTags() {
    try {
        return await axios.get(
            global.config.BACK_URL + "git/tags",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token
                }
            }
        )
    } catch (e) {
        showError("Error", "An error was occurred when retrieving tags list");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}

export async function searchAllModels() {

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        let response = {}
        await axios.get(global.config.BACK_URL + "model/list", header)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        response.status = res.data.status;
                        response.content = res.data.content;
                    } else {
                        showError("Error", "An error was occurred when searching model versions")
                        response = {
                            status: res.data.status,
                            content: null
                        };
                    }
                } else {
                    response = {
                        status: res.data.status,
                        content: null
                    };
                }
            })
        return response;
    } catch (e) {
        showError("Error", "An error was occurred when searching model versions")
    }

}

export async function deleteModelVersion(versionName) {
    let response = null;

    await axios
        .delete(global.config.BACK_URL + "model/" + versionName, {
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': 'Bearer ' + keycloak.token
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    response = res.data.content;
                } else {
                    showError("Error", "A problem was occurred when deleting model version.")
                }
            }
        }).catch(e => {
            showError("Error", "A problem was occurred when deleting model version.");
        })
    return response;
}

export async function getModelVersion(versionName) {
    let response = null;

    await axios
        .get(global.config.BACK_URL + "model/getVersion/" + versionName, {
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': 'Bearer ' + keycloak.token
            }
        }).then(res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    response = res.data.content;
                } else {
                    showError("Error", "A problem was occurred when retreiving model version.")
                }
            }
        }).catch(e => {
            showError("Error", "A problem was occurred when retreiving model version.");
        })
    return response;
}