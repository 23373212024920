import keycloak from "../../keycloak";
import axios from "axios";
import {showError} from "../notification/ToastManager";
axios.defaults.withCredentials = true
export async function generateToken() {
    let response = {}
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }

    try {
        await axios.get(global.config.BACK_URL + "token/generate", header)
            .then(res => {
                response = res.data.content
            })
        return response
    } catch (e) {
        showError("Error", "An error was occurred when generating token");
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
}
export async function generateTokenForUser(preferredName) {
    let response = {}
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }

    try {
        await axios.post(global.config.BACK_URL + "token/generate",preferredName, header)
            .then(res => {
                response = res.data.content
            })
        return response
    } catch (e) {
        showError("Error", "An error was occurred when generating token");
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
}
export async function getAPIToken() {
    let response = {}
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }

    try {
        await axios.get(global.config.BACK_URL + "token/user", header)
            .then(res => {
                    response = res.data.content
            })
        return response
    } catch (e) {
        showError("Error", "An error was occurred when generating token");
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
}


export async function getAllAPIToken() {
    let response = {}
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }

    try {
        await axios.get(global.config.BACK_URL + "token/list", header)
            .then(res => {
                response = res.data
            })
        return response
    } catch (e) {
        showError("Error", "An error was occurred when generating token");
        response = {
            status: e.response.data.status,
            content: []
        }
        return response;
    }
}


export async function deactivateToken(tokenId) {
    let response = {}
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }

    try {
        await axios.get(global.config.BACK_URL + "token/deactivate/"+tokenId, header)
            .then(res => {
                response = res.data.status
            })
        return response
    } catch (e) {

        response =e.response.data.status

        return response;
    }
}