import React, {useEffect, useState} from "react";
import Auxiliary from "../../../../../hoc/Auxiliary";
import {InputText} from "primereact/inputtext";
import classes from "../DataCollectionDetails.module.css";
import {removeNonUtf8} from "../../../../../api/utils/Strings";
import {checkDCName, executeOnScroll} from "../../../../../api/utils/data-collection-form";
import {updateProperty} from "../../../../../api/utils/conditions-utils";


const DCName = ({
                    dataCollection,
                    existingDataCollections,
                    handleChange,
                    updateDCNameStatus,
                    collapsed,
                    scroll
                }) => {
    const [v, m] = checkDCName(dataCollection.name, existingDataCollections);
    const [isValid, setIsValid] = useState(updateProperty(collapsed, v, true));
    const [errorMessage, setErrorMessage] = useState(updateProperty(collapsed, m, ""));
    const [dcNameValue, setDcNameValue] = useState(dataCollection.name);

    useEffect(() => {
        setDcNameValue(dataCollection.name)
    }, [dataCollection])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isValid) {
                updateNameValidation(dcNameValue)
            }
        }, 5000);
        return () => clearTimeout(timer);
    }, [dcNameValue])

    useEffect(() => {
        updateDCNameStatus({
            isDataCollectionNameValid: isValid,
            dataCollectionNameErrorMessage: errorMessage,

        });
    }, [isValid, errorMessage]);
    const checkDataCollectionName = () => {
        updateNameValidation(dcNameValue)
    };

    const updateNameValidation = (dcName) => {
        const [status, message] = checkDCName(dcName, existingDataCollections)
        setIsValid(status);
        setErrorMessage(message)
    };

    useEffect(() => {
        executeOnScroll(scroll, checkDataCollectionName)
    }, [scroll])
    const updateStatus = (e) => {
        if (e.nativeEvent.data === null
            || e.nativeEvent?.data?.match(/^[-_a-zA-Z0-9]+$/) !== null) {
            const value = removeNonUtf8(e.target.value);
            updateNameValidation(value);
            setDcNameValue(value);
            handleChange({
                target: {
                    name: "name",
                    value: value
                }
            });
        }
    }

    return (
        <Auxiliary>
            <div className="col-4" onBlur={checkDataCollectionName}>

                <p><b>Name</b></p>
                <div className="field">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={dcNameValue}
                                           onChange={updateStatus}
                                           className={`${classes.inputStyle} ${isValid ? '' : "p-invalid block"}`}
                                />
                            </span>
                    {isValid ? null
                        : <small
                            className="p-error block">{errorMessage}</small>}
                </div>
            </div>
        </Auxiliary>
    )

}
export default DCName;
