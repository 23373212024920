import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import {useNavigate} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import React, {useEffect, useRef, useState} from "react";
import {findAllRulesPermission} from "./api/authorization/authorization-service";
import AppRoutes from "./DOM/AppRoute/AppRoutes";
import AuthRoutes from "./DOM/AuthRoute/AuthRoutes";
import ErrorServer from "./components/ErrorServer/ErrorServer";
import {removeFromSessionStorage, saveToSessionStorage} from "./api/State/manage-state-storage-service";
import {NOT_AUTHORIZED_PATH, OPFAB_PATH, RULES_KEY} from "./api/static-data/Configurations";

const App = () => {
    const {keycloak} = useKeycloak();
    global.toastRef = useRef();
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorStatus, setErrorStatus] = useState(0);

    useEffect(() => {
        async function fetchData() {
            removeFromSessionStorage(RULES_KEY);
            await findAllRulesPermission()
                .then(res => {
                    if (res.status === 200) {
                        saveToSessionStorage(RULES_KEY, res.content);
                        setIsError(false)
                    } else {
                        setIsError(true);
                        setErrorStatus(res.status)
                    }
                }).catch(_ => {
                    setIsError(true);
                })
        }

        if (keycloak.authenticated) {
            fetchData().then(() => {
                setIsAuthenticated(true)
            });
        }
    }, [keycloak.authenticated])

    if (isAuthenticated) {
        if (window.location.pathname === '/') {
            window.location.replace(OPFAB_PATH);
        }


        if (keycloak.realmAccess.roles.find(e => e === "PEMMDB_ACCESS") !== undefined && !isError) {
            return (<AppRoutes/>)
        } else {
            if (errorStatus === 403) {
                navigate(NOT_AUTHORIZED_PATH);
            } else {
                return <ErrorServer/>
            }
            return (<AuthRoutes/>)
        }
    }
}

export default App;
