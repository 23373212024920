import React, {useEffect} from "react";
import "../../../../QueryConfiguration.css"
import {MultiSelect} from "primereact/multiselect";


const SheetsComponent = (props) => {

    useEffect(() => {
    }, [])

    const groupedItemTemplate = (option) => {
        return (<div className="flex align-items-center">
            <div>{option.label}</div>
        </div>);
    };
    let key = props.query.queryId + props.p.name
    let groupedSheets = props.query.dataCollection.categories.map(c => {
        let sheets = c.sheets.map(s => JSON.stringify({
            "label": s.name, "value": s.name + "/" + c.name
        })).map(s => JSON.parse(s))
        return {
            "label": c.name, "code": c.name, "items": sheets
        }
    });

    return (
        <div className="col-2" key={props.query.queryId + props.p.name}>
            <h5> Sheets </h5>
            <MultiSelect value={props.sheets} options={groupedSheets} onChange={(e) => {
                props.setInProgress(!props.inProgress)
                props.setSheets(e.value)
            }}
                         optionLabel="label"
                         optionValue="value"
                         optionGroupLabel="label" optionGroupChildren="items" optionGroupTemplate={groupedItemTemplate}
                         placeholder="Sheets" display="chip" className="w-full md:w-13rem"/>
        </div>
    )
}

export default SheetsComponent;