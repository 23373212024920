import {InputTextarea} from "primereact/inputtextarea";
import Header from "../../../commun/Header/Header";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import classes from "./SparqlQuery.module.css";
import {classNames} from "primereact/utils";


const EditQueryDialog = ({currentQuery, isEditQueryVisible, closeDialog, changeDesc}) => {

    const close = () => {
        closeDialog()
    }

    return (
        <Dialog
            closable={false}
            closeOnEscape={false}
            modal={true}
            style={{width: '60vw', height: '30vw'}}
            onHide={close}
            visible={isEditQueryVisible}
        >
            <div className="flex-row mt-0">
                <div className="grid">
                    <div className="col-6">
                        <Header title={"Edit <" + currentQuery.name + ">"}/>
                    </div>
                    <div className={`col-6 ${classes.buttons}`} id={"dialog-header"}>
                        <Button key="save-btn" label="Save"
                                className={classes.actionBtn}
                                type="button"
                                onClick={() => {
                                    close()
                                }}
                        />
                        <Button id={"close-btn"}
                                key="close-btn"
                                type="button"
                                className={classNames(classes.actionBtn, classes.marginLeft)}
                                label="Close"
                                onClick={() => {
                                    close()
                                }
                                }
                        ></Button>
                    </div>

                </div>
                <InputTextarea
                    className={classes.inputStyle}
                    rows={5}
                    cols={30}
                    autoResize
                    defaultValue={`${currentQuery.content}`}
                    onChange={changeDesc}
                />
            </div>

        </Dialog>
    )
}
export default EditQueryDialog;
