export const isClosed = (status) => {
    return isPermanentlyClosed(status) || isTemporaryClosed(status)
}

export const isPermanentlyClosed = (status) => {
    return status?.code === "PERMANENTLY_CLOSED"
}

export const isDraft = (status) => {
    return status?.code === "DRAFT"
}

export const isTemporaryClosed = (status) => {
    return status?.code === "TEMPORARILY_CLOSED"
}
export const isInProgressPersisted = (status) => {
    return status?.code === "PERSIST_IN_PROGRESS"
}


export const isInManualCheckStep = (status) => {
    return status?.code === "MANUEL_CHECK_IN_PROGRESS"
}

export const isInDataPersistedStep = (status) => {
    return status?.code === "DATA_PERSISTED"
}
export const isInManualCheckCompleted = (status) => {
    return status?.code === "MANUAL_CHECK_COMPLETED"
}
