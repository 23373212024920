import React, {useEffect, useRef, useState} from "react";
import Aux from "../../../../../../hoc/Auxiliary"
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {ConfirmPopup} from "primereact/confirmpopup";
import classes from "../../DataVisualisation.module.css";
import {Divider} from "primereact/divider";
import NewQuery from "./NewQuery/NewQuery";
import {
    deleteOverviewQueries,
    retrieveOverviewQueries
} from "../../../../../../api/dataCollection/sparql-queries-service";
import OverviewQueries from "./OverviewQueries/OverviewQueries";


const QueriesOverviewConfigurations = (props) => {

    const [queries, setQueries] = useState([])
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [createQueryInProgress, setCreateQueryInProgress] = useState(false);
    const [checkedQueries, setCheckedQueries] = useState([]);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);
    const buttonEl = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        retrieveOverviewQueries(props.dataCollectionId).then(res => {
            if (res !== null && res.status === 200) {
                setQueries(res.content)
            }
        });
    }, [])

    let selectAll = () => {
        if (selectAllChecked) {
            setSelectAllChecked(false);
            setCheckedQueries([]);
        } else {
            setSelectAllChecked(true);
            let arr = [];
            queries.forEach((q) => {
                arr.push(q.id)
            })
            setCheckedQueries(arr)
        }
    }

    let unselectQuery = (queryId) => {
        let index = checkedQueries.findIndex(q => q === queryId);
        checkedQueries.splice(index, 1)
        setCheckedQueries([...checkedQueries])
    }

    let selectQuery = (queryId) => {
        setCheckedQueries([...checkedQueries, queryId])

    }
    let accept = () => {
        setCheckedQueries([])
        setDeleteInProgress(true)
        setConfirmPopupVisible(false)
        deleteOverviewQueries(checkedQueries, props.dataCollectionId)
            .then(r => {
                setQueries(r.content);
                setDeleteInProgress(false);
            })


    }

    let reject = () => {
        setConfirmPopupVisible(false)
    }

    function addNewOverviewQuery() {
        setCreateQueryInProgress(true);
    }

    return (
        <Aux>
            <div className="grid mr-2 ml-2">
                <div className={"col-6 p-0 m-0"}>
                    <div>
                        <Checkbox inputId="selectAll"
                                  checked={selectAllChecked}
                                  onChange={e => selectAll()}
                                  value="Select all"/>
                        {selectAllChecked ? <label htmlFor="selectAll" className="ml-2">Unselect all</label> :
                            <label htmlFor="selectAll" className="ml-2">Select all</label>}

                    </div>
                </div>
                <div className={"col-6 p-0 m-0"}>
                    <div className={"flex flex-row-reverse"}>
                        {deleteInProgress ?
                            <Button icon="pi pi-spin pi-spinner"
                                    disabled={true}
                                    data-pr-at="left+5 top"
                                    className=" p-button-rounded-sm p-button-text"
                                    style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                            />
                            : <Button icon="pi pi-trash"
                                      ref={buttonEl}
                                      visible={!deleteInProgress}
                                      tooltip="Delete queries for selected sheets"
                                      tooltipOptions={{position: 'top', showOnDisabled: true}}
                                      disabled={!(checkedQueries.length > 0)}
                                      onClick={() => setConfirmPopupVisible(true)}
                                      className=" p-button-rounded-sm p-button-text"
                                      style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                            />}

                        <Toast ref={toast}/>
                        <ConfirmPopup target={buttonEl.current} visible={confirmPopupVisible}
                                      message="Are you sure you want to delete selected queries ?"
                                      icon="pi pi-exclamation-triangle"
                                      dismissable={false}
                                      accept={accept}
                                      acceptClassName={classes.confirmBtn}
                                      reject={reject}
                                      rejectClassName={classes.rejectBtn}
                        />
                    </div>
                </div>
                <div className={"col-12 p-0 m-0 "}>
                    <Divider/>
                </div>
                <div className="col-12">
                    <OverviewQueries checkedQueries={checkedQueries}
                                     unselectQuery={(id) => unselectQuery(id)}
                                     selectQuery={(id) => selectQuery(id)}
                                     queries={queries}/>
                </div>
                <NewQuery createQueryInProgress={createQueryInProgress}
                          dataCollectionName={props.dataCollectionName}
                          dataCollectionId={props.dataCollectionId}
                          setQueries={setQueries}
                          queries={queries}
                          setCreateQueryInProgress={setCreateQueryInProgress}/>
                <div className={"col-12 p-0 m-0"}>
                    <div className={"flex flex-row-reverse"}>
                        <Button type="button"
                                className={classes.confirmBtn}
                                icon="pi pi-plus"
                                tooltipOptions={{position: 'left', showOnDisabled: true}}
                                tooltip={"Add new overview query"}
                                disabled={createQueryInProgress}
                                onClick={addNewOverviewQuery}
                        />
                    </div>

                </div>
            </div>
        </Aux>
    )
}

export default QueriesOverviewConfigurations;




