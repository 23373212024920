import keycloak from "../../keycloak";
import axios from "axios";
import {showError, showSuccess} from "../notification/ToastManager";
axios.defaults.withCredentials = true
const sendManualCheckResult = async (params) => {
    const header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    };
    let result;
    await axios.post(global.config.BACK_URL + "dataCollection/marketnode/manual_check_response", {
        "manualCheck": params.manualCheck,
        "user": keycloak.idTokenParsed.name,
        "response": params.response,
        "reason": params.reason,
        "dataCollectionId": params.dataCollectionId
    }, header)
        .then(res => {
            if (res.data.status === 200) {
                result = res;
                showSuccess("Success", `Manual check result sent`);
            } else {
                showError("Error", `A problem was occurred when sending manual check result.`);

            }
        }).catch(_ => {
            showError("Error", `A problem was occurred when sending manual check result.`);
        })

    return result;
}

export default sendManualCheckResult;
