import React from "react";
import {Button} from "primereact/button";

const ModelVersionColumn = ({rowData}) => {
    return (
        <div className="grid">

            <div className="col-10 pb-0 ">{rowData['modelVersionName']}</div>
            <div className="col-1 pb-0">
                <Button icon="pi pi-book"
                        tooltip=" Model Documentation"
                        tooltipOptions={{position: 'top', showOnDisabled: true}}
                        onClick={() => window.open("/doc/" + rowData['modelVersionName'],'_blank')}
                        className=" p-button-rounded-sm p-button-text"
                        style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                />
            </div>
        </div>
    )

}
export default ModelVersionColumn;
