import React, {useEffect, useState} from 'react';
import Category from "./Category/Category";
import {Fieldset} from 'primereact/fieldset';
import {Button} from "primereact/button";
import classes from "./Categories.module.css";
import Aux from "../../../../hoc/Auxiliary";
import {classNames} from "primereact/utils";
import {checkCategoryValidity} from "../../../../api/utils/data-collection-form";
import {updateProperty} from "../../../../api/utils/conditions-utils";

const Categories = (props) => {
    const [fieldCollapsed, setFieldCollapsed] = useState(false)
    const [isModelDisabled, setIsModelDisabled] = useState(false);
    const [addCategoryButtonDisabled, setAddCategoryButtonDisabled] = useState(false);
    useEffect(() => {
            if (props.modelSheet !== undefined && props.modelSheet.length > 0) {
                setIsModelDisabled(false);
            } else {
                setIsModelDisabled(true);
            }
        }, [props.modelSheet]
    );

    const verifyCategories = (categoriesList) => {
        const verification = categoriesList.map(category => {
            const v = checkCategoryValidity(category.name, categoriesList)[0]
            return updateProperty(v, category.sheets.length !== 0, v);
        })
        setAddCategoryButtonDisabled(verification.includes(false))
        props.setCategoriesValid(!verification.includes(false))
    }
    const addCategory = () => {
        const ncs = [...props.categoryList, {name: "", sheets: []}]
        setAddCategoryButtonDisabled(true);
        props.onChange("categories", ncs);

    }

    const handleChange = (e, v, i) => {
        let addedCategories = props.categoryList.map((obj, ind) => {
            if (ind === i) {
                if (e === "name")
                    obj = {name: v, sheets: obj.sheets};
                else if (e === "sheets") {
                    obj = {name: obj.name, sheets: v};
                }
            }
            return obj;
        })
        props.onChange("categories", addedCategories);
    }
    const onDelete = (i) => {
        props.categoryList.splice(i, 1);
        verifyCategories(props.categoryList);
        props.onChange("categories", props.categoryList);
    }


    useEffect(() => {
        verifyCategories(props.categoryList);
    }, [props.categoryList])


    return (<Aux>
        <div><br/>
            <Fieldset toggleable legend="Categories" onCollapse={() => setFieldCollapsed(true)}>
                {
                    props.categoryList.map((c, i) =>
                        <Category key={`cat-${i + 1}`} name={c.name} elementIndex={i}
                                  selectedSheets={c.sheets}
                                  modelSheet={props.modelSheet}
                                  dataModelDisabled={isModelDisabled}
                                  onChange={handleChange}
                                  onDelete={() => onDelete(i)}
                                  setAddCategoryButtonDisabled={setAddCategoryButtonDisabled}
                                  categories={props.categoryList}
                                  isEditMode={props.isEditMode}
                                  collapsed={fieldCollapsed}
                        />
                    )}
                <div className="grid">
                    <div className="col-1"/>
                    <div className="col-8">
                        <Button
                            type="button"
                            className={classNames(classes.actionBtn, classes.rightButton, classes.noMarginRight)}
                            label="Add Category"
                            onClick={addCategory}
                            disabled={addCategoryButtonDisabled}
                        />
                    </div>
                </div>
            </Fieldset>
        </div>
    </Aux>)

}
export default Categories;
