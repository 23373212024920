import React, {useEffect, useState} from "react";
import "../../../../QueryConfiguration.css"
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import ValuesChip from "../ValuesChip/ValuesChip";
import Auxiliary from "../../../../../../../../../hoc/Auxiliary";


const GMonthDayComponent = (props) => {
    const [data, setData] = useState([]);
    const [inputValue, setInputValue] = useState(null);
    const [localValue, setLocalValue] = useState(null);

    useEffect(() => {
        props.setValue(data, props.param)
    }, [data.length])

    const addNewParameter = () => {
        data.push(inputValue);
        setData(data);
        props.setValue(data, props.param)
        setInputValue(null)
        setLocalValue(null)
    }

    const setupGMonthDay = (e) => {
        let date = new Date(e.target.value);
        let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let val = day + "/" + month;
        setInputValue(val)
        setLocalValue(date)
        if (!props.param.properties.isMultiple) {
            props.setValue([val], props.param)
        }
    }

    const multipleValues = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <Calendar key={props.keyEl} placeholder="dd/mm" dateFormat="dd/mm"
                      value={localValue}
                      onChange={(e) => setupGMonthDay(e)}/>
            <Button type="button"
                    className="customButton"
                    icon="pi pi-plus"
                    onClick={addNewParameter}
                    disabled={inputValue === null}
            />
            <ValuesChip values={data} setValues={setData}/>
        </div>
    )
    const oneValue = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <Calendar key={props.keyEl} placeholder="dd/mm" dateFormat="dd/mm"
                      value={localValue}
                      onChange={(e) => {
                          setupGMonthDay(e);

                      }}/>

        </div>
    )
    const content = props.param.properties.isMultiple ? multipleValues : oneValue;

    return (
        <Auxiliary>
            {content}
        </Auxiliary>
    );
}

export default GMonthDayComponent;