import React, {useState} from "react";
import "../../../../QueryConfiguration.css"
import {Dropdown} from "primereact/dropdown";
import Auxiliary from "../../../../../../../../../hoc/Auxiliary";
import {MultiSelect} from "primereact/multiselect";


const BooleanComponent = (props) => {
    const [bl, setBl] = useState(null);

    const options = [
        {name: 'Yes', code: 'Yes'},
        {name: 'No', code: 'No'}

    ];
    const persistValue = (e) => {
        if (props.param.properties.isMultiple) {
            setBl(e)
        } else {
            setBl(e[0])
        }
        props.setValue(e.map(v => v.code), props.param);
    }


    const oneValue = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <Dropdown key={props.keyEl}
                      value={bl}
                      onChange={(e) => persistValue([e.value])}
                      options={options} optionLabel="name"
                      className="w-full md:w-13rem"/>

        </div>
    )
    const multipleValues = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <MultiSelect key={props.keyEl}
                         value={bl}
                         onChange={(e) => persistValue(e.value)}
                         options={options}
                         optionLabel="name"
                         maxSelectedLabels={2}
                         className="w-full md:w-13rem"/>
        </div>

    )

    const content = props.param.properties.isMultiple ? multipleValues : oneValue;

    return (
        <Auxiliary>
            {content}
        </Auxiliary>
    );

}

export default BooleanComponent;