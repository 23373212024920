import React from "react";
import {BreadCrumb} from "primereact/breadcrumb";

export const normalizePathItem = (item) => {
    try {
        return item?.replaceAll(" ", "_").toLowerCase();
    } catch (e) {
        return null;
    }
}


const HeaderBreadCrumb = (props) => {
    const setItems = (items) => {
        let itm = [];
        let base = `${process.env.PUBLIC_URL}`;
        items.map(e => {
            base = base.concat("/").concat(normalizePathItem(e));
            let newItem = {label: [e], url: [base]};
            if (!itm.includes(newItem)) {
                itm.push(newItem);
            }

        });
        return itm;

    }
    const home = {icon: 'pi pi-home'}
    return (
        <div>
            <BreadCrumb model={setItems(props.items)} home={home}/>
        </div>
    );
}
export default HeaderBreadCrumb;
