import React, {useEffect, useState} from "react";
import {MultiSelect} from "primereact/multiselect";
import {Chip} from "primereact/chip";
import classes from "./Cell.module.css";
import Aux from "../../../../../../hoc/Auxiliary";
import {sortAlphabetically} from "../../../../../../api/utils/sortArrays";

const Cell = (props) => {
    const getCurrentPermissionMarketNodes = (name) => {
        if (props.marketNodePermissions[props.currentGroupID] === undefined) {
            return [];
        }
        return props.marketNodePermissions[props.currentGroupID][name] === undefined
            ? []
            : props.marketNodePermissions[props.currentGroupID][name];
    }

    const [permissionMarketNode, setPermissionMarketNode] = useState(() => getCurrentPermissionMarketNodes(props.name));

    useEffect(() => {
        setPermissionMarketNode(getCurrentPermissionMarketNodes(props.name))
    }, [props.marketNodePermissions])

    const getCurrentPermissionMarketNode = () => {
        let e = {...props.marketNodePermissions};
        if (e[props.currentGroupID] === undefined) {
            e[props.currentGroupID] = {};
        }
        if (e[props.currentGroupID][permissionMarketNode] === undefined) {
            e[props.currentGroupID][permissionMarketNode] = [];
        }
        return e;
    }
    const onSelect = (event, permission) => {
        let curr = {...getCurrentPermissionMarketNode()};
        let res = [];
        if (curr[props.currentGroupID][permission] !== undefined && event.target.value.length < curr[props.currentGroupID][permission].length)
            curr[props.currentGroupID][permission] = event.target.value;
        else {
            event.target.value.forEach((v, _) => {
                res.push(v);
            })
            curr[props.currentGroupID][permission] = [...new Set(res)];
        }
        setPermissionMarketNode(event.target.value);
        props.setPermissionPerParticipant(curr);
    }


    const removeMarketNode = (key, permission) => {
        let e = {...props.marketNodePermissions};
        e[props.currentGroupID][permission] = props.marketNodePermissions[props.currentGroupID][permission].filter(e => e.id !== key);
        setPermissionMarketNode(e[props.currentGroupID][permission]);
        props.setPermissionPerParticipant(e);
    }

    return (
        <Aux>
            <MultiSelect
                showSelectAll={props.showSelectAll}
                appendTo="self"
                className={`${classes.marginHorizontal} ${classes.displayFlex}`}
                name={props.name} fixedPlaceholder={true}
                value={permissionMarketNode}
                options={sortAlphabetically(props.marketNodes, 'name')}
                onChange={(e) => onSelect(e, props.name)}
                optionLabel="name"
                placeholder="Select MarketNodes" filter/>
            <br/>

            <div className={`${classes.marginHorizontal}`}>
                {getCurrentPermissionMarketNodes(props.name).length > 0
                    ? getCurrentPermissionMarketNodes(props.name).map((mz, _) => {
                        return <Chip key={mz.id} label={mz.name} className={`mb-2 ${classes.marginHorizontalChip}`}
                                     removable
                                     onRemove={() => {
                                         removeMarketNode(mz.id, props.name)
                                     }}/>
                    })
                    : null}
            </div>
        </Aux>
    )
}


export default Cell;
