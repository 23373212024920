import React from "react";
import Backdrop from "../../components/Backdrop/Backdrop";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Aux from "../../hoc/Auxiliary";

const LoadingScreen = (props) => {
    return (
        <Aux>
            <Backdrop/>
            <LoadingSpinner/>
            {props.children}
        </Aux>
    )
}
export default LoadingScreen;
