import React from "react";
import classes from "./ActionsList.module.css"
import {Button} from "primereact/button";
import {isAuthorized} from "../../../api/authorization/authorization-service";
import {isClosed} from "../../../api/dataCollection/dataCollection-status";

const actions = (props) => {
    const publishAuthorizationRoleVisibilityBtn = isAuthorized("AR013")
        ? (<Button className={classes.actionBtn}
                   disabled={!props.isAllDataEmpty && ((!isAuthorized("AR08") &&isClosed(props.dataCollection.status)) || props.btnPublishVisibility || props.statusLV4)}
                   onClick={props.onPublishClick}
                   label={props.publishBtnLabel}
                   loading={props.publishBtnLabel===props.persistingLabel}
            />
        ) : null


    const validateAllAuthorizationRoleVisibilityBtn = isAuthorized("AR013")
        ? (
            <Button className={classes.actionBtn}
                    label="Validate all Data"
                    loading={props.statusLV4}
                    visible={!props.isAllDataEmpty}
                    disabled={(!isAuthorized("AR08") && isClosed(props.dataCollection.status)) || props.validationButtonDisabled || props.marketNodeDataIsPublishing}
                    onClick={props.onValidateAllDataClick}/>
        ) : null

    return (
        <div className="grid p-0 m-0">
            <div className="col-8"/>
            <div className="col-4 flex flex-row-reverse flex-wrap card-container ">
                {publishAuthorizationRoleVisibilityBtn}
                {validateAllAuthorizationRoleVisibilityBtn}
            </div>
        </div>
    )

}

export default actions;
