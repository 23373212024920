import {MAX_NAME_LENGTH, MIN_NAME_LENGTH} from "../static-data/Configurations";

export const checkDCName = (dcName, oldDataCollections) => {
    const newName = dcName.replaceAll(" ", "");
    if (newName.length < MIN_NAME_LENGTH) {
        return [false, "Data Collection name is empty"]
    }
    if (newName.length > MAX_NAME_LENGTH) {
        return [false, "Data Collection name length must not exceed '" + MAX_NAME_LENGTH + "' characters"]
    }
    if (oldDataCollections.some(e => e.replaceAll(" ", "").toLowerCase() === newName.toLowerCase())) {
        return [false, "DataCollection  with name '" + dcName + "' already exists"]
    }
    if (!dcName.match(/^[-_a-zA-Z0-9]+$/)) {
        return [false, "DataCollection name may only contain letters, numbers, underscores '_' or hyphens '-'"]
    }
    return [true, ""];
};

export const checkModelVersion = (version) => {
    return version !== '';
}

export const checkOpenFromDeadLineValidity = (openFrom, deadline) => {
    const op = Date.parse(openFrom);
    const dl = Date.parse(deadline);
    if (!isNaN(op) && !isNaN(dl)) {
        return checkIfOpenFromBeforeDeadLine(op, dl)
    } else {
        const [opv, opm] = checkDateFieldValidity(op, "Open from")
        const [dlv, dlm] = checkDateFieldValidity(dl, "Deadline");
        return [opv, opm, dlv, dlm];
    }
};
const checkIfOpenFromBeforeDeadLine = (openFrom, deadline) => {
    if (new Date(openFrom) >= new Date(deadline)) {
        return [false, "Open from Date should be before Deadline date", false, ""]
    } else {
        return [true, "", true, ""]
    }
}
const checkDateFieldValidity = (date, fieldName) => {
    if (isNaN(date)) {
        return [false, `${fieldName} should not be empty`]
    } else {
        return [true, ""]
    }
};

export const checkCategoryValidity = (name, categories) => {
    const newName = name.replaceAll(' ', '');
    if (newName.length < MIN_NAME_LENGTH) {
        return [false, "Category name is empty"]
    }
    if (name.length > MAX_NAME_LENGTH) {
        return [false, "Category name length must not exceed '" + MAX_NAME_LENGTH + "' characters"]
    }
    if (!name.match(/^[-_ a-zA-Z0-9]+$/)) {
        return [false, "Category name '" + name + "' is not valid (Only letters, numbers, spaces, - or _ are allowed)"]
    }
    if (categories.filter(e => e.name.replaceAll(" ", "").toLowerCase() === newName.toLowerCase()).length > 1) {
        return [false, "Category name '" + name + "' is already defined"]
    }
    return [true, ""];
}

export const checkCategorySheetValidity = (modelSheet, selectedSheets) => {
    if (modelSheet === undefined) {
        return [false, "No PEMMDB Version selected"]
    } else if (selectedSheets.length === 0) {
        return [false, "No sheet selected"]
    } else {
        return [true, ""]
    }
}

export const executeOnScroll = (scroll, validationCallBack) => {
    if (scroll !== -1) {
        validationCallBack()
    }
}

