import React, {useEffect, useState} from "react";
import ClassComponent from "./Components/ClassComponent";
import StringComponent from "./Components/StringComponent";
import IntegerComponent from "./Components/IntegerComponent";
import DecimalComponent from "./Components/DecimalComponent";
import DateComponent from "./Components/DateComponent";
import GMonthDayComponent from "./Components/GMonthDayComponent";
import TimeComponent from "./Components/TimeComponent";
import BooleanComponent from "./Components/BooleanComponent";

const ComponentByType = (props) => {
    const [parameter] = useState(props.param);
    const [literalType, setLiteralType] = useState("");


    useEffect(() => {
        let lT = checkLiteralType(parameter.properties.uri)
        setLiteralType(lT)
    }, [])


    const checkLiteralType = (literal) => {
        return literal.split('xsd:').pop().split('>')[0];
    }


    let getComponent = () => {
        if (parameter.properties.type === "PEMMDB Class") {
            return <ClassComponent param={parameter} setValue={props.setValue} keyEl={props.keyEl}/>
        } else if (parameter.properties.type === "Resource") {
            return <StringComponent param={parameter} setValue={props.setValue} keyEl={props.keyEl}/>;
        } else {
            if (literalType === 'integer') {
                return <IntegerComponent param={parameter} setValue={props.setValue} keyEl={props.keyEl}
                                         value={props.value}/>

            } else if (literalType === 'decimal') {
                return <DecimalComponent param={parameter} setValue={props.setValue} keyEl={props.keyEl}
                                         value={props.value}/>
            } else if (literalType === 'date') {
                return <DateComponent param={parameter} setValue={props.setValue} keyEl={props.keyEl}
                                      value={props.value}/>
            } else if (literalType === 'gMonthDay') {
                return <GMonthDayComponent param={parameter} setValue={props.setValue} keyEl={props.keyEl}
                                           value={props.value}/>
            } else if (literalType === 'time') {
                return <TimeComponent param={parameter} setValue={props.setValue}/>
            } else if (literalType === 'boolean') {
                return <BooleanComponent param={parameter} setValue={props.setValue} keyEl={props.keyEl}/>

            } else {
                return <StringComponent param={parameter} setValue={props.setValue} keyEl={props.keyEl}/>
            }
        }
    }


    return (getComponent())
}
export default ComponentByType;
