import React, {useEffect, useState} from 'react';
import Auxiliary from "../../../../hoc/Auxiliary";
import {InputTextarea} from 'primereact/inputtextarea';
import classes from "../Details/DataCollectionDetails.module.css"
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import {Fieldset} from 'primereact/fieldset';
import DCName from "./DCName/DCName";
import PEMMDBVersionField from "./PEMMDBVersionField/PEMMDBVersionField";
import OpenFromDeadLineFields from "./OpenFromDeadLine/OpenFromDeadLineFields";

const DataCollectionDetails = (props) => {
    const [fieldCollapsed, setFieldCollapsed] = useState(false)
    const [dcNameStatus, setDcNameStatus] = useState({
        isDataCollectionNameValid: false,
        dataCollectionNameErrorMessage: "",
    });
    const [openFromDeadLineStatus, setOpenFromDeadLineStatus] = useState({
        isOpenFromValid: true,
        openFromValidErrorMessage: "",
        isDeadlineValid: true,
        deadlineValidErrorMessage: "",
    });
    const [isPEMMDBVersionValid, setIsPEMMDBVersionValid] = useState(true);

    useEffect(() => {
        props.setGeneralSettings({
            ...dcNameStatus,
            ...openFromDeadLineStatus,
            isPEMMDBVersionValid: isPEMMDBVersionValid,
        })
    }, [dcNameStatus, isPEMMDBVersionValid, openFromDeadLineStatus])

    const handleChange = (event) => {
        props.onChange(event.target.name, event.target.value);
    }

    return (
        <Auxiliary>
            <Fieldset toggleable legend="General Settings" onCollapse={() => setFieldCollapsed(true)}>
                <div className="grid">
                    <div className="col"/>
                    <DCName dataCollection={props.dataCollection}
                            existingDataCollections={props.existingDataCollections}
                            handleChange={handleChange}
                            updateDCNameStatus={setDcNameStatus}
                            collapsed={fieldCollapsed}
                            scroll={props.scroll}
                    />
                    <PEMMDBVersionField
                        version={props.version}
                        setIsPEMMDBVersionValid={setIsPEMMDBVersionValid}
                        setVersionName={props.setVersionName}
                        pemmdbVersionList={props.pemmdbVersionList}
                        handleChange={handleChange}
                        onModelChange={props.onModelChange}
                        collapsed={fieldCollapsed}
                    />
                    <div className="col-3"/>
                    <div className="col-1"/>
                    <OpenFromDeadLineFields
                        dataCollection={props.dataCollection}
                        handleChange={handleChange}
                        updateOpenFromDeadLineFields={setOpenFromDeadLineStatus}
                        collapsed={fieldCollapsed}
                        scroll={props.scroll}
                    />
                    <div className="col-3"/>
                    <div className="col-1"/>
                    <div className="col-8">
                        <p><b>Description</b></p>
                        <InputTextarea name="description"
                                       rows={5}
                                       cols={30}
                                       autoResize
                                       className={classes.inputStyle}
                                       value={props.dataCollection.description}
                                       onChange={handleChange}
                        />
                    </div>
                </div>
            </Fieldset>
        </Auxiliary>);

}

export default DataCollectionDetails;
