import Auxiliary from "../../../../../../hoc/Auxiliary";
import {Calendar} from "primereact/calendar";
import {classNames} from "primereact/utils";
import classes from "../../DataCollectionDetails.module.css";
import React from "react";

const CalendarField = ({title, name, value, onChange, checkIsValid, isValid, errorMessage, minDate ,disabled=false}) => {
    return (
        <Auxiliary>
            <p><b>{title}</b></p>
            <div className="field" onBlur={checkIsValid} onMouseLeave={checkIsValid}>
                             <span className="p-float-label">
                        <Calendar name={name}
                                  placeholder="__/__/____"
                                  dateFormat="dd/mm/yy"
                                  className={classNames({'p-invalid': !isValid}, classes.calendar, classes.iconCalendar)}
                                  value={value}
                                  showIcon
                                  onChange={onChange}
                                  onHide={checkIsValid}
                                  minDate={minDate}
                                  disabled={disabled}
                        />
                                  </span>
                {isValid ? null
                    : <small
                        className="p-error block">{errorMessage}</small>}
            </div>
        </Auxiliary>
    )
}

export default CalendarField;
