import React, {useCallback, useEffect, useState} from "react";
import {Button} from "primereact/button";
import {canCheckIn} from "../../../../api/authorization/rules";

const CheckInButton = (rowData) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        setIsVisible(canCheckIn(rowData.dataCollection?.participants, rowData.marketNodeData?.marketNode));
        setIsDisabled(true)
    }, [rowData])

    const checkIn = useCallback(() => {
    }, [])


    if (isVisible) {
        return (
            <Button icon="pi pi-file-import"
                    tooltip="Check-in"
                    disabled={isDisabled}
                    tooltipOptions={{position: 'top', showOnDisabled: true}}
                    className=" p-button-rounded-sm p-button-text"
                    onClick={checkIn}
                    style={{height: '1.5rem', width: '2rem', color: '#487c84'}}/>
        )
    } else return null;
}

export default CheckInButton;
