import {Button} from "primereact/button";
import classes from "./TokenGenerator.module.css";
import {CopyToClipboard} from "react-copy-to-clipboard";
import React, {useEffect, useState} from "react";
import {Toast} from "primereact/toast";
import Aux from "../../hoc/Auxiliary";
import {generateToken, getAPIToken} from "../../api/tokenGenerator/tokenGenerator-service";
import {formatDate} from "../../api/utils/DateFormatter";

const TokenDetails = () => {

    const [currentAccessKey, setCurrentAccessKey] = useState()
    const [currentExpirationDate, setCurrentExpirationDate] = useState()
    const toast = React.createRef();
    const generate = () => {
        generateToken()
            .then(res => {
                setCurrentAccessKey(res.accessKey);
                setCurrentExpirationDate(formatDate(new Date(Date.parse(res.expirationDate))));
            })
    }
    useEffect(() => {
        getAPIToken().then(res => {
            if(res.status==="ACTIVE"){
                setCurrentAccessKey(res.accessKey);
                setCurrentExpirationDate(formatDate(new Date(Date.parse(res.expirationDate))));
            }else{
                generate()
            }
        })
    },[])



    const showToast = () => {
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Access key copied to clipboard',
            life: 3000,
        });
    }


    const handleCopy = () => {
        showToast();
    };

    return (
        <Aux>
            <div className="grid">
                <div className="col" style={{cursor: "not-allowed"}}>
                    <div className="grid">
                        <div className="col-12 p-0">
                            <p className="flex align-items-center justify-content-center">{currentAccessKey}</p>
                            <p className="flex align-items-center justify-content-center"
                               style={{fontSize: 13}}>Expire: {currentExpirationDate}</p>
                        </div>
                        <div className="col-12 p-0 flex align-items-center justify-content-center">
                            <div className="grid ">
                                <div className="col-6">
                                    <Button className={classes.btnStyle}
                                            label="Regenerate"
                                            icon="pi pi-undo"
                                            onClick={() => {
                                                generate()
                                            }}
                                    />
                                </div>
                                <div className="col-6">
                                    <CopyToClipboard text={currentAccessKey} onCopy={handleCopy}>
                                        <Button className={classes.btnStyle}
                                                label="copy"
                                                icon="pi pi-copy"
                                        />
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toast ref={toast}/>
        </Aux>
    )
}

export default TokenDetails;