import classes from "./GeneralSettings.module.css";
import Auxiliary from "../../../../hoc/Auxiliary";
import {InputText} from "primereact/inputtext";
import React, {useEffect, useRef, useState} from 'react';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {Button} from "primereact/button";
import ActionList from "./ActionList/ActionList";
import {FileUpload} from "primereact/fileupload";
import {showError} from "../../../../api/notification/ToastManager";
import {validateModel} from "../../../../api/DataModel/data-model";
import {useNavigate} from "react-router-dom";
import {removeNonUtf8} from "../../../../api/utils/Strings";
import {checkVersionNameValidity} from "../../../../api/utils/pemmdb-version-form";

const GeneralSettings = (props) => {
    const [id] = useState(props.id);
    const [name, setName] = useState(`${props.name}`);
    const [isNameChanged, setIsNameChanged] = useState(false);
    const [isValidName, setIsValidName] = useState(true);
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [description, setDescription] = useState(`${props.description}`);
    const [creationDate] = useState(props.creationDate);
    const [tag, setTag] = useState(props.tag);
    const [path] = useState(props.path);
    const [modelStatus] = useState(props.modelStatus);
    const [fileIsUploaded, setFileIsUploaded] = useState(false);
    const [templateFiles, setTemplateFiles] = useState(null);
    const [isLoad, setIsLoad] = useState(false);
    const [validationBtnState, setValidationBtnState] = useState(false);
    const [hiddenName, setHiddenName] = useState(false);
    const [hiddenTag, setHiddenTag] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [previousVersionName, setPreviousVersionName] = useState(name);
    const [previousTag, setPreviousTag] = useState(tag);
    const[tooltip,setTooltip]=useState("");
    const navigate = useNavigate();

    const fileUploadRef = useRef(null);


    useEffect(() => {
        checkNameValidity(props.modelsName, name,props.oldModel)
    }, [name])


    useEffect(() => {
        if ((props.templateName!== "Upload Template") && (tag !== undefined) && (name !== "")) {
            setValidationBtnState(false);
        } else {
            setValidationBtnState(true);
        }
    }, [props.oldModel,fileIsUploaded, name, tag])

    const getFileContext = (e) => {
        let ext = e.files[0].name.split(".").pop();
        if (ext.toLowerCase() !== "zip") {
            showError("The uploaded file must be zip")
            setFileIsUploaded(false);
            fileUploadRef.current.clear();
        } else if (e.files[0].size > 52428800) {
            showError("The uploaded file must not exceed 50 MB")
            setFileIsUploaded(false);
            fileUploadRef.current.clear();
        } else {

            setFileIsUploaded(true);
            const zipFile = e.files[0];
            setTemplateFiles(zipFile);
            props.setTemplateName(zipFile.name);
            fileUploadRef.current.clear();
        }
    }

    useEffect(() => {
        if (Object.keys(props.oldModel).length !== 0) {
            setPreviousVersionName(props.oldModel.model.name)

        }
    }, [props.oldModel, name])


    const validate = () => {
        setPreviousVersionName(name);
        setIsLoad(true);
        const formData = new FormData();
        formData.append('file', templateFiles);
        formData.append('params', JSON.stringify({
            'versionName': name,
            'previousVersionName': previousVersionName === "" ? name : previousVersionName,
            'fileName': props.templateName,
            'tag': tag
        }))
        validateModel(formData).then((res) => {
            setIsCompleted(true);
            setPreviousTag(res.data.content.tag);
            props.setDocumentation(res.data.content.documentation);
            let val = res.data.content.documentation.validationResult;
            if (val === false) {
                props.setIsValid(false);
                setTooltip("The PEMMDB version ".concat(name).concat(" is not valid"));
            } else {
                props.setIsValid(true);
                setTooltip("");
            }
            setIsLoad(false);
            let wind = window.open()
            wind.document.write(res.data.content.htmlContent);

        }).catch(() => {
            setIsLoad(false);
            setPreviousTag(tag);
            props.setIsValid(false);
            setIsCompleted(false);
            props.setDocumentation({
                "id": 0,
                "templatePath": "",
                "uploadedFile": props.templateName,
                "validationResult": false
            })
        })

    }

    const checkTemplateUploaded = () => {
        if (props.documentation.uploadedFile !== undefined && props.documentation.uploadedFile !== '' && props.templateName !== props.documentation.uploadedFile && previousTag === tag && isLoad === false) {
            return (<div style={{marginLeft: "40.5%"}}><p className={classes.text}> ⚠️ The last template used to
                validated {name} is {props.documentation.uploadedFile}. </p></div>)
        } else if (previousTag !== "" && previousTag !== undefined && previousTag !== tag && props.templateName === props.documentation.uploadedFile && isLoad === false) {
            return (<div style={{marginLeft: "40.5%"}}><p className={classes.text}> ⚠️ The last tag used to
                validated {name} is {previousTag} if you don't press on validate model. </p></div>)

        } else if (props.documentation.uploadedFile !== undefined && props.documentation.uploadedFile !== '' && props.templateName !== props.documentation.uploadedFile && previousTag !== "" && previousTag !== undefined && previousTag !== tag && isLoad === false) {
            return (<div style={{marginLeft: "40.5%"}}>
                <div><p className={classes.text}> ⚠️ The last template used to
                    validated {name} is {props.documentation.uploadedFile} </p></div>
                <div><p className={classes.text}> ⚠️ The last tag used to validated {name} is {previousTag} if you don't
                    press on validate model. </p></div>
            </div>);
        } else {
            return null;
        }

    }
    useEffect(() => {
        if (Object.keys(props.oldModel).length !== 0 && props.oldModel.model.modelStatus.name === "Published"
            && !props.oldModel.model.used) {
            setHiddenTag(true)
            setHiddenName(true);
        } else if (Object.keys(props.oldModel).length !== 0 && props.oldModel.model.used) {
            setHiddenTag(true);
            setHiddenName(true);
        } else {
            setHiddenTag(false);
            setHiddenName(false);
        }

    }, [props.oldModel])

    useEffect(() => {
        if (Object.keys(props.oldModel).length !== 0) {
            if (fileIsUploaded === true && props.oldModel.model.modelStatus.name === "Published" && isCompleted === false) {
                props.setIsValid(false);
            } else if (fileIsUploaded === true && props.oldModel.model.modelStatus.name === "Published" && isCompleted === true) {
                props.setIsValid(props.documentation.validationResult);

            } else if (fileIsUploaded === true && props.oldModel.model.modelStatus.name === "Draft" && isCompleted === false) {
                props.setIsDraft(true);
                props.setIsValid(props.documentation.validationResult);
            } else if (fileIsUploaded === true && props.oldModel.model.modelStatus.name === "Draft" && isCompleted === true) {
                checkTemplateUploaded()
                props.setIsDraft(true);
                props.setIsValid(props.documentation.validationResult);

            } else {
                props.setIsValid(props.isValid);
                props.setIsDraft(props.isDraft);
            }
        } else {
            checkTemplateUploaded()
        }

    }, [validate])


    const checkNameValidity = (existingVersions, modelName,oldModel) => {
        if (isNameChanged) {
            const [validity, message] = checkVersionNameValidity(modelName, existingVersions, props.models,oldModel)
            setIsValidName(validity);
            setNameErrorMessage(message);
        }
    }

    const abort = () => {
        navigate("/pemmdb_versions/")
    }
    return (
        <Auxiliary>
            <h4 className={classes.title}>General Settings</h4>
            <p><b>PEMMDB Version name</b></p>
            <InputText className={classes.dropDown}
                       value={name}
                       disabled={hiddenName}
                       onChange={(e) => {
                           if (e.nativeEvent.data === null
                               || e.nativeEvent?.data?.match(/^[-_a-zA-Z0-9]+$/) !== null) {
                               setIsNameChanged(true);
                               const value = removeNonUtf8(e.target.value);
                               setName(value)
                           }
                       }}
                       placeholder="name"/>
            {isValidName ? null
                : <small
                    className="p-error block">{nameErrorMessage}</small>}
            <p><b>Description</b></p>
            <InputTextarea rows={7} className={classes.textArea}
                           value={description}
                           onChange={(e) => setDescription(e.target.value)}/>


            <p><b>Model reference</b></p>

            <div className={classes.tool}>
                <Dropdown className={classes.dropDown}
                          value={tag}
                          options={props.models}
                          disabled={hiddenTag}
                          onChange={(e) => {
                              setTag(e.value);
                              setIsCompleted(false);
                          }}
                          placeholder="Model reference"/>

                <FileUpload mode="basic" name="demo[]"
                            ref={fileUploadRef}
                            className={classes.uploadBtn}
                            accept=".zip"
                            onSelect={(e) => getFileContext(e)}
                            chooseLabel={props.templateName}
                />

                <Button className={classes.actionBtn}
                        onClick={validate}
                        disabled={validationBtnState || !isValidName}
                        loading={isLoad}
                        label={props.buttonLabel}/>

            </div>
            {checkTemplateUploaded()}

            <ActionList id={id}
                        name={name}
                        description={description}
                        creationDate={creationDate}
                        model={tag}
                        previousModel={previousTag}
                        isCompleted={isCompleted}
                        path={path}
                        isValidName={isValidName}
                        modelStatus={modelStatus}
                        file={templateFiles}
                        templateName={props.templateName}
                        accessWrite={props.accessWrite}
                        isValid={props.isValid}
                        isLoading={isLoad}
                        isDraft={props.isDraft}
                        documentation={props.documentation}
                        setDocumentation={props.setDocumentation}
                        previousName={previousVersionName}
                        tooltipVal={tooltip}
                        abort={abort}/>
        </Auxiliary>);

}

export default GeneralSettings;
