import keycloak from "../../keycloak";
import axios from "axios";
import {showError, showSuccess} from "../notification/ToastManager";
import {saveAs} from 'file-saver';
axios.defaults.withCredentials = true
export async function getBackupByMarketNode(marketNodeId) {
    let response = {}
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        await axios.get(global.config.BACK_URL + "category/backup/" + marketNodeId, header)
            .then(res => {
                response = res.data.content
            })
        return response
    } catch (e) {
        showError("Error", "An error was occurred when retrieving list of backup");
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
}

export async function downloadBackupForMarketNode(body) {
    try {
        await axios.post(global.config.BACK_URL + "category/marketnode/export/backup", JSON.stringify(body),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token
                }, responseType: 'arraybuffer'
            }).then(response => {
            if (response.status === 200) {
                saveAs(new Blob([response.data],
                    {type: "excel"}), "backup_" + body.dcMarketNode.dataCollection.name + "_" +
                    body.dcMarketNode.marketNode.name + ".zip");
                showSuccess("Success", `backup is generated.`);
            } else {
                showError("Error", "An error occurs while downloading backup")
            }
        })
    } catch (e) {
        showError("Error", "An error occurs while downloading backup")
    }
}