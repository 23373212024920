export function showSuccess(summary, detail) {
    global.toastRef.current.show({severity: 'success', summary: summary, detail: detail});
}

export function showInfo(summary, detail) {
    global.toastRef.current.show({severity: 'info', summary: summary, detail: detail, life: 10000});
}

export function showWarn(summary, detail,life) {
    global.toastRef.current.show({severity: 'warn', summary: summary, detail: detail,life:life});
}

export function showError(summary, detail) {
    global.toastRef.current.show({severity: 'error', summary: summary, detail});
}

export function showTimedError(summary, detail, life) {
    global.toastRef.current.show({severity: 'error', summary: summary, detail: detail, life: life});
}
