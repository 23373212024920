import React from "react";
import Aux from "../../../../../hoc/Auxiliary";
import classes from "./CategoryOverview.module.css"

const CategoryOverview = (props) => {
    return (
        <Aux>
            <div className="col">
                  <span>
                      <i className="pi pi-circle-fill"
                         style={{'fontSize': '.7em', 'color': props.validationStatus.color}}></i>
                      <label className={classes.label}> Status :</label>
                  </span>
                <label> {props.validationStatus.name}</label>
            </div>
            <div className="col">
                  <span>
                      <label className={classes.label}> Last update :</label>
                  </span>
                <label> {props.lastUpdate}</label>
            </div>
            <div className="col">
                  <span>
                      <label className={classes.label}> Last update by :</label>
                  </span>
                <label> {props.lastUpdateBy}</label>
            </div>
        </Aux>
    )
}

export default CategoryOverview;
