import React from "react";

const InfoLabel = ({message, textStyle}) => {
    return (
        <label className="white-space-normal">
            <div className="grid pb-9 flex align-items-center justify-content-center">
                <div className="col-12 flex align-items-center justify-content-center">
                    <b className={textStyle}>{message}</b>
                </div>
            </div>
        </label>)
}

export default InfoLabel;
