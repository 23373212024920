import React from "react";
import Aux from "../../../../../../../hoc/Auxiliary"
import Query from "./Query";


const OverviewQueries = (props) => {

    return (
        <Aux>
            <div className={"grid"}>
                {
                    props.queries.map((q) => {
                        return (
                            <div className="col-6" key={q.id}>
                                <Query query={q}
                                       selectQuery={(id) => props.selectQuery(id)}
                                       checkedQueries={props.checkedQueries}
                                       unselectQuery={(id) => props.unselectQuery(id)}/>
                            </div>

                        )
                    })
                }
            </div>

        </Aux>
    )
}

export default OverviewQueries;




