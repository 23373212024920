import React, {useEffect, useState} from "react";
import Auxiliary from "../../../../../../../hoc/Auxiliary";
import {Button} from "primereact/button";
import "../../QueryConfiguration.css"
import keycloak from "../../../../../../../keycloak";
import {executeSparqlQueries} from "../../../../../../../api/dataCollection/sparql-queries-service";
import ExecutionStructure from "./ExecutionStructure/ExecutionStructure";
import {ScrollPanel} from 'primereact/scrollpanel';

const QueryExecution = (props) => {
    const [marketNodes, setMarketNodes] = useState(null);
    const [sheets, setSheets] = useState(null);
    const [canRun, setCanRun] = useState(false);
    const [inProgress, setInProgress] = useState(true);
    const [queryExecutionInProgress, setQueryExecutionInProgress] = useState(false);
    const [sparqlParameters] = useState(props.query.sparqlParameters);
    const [execution, setExecution] = useState({
        "queryId": props.query.queryId,
        "executionName": "",
        "executedBy": keycloak.tokenParsed.name,
        "sparqlParameters": []
    });
    const [executionName, setExecutionName] = useState("");

    useEffect(() => {
        if (execution.executionName && execution.executionName.length > 0
            && props.query.sparqlParameters.length === execution.sparqlParameters.length) {
            let exist = true;
            props.query.parameters.map(p => {
                if (p.name === "MarketNodes") {
                    if (!execution.marketNodes || execution.marketNodes.length === 0) {
                        exist = false;
                    }
                }
                if (p.name === "CategorySheets") {
                    if (!execution.sheets || execution.sheets.length === 0) {
                        exist = false;
                    }
                }

            })
            execution.sparqlParameters.forEach(p => {
                if (p.values.length === 0) {
                    exist = false
                }
            })
            setCanRun(exist);
        } else {
            setCanRun(false)
        }

    }, [inProgress])

    useEffect(() => {
            execution.executionName = executionName
            setInProgress(!inProgress)
        }, [executionName]
    )

    useEffect(() => {
        if (sheets !== null) {
            execution.sheets = sheets.map(e => {
                let el = e.split("/");
                return {"sheetName": el[0], "categoryName": el[1]}
            });
            setInProgress(!inProgress)
        }
    }, [sheets])

    useEffect(() => {
        if (marketNodes !== null) {
            execution.marketNodes = marketNodes.map(e => e.name);
            setInProgress(!inProgress)
        }
    }, [marketNodes])


    const setupParameter = (e, p) => {
        let index = execution.sparqlParameters.findIndex((element) => element.name === p.name)
        if (index < 0) {
            execution.sparqlParameters.push({"name": p.name, "values": e})
            setExecution(execution)
        } else {
            setExecution(execution.sparqlParameters.splice(index, 1));
            execution.sparqlParameters.push({"name": p.name, "values": e})
            setExecution(execution)
        }
        setInProgress(!inProgress)
    }


    const executeQuery = () => {
        setQueryExecutionInProgress(true);
        setTimeout(() => {
            props.updateQueries();
            setQueryExecutionInProgress(false);
            props.hideDialog();
        }, 2000);
        executeSparqlQueries(execution).then(_ => {
        });
    }
    return (
        <Auxiliary>
            <ScrollPanel style={{width: '100%', height: '470px'}}>
                <ExecutionStructure execution={execution} setExecution={setExecution}
                                    setupParameter={setupParameter}
                                    inProgress={inProgress} setInProgress={setInProgress}
                                    sheets={sheets} setSheets={setSheets}
                                    marketNodes={marketNodes} setMarketNodes={setMarketNodes}
                                    sparqlParameters={sparqlParameters}
                                    dcMarketNodes={props.dcMarketNodes}
                                    query={props.query}
                                    executionName={executionName} setExecutionName={setExecutionName}
                />
            </ScrollPanel>
            <br/>
            <div className="grid flex justify-content-end">
                <Button className="btn" label="Run query"
                        loading={queryExecutionInProgress}
                        icon="pi pi-play"
                        onClick={executeQuery}
                        disabled={!canRun}/>
            </div>
        </Auxiliary>
    )
}
export default QueryExecution;
