import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import Header from "../../../../../commun/Header/Header";
import classes from "../../SparqlQuery.module.css";
import dClass from "./ParamDialog.module.css"
import {InputTextarea} from "primereact/inputtextarea";
import {RadioButton} from "primereact/radiobutton";
import {InputText} from "primereact/inputtext";
import Aux from "../../../../../../hoc/Auxiliary";
import {Button} from "primereact/button";
import {Divider} from "primereact/divider";
import {categories, XSDTypes} from "../../../../../../api/utils/SparqlQueries";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";

const ParamDialog = ({parameter, isDialogVisible, closeDialog}) => {
    const [property, setProperty] = useState(parameter.id ? parameter.properties : {
        type: '',
        uri: '',
        description: '',
        isMultiple: false
    });
    const [selectedCategory, setSelectedCategory] = useState(parameter.id ? {
        name: parameter.properties.type,
        key: ''
    } : categories[1]);

    const initializeProperty = () => {
        if (property.type === '') {
            setProperty({
                ...property,
                type: categories[1].name,
                uri: '',
                isMultiple: false,
            })
        }
    }

    const updatePropertyType = (event) => {
        setProperty({
            ...property,
            type: event.value.name,
            uri: ''
        })
        setSelectedCategory(event.value)
    }

    const updatePropertyUri = (event) => {
        setProperty({
            ...property,
            type: selectedCategory.name,
            uri: event.target.value
        })
    }
    const updateMultipleValue = () => {
        setProperty({
            ...property,
            isMultiple: !property.isMultiple,
        })
    }

    const updatePropertyDescription = (event) => {
        setProperty({...property, description: event.target.value})
    }


    const close = () => {
        closeDialog(property)
    }

    return (
        <Dialog
            closable={false}
            closeOnEscape={false}
            modal={true}
            style={{
                width: '80vw',
                height: '62vh'
            }}
            onHide={close}
            visible={isDialogVisible}
            onShow={initializeProperty}
        >
            <div className="flex-row mt-0">
                <div className="grid">
                    <div className="col-6">
                        <Header title={"SPARQL Parameter <" + parameter.name + ">"}/>
                    </div>
                    <div className={`col-6 ${dClass.buttons}`} id={"dialog-header"}>
                        <Button label={"Save"} className={dClass.actionBtn} onClick={close}/>
                    </div>

                </div>
                <div className=" grid mt-2">
                    <div className="col-6">
                        <div className="flex flex-column gap-3">
                            {categories.map((category) => {
                                return (
                                    <div key={category.key} className="flex align-items-center">
                                        <RadioButton inputId={category.key} name="category" value={category}
                                                     onChange={updatePropertyType}
                                                     checked={selectedCategory.name === category.name}
                                        />
                                        <label htmlFor={category.key} className="ml-2 mr-2">{category.name}</label>
                                    </div>
                                );
                            })}
                            <div className="flex flex-column">
                                {
                                    selectedCategory.name === 'PEMMDB Class'
                                        ? (<Aux>
                                            <p><b>{selectedCategory.name} URI :</b></p>
                                            <InputText name={`inp-${selectedCategory.key}`}
                                                       className={classes.inputStyle}
                                                       value={property.uri}
                                                       placeholder={selectedCategory.name + " URI"}
                                                       onChange={updatePropertyUri}
                                            />
                                            <div className="mt-1">
                                                <small>{"⚠️ "}<b style={{color: "red"}}>Required</b> From PEMMDB Version
                                                    Model</small>
                                            </div>
                                        </Aux>)
                                        : null
                                }
                                {
                                    selectedCategory.name === 'Literal'
                                        ? (<Aux>
                                            <p><b>{selectedCategory.name} Type :</b></p>
                                            <Dropdown name={`inp-${selectedCategory.key}`}
                                                      className={classes.inputStyle}
                                                      value={property.uri}
                                                      options={XSDTypes}
                                                      placeholder={selectedCategory.name + " Type"}
                                                      onChange={updatePropertyUri}
                                            />
                                            <div className="mt-1">
                                                <small>{"⚠️ "}<b style={{color: "red"}}>Required</b> Literal type be defined</small>
                                            </div>
                                        </Aux>)
                                        : null
                                }
                            </div>
                        </div>

                    </div>
                    <div className="col-sm-1">
                        <Divider layout="vertical" className="hidden md:flex"/>
                    </div>
                    <div className="col-5 mt-0 pt-0">
                        <div className="flex gap-3">
                            <Checkbox inputId={'is-multiple'} name="category"
                                      onChange={updateMultipleValue}
                                      checked={property.isMultiple}
                            />
                            <label htmlFor={'is-multiple'}>Parameter could have multiple values (defined by <b
                                style={{color: "red"}}>IN</b> clause in the query).</label>
                        </div>
                        <p><b>Description</b></p>
                        <InputTextarea name="description"
                                       rows={5}
                                       cols={20}
                                       autoResize
                                       className={classes.inputStyle}
                                       placeholder={"Describe query parameter : Type, possible values ..."}
                                       value={`${property.description === null ? "" : property.description}`}
                                       onChange={updatePropertyDescription}
                        />
                        <span className="pi pi-info-circle"/>
                        <small> Please provide a description of the parameter (type, possible values ...) to help query
                            users</small>
                    </div>

                </div>
            </div>
        </Dialog>
    )
}
export default ParamDialog;
