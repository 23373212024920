import Aux from "../../hoc/Auxiliary";
import React from "react";
import TokenDetails from "../../components/TokenGenerator/TokenDetails";
import HeaderBreadCrumb from "../../components/BreadCrumb/Breadcrumb";
import {Card} from "primereact/card";

const TokenDetailsBuilder = () => {

    return (<Aux>
            <HeaderBreadCrumb items={["Access Keys"]}/>
            <div className="flex justify-content-center">
            <Card title="Access key details" className="md:w-35rem mt-5" >
                <TokenDetails/>
            </Card>
            </div>
        </Aux>
    )
}
export default TokenDetailsBuilder