import React, {useCallback, useEffect, useRef, useState} from "react";
import {SplitButton} from "primereact/splitbutton";
import {showError} from "../../../../api/notification/ToastManager";
import {exportMarketNodeDataAsZip} from "../../../../api/file/download-service";
import {getWriteAccessMarketNodesDetailsLight} from "../../../../api/dataCollection/dataCollection-service";

const ExportButton = ({dcName, dcId, isDisplayed, disabled = false, messageOnDisable = ""}) => {
    const btn = useRef(null);
    const [isVisible, setIsVisible] = useState(isDisplayed);
    useEffect(() => {
        setIsVisible(isDisplayed);
    }, [isVisible])

    const exportMarketNodeAsZip = (dataType) => {
        let marketNodeList = [];
        getWriteAccessMarketNodesDetailsLight(dcId)
            .then(data => {
                data.marketNodeLightList.forEach(i => {
                    marketNodeList.push(i.marketNode);
                })
                exportData(marketNodeList, dcName, dcId, dataType)
            })
    }

    const exportData = (marketNodes,dcName, dcId, dataType) => {
        exportMarketNodeDataAsZip({
            dataCollection: {name: dcName, id: dcId},
            marketNodes: marketNodes
        }, dataType)
            .catch(_ => {
                showError("Export failed", "An error occurs when exporting " + dataType + " data, Please try again later.")
            });


    }

    const items = [
        {
            label: 'Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                exportMarketNodeAsZip("excel");
            }
        },
        {
            label: 'RDF',
            icon: 'pi pi-file-pdf',
            command: () => {
                exportMarketNodeAsZip("rdf");
            }
        }
    ];

    const show = useCallback(() => {
        btn.current.show();
    }, [])

    const buttonTemplate = useCallback(() => {
        return <i className={"pi pi-file-export"}
                  style={{color: '#487c84'}}/>
    }, [])

    if (isVisible) {
        return (
            <SplitButton
                ref={btn}
                tooltip={`${disabled ? messageOnDisable : ""}`}
                tooltipOptions={{position: 'top', showOnDisabled: true}}
                className="p-button-rounded-sm p-button-text align-items-center"
                buttonTemplate={buttonTemplate}
                style={{height: '1.6rem', width: '3.2rem'}}
                model={items}
                onClick={show}
                menuButtonClassName={"hidden"}
                disabled={disabled}
            />
        )
    } else return null;

}

export default ExportButton;
