import React, {useEffect, useState} from "react";
import Aux from "../../../../hoc/Auxiliary";

const ManualCheckTooltip = ({rowData, status}) => {
    const [content, setContent] = useState("");
    useEffect(() => {
        const mcReason = rowData?.manualCheck.reason === null
        || rowData?.manualCheck.reason === undefined
        || rowData?.manualCheck.reason === ""
            ? "-"
            : rowData?.manualCheck.reason;
        if (status?.code === "NOT_YET_EXECUTED" || status?.code === "WAITING_FOR_MC") {
            setContent(status?.name)
        } else {
            setContent(`Reason : ${mcReason} `)
        }
    }, [status, rowData])

    return (<Aux>
        <div className="grid">
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <i className="pi pi-circle-fill"
                   data-pr-mousetrack={true}
                   id={`mc-dc-icon-${rowData.id}`}
                   style={{height: '0.5rem', width: '0.5rem', color: status?.color}}
                />
            </div>
        </div>
    </Aux>)
}
export default ManualCheckTooltip;
