import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import classes from "../../DataCollection/DataCollections/DataCollectionList.module.css";
import DCListHeader from "../../DataCollection/DataCollections/Header/DCListHeader";
import TemplatePaginator from "../../commun/TemplatePaginator/TemplatePaginator";
import LongTextColumn from "../../commun/Table/LongTextColumn/LongTextColumn";
import ActionTemplate from "./ActionTemplate/ActionTemplate";
import StatusBodyTemplate from "../../commun/Table/StatusColumn/StatusBodyTemplate";
import DCStatusFilter from "../../commun/DCStatusFilter/DCStatusFilter";
import {getUniqueObjectsByProperty} from "../../../api/utils/UniqueObjectsByProperty";
import {statusFilterProperties} from "../../../api/utils/filterProperties";

const DataCollectionList = ({nonDraftDataCollections}) => {
    const [dataCollections, setDataCollections] = useState([...nonDraftDataCollections])
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [statusList, setStatusList] = useState([])
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    useEffect(() => {
        let st = nonDraftDataCollections.map((dc) => dc.dataStatus);
        setStatusList(getUniqueObjectsByProperty(st, "name"));
    }, [nonDraftDataCollections])

    const descriptionBody = (row) => (<LongTextColumn row={row} fieldName={"description"} idField={"id"}/>)

    const actionsBodyTemplate = (row) => (<ActionTemplate row={row}/>)

    const statusFilterTemplate = () => {
        return <DCStatusFilter dataCollectionsList={nonDraftDataCollections} filterDataCollections={setDataCollections}
                               statusList={statusList}/>
    }

    return (<div>
        <div className=" card">
            <DataTable value={dataCollections}
                       header={DCListHeader}
                       size="small"
                       resizableColumns
                       filters={statusFilterProperties}
                       stripedRows
                       showGridlines
                       paginator paginatorTemplate={TemplatePaginator}
                       first={first} rows={rows} onPage={onCustomPage}
                       paginatorClassName="justify-content-end" className="mt-6"
            >
                <Column className={classes.bigCell} field="name" sortable header="Name of data collection"
                        style={{minWidth: "8rem", maxWidth: "10rem", whiteSpace: 'normal', wordWrap: 'break-word'}}
                        filter/>
                <Column className={classes.cellWidth} field="dataStatus.name" sortable header="Status"
                        filterMenuStyle={{width: '14rem', height: '6rem'}}
                        style={{minWidth: '10rem', width: '10rem'}}
                        showFilterMatchModes={false}
                        body={StatusBodyTemplate}
                        filter
                        showApplyButton={false}
                        showClearButton={false}
                        filterElement={statusFilterTemplate}/>
                <Column field="startFrom" header="Start date" style={{width: "6rem"}}/>
                <Column field="deadline" header="Deadline" style={{width: "6rem"}}/>
                <Column field="description" body={descriptionBody}
                        style={{width: "500px"}}
                        header="Description"/>
                <Column field="modelVersionName"
                        header="PEMMDB Version"
                        style={{minWidth: "8rem", maxWidth: "10rem", whiteSpace: 'normal', wordWrap: 'break-word'}}/>
                <Column className={classes.actionCell} header="Actions" body={actionsBodyTemplate}
                        style={{minWidth: "5rem", width: '5rem'}}/>
            </DataTable>
        </div>
    </div>)
}
export default DataCollectionList;
