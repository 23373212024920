import {MAX_NAME_LENGTH, MIN_NAME_LENGTH} from "../static-data/Configurations";

export const checkVersionNameValidity = (modelName, existingVersions, existingTags,oldModel) => {
    const name = modelName.replaceAll(' ', '');
    if (name.length < MIN_NAME_LENGTH) {
        return [false, "PEMMDB version name is empty."]
    }
    if (name.length > MAX_NAME_LENGTH) {
        return [false, "PEMMDB version name length must not exceed '" + MAX_NAME_LENGTH + "' characters."]
    }
    if (modelName.match(/^[a-zA-Z0-9_-]+$/) === null) {
        return [false, "PEMMDB version name " + modelName + " is not valid (Letters, numbers, - or _ are allowed)."]
    }
    if (existingTags.includes(modelName)) {
        return [false, "PEMMDB version name " + modelName + " is already used as a model reference name."]
    }
    if (existingVersions.includes(modelName)) {
        if(oldModel!=={} && oldModel.model!==undefined && oldModel.model.name===modelName) return [true,""]
        return [false, "PEMMDB version name " + modelName + " already exist."]
    }
    return [true, ""];
}
