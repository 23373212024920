import React from "react";
import {Skeleton} from "primereact/skeleton";
import {Accordion, AccordionTab} from 'primereact/accordion';
import Aux from "../../../../../hoc/Auxiliary"
import './ErrorStructure.css';
import {Button} from "primereact/button";
import {showError, showSuccess} from "../../../../../api/notification/ToastManager";
import {solveError} from "../../../../../api/dataCollection/dataCollection-service";


const ErrorStructure = (props) => {

    const skeleton = () => {
        return (
            <div>
                <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
                <Skeleton width="10rem" className="mb-2" borderRadius="16px"></Skeleton>
                <Skeleton width="5rem" borderRadius="16px" className="mb-2"></Skeleton>
                <Skeleton height="2rem" className="mb-2" borderRadius="16px"></Skeleton>
                <Skeleton width="10rem" height="4rem" borderRadius="16px"></Skeleton>
            </div>
        )
    }

    const deleteError = async (ids) => {
        props.close();
        await solveError({dataCollectionId: props.dataCollectionId, errorsId: ids})
            .then(res => {
                if (res.status === 200) {
                    if (res.content === 0) {
                        props.setIsBtnErrorListVisible(false);
                    }
                    showSuccess("Success", "Data collection errors are solved.");
                } else {
                    showError("Error", "A problem was occurred when deleting errors");
                }
            })
    }
    const headerStructure = (r) => {
        return (
            <div className="grid errorClass">
                <div className="col-10 pb-0">{r.context.name}</div>
                <div className="col-1 pb-0">
                    <Button icon="pi pi-trash"
                            tooltipOptions={{position: 'top', showOnDisabled: true}}
                            tooltip="Mark this error as resolved"
                            onClick={() => deleteError(r.errors.map(i => i.id))}
                            className="p-button-rounded-sm p-button-text"
                            style={{
                                height: '0.5rem',
                                width: '1.5rem',
                                color: '#487c84'
                            }}
                    /></div>
            </div>
        )
    }
    const err = (er) => {
        return (
            <div className="grid errorClass">
                {er.map(m => <Aux key={m.id}>
                    <div className="col-10 pb-0">{m.message}</div>
                    <div className="col-1 pb-0">
                        <Button icon="pi pi-trash"
                                tooltipOptions={{position: 'top', showOnDisabled: true}}
                                tooltip="Mark this error as resolved"
                                onClick={() => deleteError([m.id])}
                                className="p-button-rounded-sm p-button-text"
                                style={{
                                    height: '0.5rem',
                                    width: '1.5rem',
                                    color: '#487c84'
                                }}
                        /></div>
                </Aux>)}
            </div>

        )
    }

    if (props.isLoading) {
        return skeleton()
    } else {
        return (
            <Accordion activeIndex={0}>
                {props.errors.map(e =>
                    <AccordionTab header={headerStructure(e)} key={e.context.code}>
                        {err(e.errors)}
                    </AccordionTab>)}

            </Accordion>
        )

    }
}

export default ErrorStructure;
