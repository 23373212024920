import axios from "axios";
import keycloak from "../../keycloak";
import {showError, showInfo, showSuccess} from "../notification/ToastManager";
axios.defaults.withCredentials = true
export const saveSparqlQueries = async (dataCollection, queries, deletedQueriesId) => {
    let data = {status: 500, content: []}
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/queries/persist",
            {
                dcId: dataCollection.id,
                queries: queries,
                deletedQueriesId: [...deletedQueriesId]
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                if (response.status === 200) {
                    data.status = response.status;
                    let cc = response.data.content.filter(e => e.status === false);
                    if (cc.length > 0) {
                        showInfo("Incomplete save", "Some Queries did not save properly, due to missing/incorrect parameters")
                        data.content = cc
                    } else if (response.status === 504) {
                        showInfo("Info", "Data persistence might take several minutes")
                    } else {
                        showSuccess("Queries were saved Successfully", "All Queries were saved successfully")
                        data.content = [];
                    }
                }
            })
    } catch (e) {
        if (e.response.status === 504) {
            showInfo("Info", "Data persistence might take several minutes")
        } else {
            showError("Error", "An error was occurred when saving queries");
        }
    }
    return data;
}
export const retrieveSparqlQueries = async (dcId) => {
    let data = {status: 500, content: []}
    try {
        await axios.get(global.config.BACK_URL + "dataCollection/queries/list/" + dcId,
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                if (response.status === 200) {
                    data.status = response.status;
                    data.content = response.data.content;
                }
            })
    } catch (e) {
        showError("Error", "An error was occurred when retrieving saved queries");
    }
    return data;
}

export const retrieveOverviewQueries = async (dcId) => {
    let data = {status: 500, content: []}
    try {
        await axios.get(global.config.BACK_URL + "dataCollection/queries/overview/list/" + dcId,
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                if (response.status === 200) {
                    data.status = response.status;
                    data.content = response.data.content;
                }
            })
    } catch (e) {
        showError("Error", "An error was occurred when retrieving saved overview queries");
    }
    return data;
}

export const deleteOverviewQueries = async (param, dcId) => {
    let data = {status: 500, content: []}
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/delete/overview/queries/" + dcId, param,
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                if (response.status === 200 && response.data.status === 200) {
                    showSuccess("Success", 'Overview queries are deleted successfully');
                    data.content = response.data.content;
                    data.status = response.status;
                }
            })
    } catch (e) {
        showError("Error", "An error was occurred when deleting overview queries");
    }
    return data;
}

export const executeSparqlQueries = async (param) => {
    let data = {status: 500, content: []}
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/queries/run", param,
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                if (response.status === 200) {
                    data.status = response.status;
                    data.content = response.data.content;
                    if (response.data.content.status.code !== "EXECUTION_IN_PROGRESS") {
                        showSuccess("Success", `${response.data.content.name} is executed.`);
                    }
                }
            })
    } catch (e) {
        if (e.response.status === 504) {
            showInfo("Info", "Executing queries might take several minutes  ")
        } else {
            showError("Error", "An error was occurred when executing query " + param.executionName);
        }
    }
    return data;
}
export const deleteQueryExecution = async (param) => {
    let data = {status: 500, content: []}
    try {
        await axios.delete(global.config.BACK_URL + "dataCollection/queries/delete/"+param,
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                if (response.status === 200) {
                    data.status = response.status;
                    data.content = response.data.content;
                        showSuccess("Success", "Query execution is deleted");

                }
            })
    } catch (e) {

            showError("Error", "An error was occurred when deleting query execution" );

    }
    return data;
}