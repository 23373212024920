import React, {useEffect, useRef, useState} from "react";
import {Checkbox} from "primereact/checkbox";
import classes from "../../../DataVisualisation.module.css";
import {Button} from "primereact/button";
import {showError} from "../../../../../../../api/notification/ToastManager";
import {getAllQueryFileNamesPerDataCollection, saveQuery} from "../../../../../../../api/file/upload-service";
import keycloak from "../../../../../../../keycloak";
import {FileUpload} from "primereact/fileupload";
import Aux from "../../../../../../../hoc/Auxiliary"
import {Tooltip} from "primereact/tooltip";

const SheetConfiguration = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const fileUploadRef = useRef(null);
    const [fileIsUploaded, setFileIsUploaded] = useState(false);
    const [fileName, setFileName] = useState();

    const chooseOptions = {
        icon: 'pi pi-upload'
    };


    let checkQueryNameBySheetAndCategory = (sheet, category) => {
        if (props.sheetDetails && props.sheetDetails.length > 0) {
            let sh = props.sheetDetails.find(s => {
                return s.sheetId === sheet.id && s.categorieId === category.id
            });
            if (sh !== null && sh !== undefined) {
                return sh.QueryFileName
            } else {
                return null;
            }
        }

    }

    useEffect(() => {
        let exist = props.checkedSheets.filter(s => s.sheet === props.sheet.id);
        if (exist.length > 0) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }, [props.checkedSheets])

    useEffect(() => {
        let name = checkQueryNameBySheetAndCategory(props.sheet, props.category)
        if (name !== null && name !== undefined) {
            setFileName(name)
        } else {
            setFileName("")
        }
    }, [props.sheetDetails])

    let setButtonUpload = () => {
        return (
            <Aux>
                <div className={"grid"}>
                    <div className={"col-2 mr-3"}>
                        <FileUpload
                            ref={fileUploadRef}
                            name="demo[]"
                            accept=".sparql"
                            maxFileSize={100000000000}
                            mode="basic"
                            className={classes.uploadBtn}
                            chooseOptions={chooseOptions}
                            chooseLabel={" "}
                            onSelect={(e) =>
                                handleFileInputChange(e, props.sheet, props.category)}
                        />
                    </div>
                    <div className={"col-8"}>
                        <Tooltip target=".custom-tooltip"/>
                        <p className={classes.fileName}
                           data-pr-tooltip="No notifications"
                           data-pr-position="bottom">
                                <span className="custom-tooltip"
                                      data-pr-tooltip={fileName}
                                      data-pr-position="bottom">{fileName}</span>
                        </p>
                    </div>
                </div>
            </Aux>
        );

    }

    let checkSheet = (e) => {
        setIsChecked(!isChecked)
        if (isChecked) {
            props.removeSheet(props.category, props.sheet)
        } else {
            props.selectSheet(props.category, props.sheet)

        }
    }

    const getBase64 = file => {
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    }
    const handleFileInputChange = (e, sheet, category) => {

        let file = e.files[0];
        let ext = file.name.split(".").pop();
        if (ext.toLowerCase() !== "sparql") {
            showError("Uploaded file must be SPARQL")
            setFileIsUploaded(false);
            fileUploadRef.current.clear();
        } else {
            setFileIsUploaded(true);
            setFileName(file['name']);
            getBase64(file).then((res) => {
                try {

                    saveQuery({
                            fileName: file["name"],
                            contentFile: res,
                            sheetQueryInfo: {
                                dataCollection: props.dataCollection,
                                queryLocation: "",
                                sheet: sheet,
                                category: category,
                                createdBy: keycloak.idTokenParsed.name,
                                createdIn: new Date(),
                            }
                        }
                    ).then(() => {
                        getAllQueryFileNamesPerDataCollection(props.dataCollection.id).then(res => {
                            if (res) {
                                let sh = res.find(s => s.sheetId === props.sheet.id && s.categorieId === props.category.id);
                                if (sh !== null && sh !== undefined) {
                                    setFileName(sh.QueryFileName)
                                }
                            }
                        });
                        setFileIsUploaded(false);
                    })
                } catch (err) {
                    setFileIsUploaded(false);
                    fileUploadRef.current.clear();
                }
            });
        }
    };
    return (
        <div className="grid max-w-full">
            <div className=" flex align-items-center ">
                <Checkbox onChange={e => checkSheet(e)} checked={isChecked}/>
            </div>
            <div className="col-4 mr-2 flex align-items-center ">
                <label>{props.sheet.name} : </label>
            </div>
            <div className="col-7 mt-0 ">
                {
                    fileIsUploaded ?
                        <Button
                            tooltip={fileName}
                            className={classes.uploadSpinnerBtn}
                            label={fileName} loading={true}/> :
                        setButtonUpload()
                }
            </div>
        </div>

    )
}

export default SheetConfiguration;