import React from "react";
import {isAuthorized} from "../../../../api/authorization/authorization-service";
import {Button} from "primereact/button";
import classes from "../DataCollectionList.module.css";
import {useNavigate} from "react-router-dom";
import {NEW_DATA_COLLECTION_PATH} from "../../../../api/static-data/Configurations";
import Aux from "../../../../hoc/Auxiliary"

const CreateDataCollectionButton = () => {
    const navigate = useNavigate();
    if (isAuthorized("AR08")) {
        return (
            <Aux>
                <Button label="Create new data collection"
                        onClick={() => {
                            navigate(NEW_DATA_COLLECTION_PATH)
                        }}
                        className={classes.newDC}
                        icon="pi pi-plus"/>
            </Aux>
        )
    } else {
        return null;
    }
}
export default CreateDataCollectionButton;
