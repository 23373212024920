import classes from "./ParticipantsSetting.module.css";
import Auxiliary from "../../../../hoc/Auxiliary";
import React, {useEffect, useState} from "react";
import {MultiSelect} from "primereact/multiselect";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import ManagePermissions from "../ManagePermissions/ManagePermissions";
import {Fieldset} from 'primereact/fieldset';
import {Chip} from 'primereact/chip';
import {showError} from "../../../../api/notification/ToastManager";
import {sortAlphabetically} from "../../../../api/utils/sortArrays";
import {findDataCollectionById} from "../../../../api/dataCollection/dataCollection-service";
import {getPermissionByParticipants} from "../../../../api/dataCollection/dataCollection-utils";

const ParticipantsSetting = (props) => {

    const [showManagePermissionPopup, setShowManagePermissionPopup] = useState(false);
    const [permissionFromOtherDC, setPermissionFromOtherDCChecked] = useState(false);
    const [selectedPreviousDc, setSelectedPreviousDc] = useState({});
    const [dcId, setDcId] = useState(null);


    const initializePermissionPerParticipants = (participants) => {
        let res = {};
        participants.map((par, _) => {
            let p = {};
            props.permissionsData.forEach((per) => {
                p[per.name] = [];
            })
            if (Object.keys(props.participantsPermission).includes(par.groupId)) {
                res[par.groupId] = props.participantsPermission[par.groupId];
            } else {
                res[par.groupId] = p;
            }
        })
        props.setParticipantsPermission(res);
    }
    const initializeParticipantsPermissions = () => {
        initializePermissionPerParticipants(props.selectedParticipants);
    }
    const handleDelete = (p) => {
        let s = props.selectedParticipants.filter(i => i.name !== p.name);
        props.setSelectedParticipants(s);
        initializePermissionPerParticipants(s);
    };

    useEffect(() => {
        saveDataCollectionPermissions();
    }, [props.participantsPermission])

    const saveDataCollectionPermissions = () => {

        let permissionsDataNames = props.permissionsData.map(p => p.name);
        let res = []
        if (props.version.marketNodes) {
            Object.keys(props.participantsPermission).forEach(id => {
                let participant = props.selectedParticipants.filter(sp => sp.groupId === id)[0];
                Object.keys(props.participantsPermission[id]).forEach(dp => {
                    if (permissionsDataNames.includes(dp)) {
                        let permissionName = props.permissionsData.filter(p => p.name === dp)[0]
                        let mz = props.participantsPermission[id][dp];
                        mz.map(m => {
                            let marketNode = props.version.marketNodes.filter(mn => mn.id === m.id).map(mn => {
                                return {id: mn.id, name: mn.name}
                            })[0];
                            res.push({participant, marketNode, permissionName})
                        })
                    }
                })
            })
        }
        props.onChange('participants', res);
    }

    const showManagePermissions = () => {
        if (props.version?.marketNodes?.length === undefined) {
            showError("unKnown PEMMDB Version", "Please select PEMMDB Version")
        } else if (props.selectedParticipants.length <= 0) {
            showError("No Participants selected", "Please select Participants")
        } else {
            initializeParticipantsPermissions();
            setTimeout(() => {
                setShowManagePermissionPopup(true);
            }, 100)
        }
    }

    const onSelect = (event) => {
        props.setSelectedParticipants(event.value);
    }

    useEffect(() => {

        if (dcId) {

            findDataCollectionById(dcId).then(res => {
                props.setParticipantsFromOtherDC(res.participants);
                res.participants.forEach((x, i) => {
                    if ((props.participants.filter(y => y.groupId === x.participant.groupId)).length === 0) {
                        res.participants.splice(i, 1);
                    }
                });
                props.setNewPermission(getPermissionByParticipants(res.participants));

            })
        }

    }, [dcId, props.marketNodes])
    const changeDcPermission = (e) => {

        const dc = (props.existingPublishedDCs.filter(x => x.name === e.value));
        setDcId(dc[0].id)
        setSelectedPreviousDc(e.value);
    }

    return (<Auxiliary>
        <br/>
        <Fieldset legend="Participants" toggleable>
            <div className="grid">
                <div className="col-1"/>
                <div className="col-8">
                    <p><b>Participants</b></p>

                    <MultiSelect name="participants" fixedPlaceholder={true} value={props.selectedParticipants}
                                 className={classes.selectStyle}
                                 options={sortAlphabetically(props.participants, 'name')} onChange={(e) => onSelect(e)}
                                 optionLabel="name"
                                 placeholder="Select Participants" filter/>
                    <Button type="button"
                            className={classes.actionBtn} label="Manage permissions"
                            onClick={showManagePermissions}
                    />
                    <ManagePermissions setDialogVisible={(e) => setShowManagePermissionPopup(e)}
                                       isDialogVisible={showManagePermissionPopup}
                                       version={props.version}
                                       participants={props.selectedParticipants}
                                       permissions={props.participantsPermission}
                                       setPermissionPerParticipant={(e) => {
                                           props.setParticipantsPermission(e)
                                       }}
                                       permissionsData={props.permissionsData}

                    />
                    <br/><br/>
                    {
                        props.selectedParticipants.map((participant) => {
                            let index = 1 + (Math.random() * 1000);
                            return <Chip key={index} label={participant.name} onRemove={() => handleDelete(participant)}
                                         className={"mb-2"} removable/>
                        })
                    }
                    <p style={{color: "red"}}>{props.errorMsg}</p>
                    <p><b>Message to the participants</b></p>
                    <InputTextarea name="mailMessage" rows={7} className={classes.textArea}
                                   defaultValue={props.mailMessage}
                                   onChange={e => {
                                       props.onChange(e.target.name, e.target.value)
                                   }}
                    />
                </div>
            </div>
        </Fieldset>
    </Auxiliary>);
}
export default ParticipantsSetting;
