import classes from "./Header.module.css";
import Auxiliary from "../../../hoc/Auxiliary";

const Header = (props) => {
    return (
        <Auxiliary>
            <h3 className={classes.title}>{props.title}</h3>
            <p>{props.description}</p>
        </Auxiliary>);

}

export default Header;
