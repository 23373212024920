import React from "react";
import {isAuthorized} from "../../api/authorization/authorization-service";
import {Toast} from "primereact/toast";
import {Route, Routes} from "react-router-dom";
import Aux from "../../hoc/Auxiliary";
import NotAuthorized from "../../components/NotAuthorized/NotAuthorized";
import DataCollectionBuilder from "../../containers/DataCollection/DataCollectionBuilder";
import DataCollectionEditor from "../../containers/DataCollection/DataCollectionEditor";
import ErrorServer from "../../components/ErrorServer/ErrorServer";
import DataCollectionQueriesBuilder
    from "../../containers/DataCollectionQueries/Configuration/DataCollectionQueriesBuilder";
import DataCollectionDetails from "../../containers/DataCollectionDetails/DataCollectionDetails";
import DataUploadBuilder from "../../containers/DataUpload/DataUploadBuilder";
import PemmdbVersionBuilder from "../../containers/PemmdbVersion/PemmdbVersionBuilder";
import VersionCreator from "../../components/PemmdbVersion/VersionCreator/VersionCreator";
import DataCollectionListBuilder from "../../containers/DataCollections/DataCollectionListBuilder";
import DataCollectionQueriesForStudyTeams
    from "../../containers/StudyTeams/DataCollectionQueries/DataCollectionQueriesForStudyTeams";
import ModelDocumentation from "../../components/PemmdbVersion/ModelDocumentation/ModelDocumentation";
import DataVisualisation from "../../components/DataCollection/DataCollections/DataVisualisation/DataVisualisation";
import StudyTeamsDataCollections from "../../containers/StudyTeams/PublishedDataCollections/StudyTeamsDataCollections";
import {
    ERROR_PATH,
    NEW_DATA_COLLECTION_PATH,
    NOT_AUTHORIZED_PATH,
    STUDY_TEAM_SPACE_PATH
} from "../../api/static-data/Configurations";
import TokenListBuilder from "../../containers/TokenList/TokenListBuilder";
import TokenDetailsBuilder from "../../containers/TokenList/TokenDetailsBuilder";


const AppRoutes = () => {
    return (
        <Aux>
            <Toast ref={global.toastRef}/>
            <Routes>
                <Route path={NOT_AUTHORIZED_PATH} exact element={<NotAuthorized/>}/>
                <Route path={ERROR_PATH} exact element={<ErrorServer/>}/>
                <Route path={NEW_DATA_COLLECTION_PATH} exact
                       element={isAuthorized("AR08") ? <DataCollectionBuilder/> :
                           <NotAuthorized/>}/>
                <Route path="/data_collections/edit/:dcName" exact
                       element={isAuthorized("AR08") ? <DataCollectionBuilder/> :
                           <NotAuthorized/>}/>
                <Route path="/data_collections/edit/published/:dcName" exact
                       element={isAuthorized("AR08") ? <DataCollectionEditor/> :
                           <NotAuthorized/>}/>
                <Route path="/data_collections/:dcName/queries" exact
                       element={isAuthorized("AR030") ? <DataCollectionQueriesBuilder/> :
                           <NotAuthorized/>}/>
                <Route path="/data_collections/:dcName" exact element={<DataCollectionDetails/>}/>
                <Route path="/data_collections/:dcName/:marketNode" exact
                       element={isAuthorized("AR010") ? <DataUploadBuilder/> : <NotAuthorized/>}/>
                <Route path="/data_collections/:dcName/visualisation/dashboards" exact element={<DataVisualisation/>}/>
                <Route path="/data_collections/Dashboards" exact element={<DataVisualisation/>}/>

                <Route path="/pemmdb_versions" exact
                       element={isAuthorized("AR016") ? <PemmdbVersionBuilder/> :
                           <NotAuthorized/>}/>
                <Route path="/pemmdb_versions/:version" exact
                       element={isAuthorized("AR015") ? <VersionCreator/> : <NotAuthorized/>}/>
                <Route path="/data_collections" element={<DataCollectionListBuilder/>}/>
                <Route path={STUDY_TEAM_SPACE_PATH}
                       element={isAuthorized("AR031") ? <StudyTeamsDataCollections/> :
                           <NotAuthorized/>}/>
                <Route path={STUDY_TEAM_SPACE_PATH + "/:dcName"}
                       element={isAuthorized("AR031") ? <DataCollectionQueriesForStudyTeams/> :
                           <NotAuthorized/>}/>
                <Route path={STUDY_TEAM_SPACE_PATH + "/:dcName/queries"} exact
                       element={isAuthorized("AR031") ? <DataCollectionQueriesForStudyTeams/> :
                           <NotAuthorized/>}/>
                <Route path="/doc/:version" exact element={<ModelDocumentation/>}/>
                <Route path="/access_key" exact
                       element={isAuthorized("AR036") ? <TokenListBuilder/> :
                           <TokenDetailsBuilder/>}/>
            </Routes>
        </Aux>
    )
}

export default AppRoutes;
