import React, {useState} from "react";
import Aux from "../../../../hoc/Auxiliary";
import {RadioButton} from "primereact/radiobutton";
import {isTemporaryClosed} from "../../../../api/dataCollection/dataCollection-status";

const CloseDCConfirmDialog = ({updateStatus, name, dataCollectionStatus}) => {
    const nextStatus = () => {
        return isTemporaryClosed(dataCollectionStatus) ? "Permanently" : "Temporarily"
    }
    const [closedStatus, setClosedStatus] = useState(nextStatus());
    const updateClosedStatus = (e) => {
        setClosedStatus(e.value);
        updateStatus(e.value);
    }
    const radios = nextStatus() === "Permanently" ? null : (
        <Aux>
            <div style={{display: "block"}} className="field-radiobutton">
                <RadioButton inputId="cStatusPermanently" name="cStatus" value="Permanently"
                             onChange={updateClosedStatus} checked={closedStatus === 'Permanently'}/>
                <label htmlFor="cStatusPermanently">Permanently</label>
            </div>
            <div style={{display: "block"}} className="field-radiobutton">
                <RadioButton inputId="cStatusTemporarily" name="cStatus" value="Temporarily"
                             onChange={updateClosedStatus} checked={closedStatus === 'Temporarily'}/>
                <label htmlFor="cStatusTemporarily">Temporarily</label>
            </div>
        </Aux>
    )
    return (
        <Aux>
            <h5>Are you sure you want to close data collection {name} {`${closedStatus.toLowerCase()}`} ?</h5>
            {radios}
        </Aux>
    )
}

export default CloseDCConfirmDialog;
