import React, {useEffect, useState} from "react";
import "../../../../QueryConfiguration.css"
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import ValuesChip from "../ValuesChip/ValuesChip";
import Auxiliary from "../../../../../../../../../hoc/Auxiliary";


const DateComponent = (props) => {
    const [data, setData] = useState([]);
    const [inputValue, setInputValue] = useState(null);

    useEffect(() => {
        props.setValue(data, props.param)
    }, [data.length])

    const transformDate = (v) => {
        let d = new Date(v);
        let month = d.getMonth() < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1);
        let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
        return month + "/" + day + "/" + d.getFullYear()
    }
    const addNewParameter = () => {
        let transformedDate = transformDate(inputValue);
        data.push(transformedDate);
        setData(data);
        props.setValue(data, props.param)
        setInputValue(null)
    }

    const multipleValues = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <Calendar key={props.keyEl} placeholder="mm/dd/yyyy"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}/>
            <Button type="button"
                    className="customButton"
                    icon="pi pi-plus"
                    onClick={addNewParameter}
                    disabled={inputValue === null}

            />
            <ValuesChip values={data} setValues={setData}/>
        </div>
    )

    const oneValue = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <Calendar key={props.keyEl} placeholder="mm/dd/yyyy"
                      value={inputValue}
                      onChange={(e) => props.setValue([transformDate(e.target.value)], props.param)}/>
        </div>
    )

    const content = props.param.properties.isMultiple ? multipleValues : oneValue;

    return (
        <Auxiliary>
            {content}
        </Auxiliary>
    );
}

export default DateComponent;