import React, {useEffect, useState} from "react";
import Aux from "../../hoc/Auxiliary";
import Header from "../../components/commun/Header/Header";
import AdminTool from "../../components/DataCollectionDetails/AdminTool/AdminTool"
import HeaderBreadCrumb, {normalizePathItem} from "../../components/BreadCrumb/Breadcrumb";
import {
    getDataCollectionDetails,
    getWriteAccessMarketNodesDetailsLight,
    sendMCInvitation
} from "../../api/dataCollection/dataCollection-service";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {loadState, removeFromSessionStorage} from "../../api/State/manage-state-storage-service";
import DetailsTable from "../../components/DataCollectionDetails/DetailsTable/DetailsTable";
import {StatusCode, StatusContext} from "../../entities/StatusContext";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {onServerCallError} from "../../api/utils/errors";
import {showError} from "../../api/notification/ToastManager";

const checkBtnMCVisibility = (data) => {
    let isHidden = false;
    if (data.dataCollection.dataStatus.code !== StatusContext.PUBLISHED_IN_ES) {
        isHidden = true;
    } else {
        data.marketNodeLightList.forEach(e => {
            if (e.manualCheck.status.code !== "NOT_YET_EXECUTED")
                isHidden = true;
        })
    }
    return isHidden;
}

const DataCollectionDetails = () => {
    const {state} = useLocation();
    const dataCollection = loadState(state, "dataCollection");
    const [dataCollectionDetails, setDataCollectionDetails] = useState();

    const urlParams = useParams();
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [sendMCBtnIsDisabled, setSendMCBtnIsDisabled] = useState(false);
    const [marketNodesDetails, setMarketNodesDetails] = useState([])
    const [persistButtonDisable, setPersistButtonDisable] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (urlParams?.dcName && urlParams.dcName.toLowerCase() !== normalizePathItem(dataCollection?.name)) {
            removeFromSessionStorage("dataCollection")
            onResponseError("Access to dataCollection details page from address bar is not authorized")
        }
    }, [])

    useEffect(() => {
        if (marketNodesDetails.length > 0) {
            let res = marketNodesDetails
                .filter(m =>
                    (m.marketNodeStatus.code === StatusCode.PUBLISHED || m.marketNodeStatus.code === StatusCode.DATA_PERSISTED)
                    &&
                    m.marketNodeStatus.context === StatusContext.MARKET_NODE_STEPS);
            setPersistButtonDisable(res.length === 0);
        }

    }, [marketNodesDetails])

    /**
     * format lastEdit and lastStatusChange date to dd/MM/yyyy
     * */
    const formatMarketNodeDateField = (data) => {

        data.map(e => {
            e.lastEdit = new Date(Date.parse(e.lastEdit)).toLocaleString([], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
            e.lastStatusChange = e.lastStatusChange ? new Date(e.lastStatusChange).toLocaleString([], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }) : e.lastEdit;
        })
        return data;
    }

    /**
     * Update Persist button status
     * <br/>
     * if data collection is permanently closed -> persist button is disable
     * <br/>
     * if market nodes are not published -> persist button is disable
     * */

    useEffect(() => {
        if (dataCollection) {
            try {
                getWriteAccessMarketNodesDetailsLight(dataCollection?.id)
                    .then(data => {

                        let marketNodeList = [];
                        data.marketNodeLightList.forEach(i => {
                            marketNodeList.push(i);
                        })

                        setDataCollectionDetails(data.dataCollection);
                        setSendMCBtnIsDisabled(checkBtnMCVisibility(data));
                        setMarketNodesDetails(formatMarketNodeDateField(marketNodeList));
                        setIsLoading(false);
                    })
                    .catch(_ => {
                        onResponseError("Could not retrieve market nodes access details.")
                    })
            } catch (e) {
                onResponseError(`An Error occur when loading Data Collection "${dataCollection?.name}" details`);
            }
        }
    }, [reload]);

    const onResponseError = (message) => {
        onServerCallError("Error loading page", message,
            () => {
                navigate("/data_collections");

            })
    }

    const sendInvitation = () => {
        if (dataCollection) {
            getDataCollectionDetails(dataCollection?.id).then(e => {
                    setDataCollectionDetails(e[0].dataCollection);
                    sendMCInvitation(dataCollection?.id)
                        .then(() => {
                            setReload(!reload);
                            setSendMCBtnIsDisabled(true)
                        })
                        .catch(() => {
                            showError("Failed to send invitation", "Could not send invitation for dataCollection '" + dataCollection?.name + "' participants.")
                            setSendMCBtnIsDisabled(false)
                        })
                }
            ).catch(_ => {
                showError("Failed to send invitation", "Could not retrieve details for dataCollection '" + dataCollection?.name + "'")
                setSendMCBtnIsDisabled(false)
            })
        }
    }
    return isLoading ? (<LoadingScreen/>)
        :
        (<Aux>
            <HeaderBreadCrumb items={["Data Collections", dataCollection?.name]}/>
            <Header title={dataCollection?.name} description={"Data collection details per market nodes"}/>
            <AdminTool dataCollection={dataCollectionDetails}
                       marketNodes={marketNodesDetails}
                       sendMCDisabled={sendMCBtnIsDisabled}
                       persistDisabled={persistButtonDisable}
                       setReload={setReload}
                       reload={reload}
                       onSendMCInvitation={sendInvitation}></AdminTool>
            <DetailsTable marketNodesDetails={marketNodesDetails} dataCollection={dataCollectionDetails}
            />
        </Aux>)
}

export default DataCollectionDetails;
