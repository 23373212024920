export function getUniqueObjectsByProperty(objectsList, propertyName) {
    let uniqueValues = new Set(objectsList.map(item => item[propertyName]));
    let uniqueObjectsList = [];
    uniqueValues.forEach(value => {
        let obj = objectsList.find(item => item[propertyName] === value);
        if (obj) {
            uniqueObjectsList.push(obj);
        }
    });
    return uniqueObjectsList;
}