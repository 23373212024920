import React from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {normalizePathItem} from "../../../BreadCrumb/Breadcrumb";

const DCButton = ({rowData, icon, tooltipText, navigationPath, disabled = false}) => {
    const navigate = useNavigate();

    return (<Button icon={icon}
                    tooltip={tooltipText}
                    tooltipOptions={{position: 'top', showOnDisabled: true}}
                    className=" p-button-rounded-sm p-button-text"
                    onClick={() => {
                        navigate(navigationPath ,
                            {
                                state:
                                    {
                                        dataCollection: {
                                            id: rowData?.id,
                                            name: rowData?.name,
                                            status: rowData?.dataCollectionStatus
                                        }
                                    }
                            })
                    }}
                    style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                    disabled={disabled}
    />)
}

export default DCButton;
