import React, {useEffect, useState} from "react";
import "../../../../QueryConfiguration.css"
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";
import ValuesChip from "../ValuesChip/ValuesChip";
import Auxiliary from "../../../../../../../../../hoc/Auxiliary";


const IntegerComponent = (props) => {
    const [data, setData] = useState([]);
    const [inputValue, setInputValue] = useState(null);

    useEffect(() => {
        props.setValue(data, props.param)
    }, [data.length])

    const addNewParameter = () => {
        data.push(inputValue);
        setData(data);
        props.setValue(data, props.param)
        setInputValue(null)
    }

    const multipleValues = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <InputNumber key={props.keyEl}
                         value={inputValue}
                         onChange={(e) => setInputValue(e.value)}
            />
            <Button type="button"
                    className="customButton"
                    icon="pi pi-plus"
                    disabled={inputValue === null}
                    onClick={addNewParameter}
            />
            <ValuesChip values={data} setValues={setData}/>
        </div>

    )
    const oneValue = (
        <div className="col-3" key={props.param.name}>
            <h5 key={`${props.param.name}title`}>
                {props.param.name} &nbsp;
                {props.param.properties.description !== null && props.param.properties.description.length > 0 ?
                    <span key={props.param.name} className="pi pi-info-circle" style={{fontSize: '0.7rem'}}
                          title={props.param.properties.description}/> : null}
            </h5>
            <InputNumber key={props.keyEl}
                         value={inputValue}
                         onChange={(e) => {
                             setInputValue(e.value)
                             props.setValue([e.value], props.param)
                         }}
            />
        </div>

    )
    const content = props.param.properties.isMultiple ? multipleValues : oneValue;

    return (
        <Auxiliary>
            {content}
        </Auxiliary>
    );
}

export default IntegerComponent;