import React, {useRef, useState} from "react";
import Auxiliary from "../../../../../hoc/Auxiliary";
import {Card} from 'primereact/card';
import {Panel} from 'primereact/panel';
import {Button} from "primereact/button";
import QueryExecutionsList from "./QueryExecutionsList/QueryExecutionsList";
import {Ripple} from 'primereact/ripple';
import {Toast} from "primereact/toast";
import QueryExecution from "./QueryExecutionsList/QueryExecution/QueryExecution";
import {Dialog} from "primereact/dialog";

const QueryConfiguration = (props) => {
    const [newExecution, setNewExecution] = useState(false);
    const toast = useRef(null);

    const addNewExecution = () => {
        setNewExecution(true);
    }
    const template = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple/>
                </button>
                <span className={titleClassName}>
                    Executions
                </span>
            </div>
        )
    }


    return (
        <Auxiliary>
            <Card title={props.query.name} key={props.query.queryId} className="titleQuery">
                <p className="m-0">{props.query.description}</p>
                <br/>
                <Panel headerTemplate={template}
                       toggleable
                       collapsed={true}>
                    <QueryExecutionsList
                        query={props.query}/>
                    <br/>
                    <div className="grid flex justify-content-end">
                        <Button
                            label="Add Execution"
                            className="btn AddExecution"
                            icon="pi pi-plus"
                            onClick={addNewExecution}
                            disabled={newExecution}
                        />
                        <Toast ref={toast}/>
                        <Dialog visible={newExecution}
                                onHide={() => setNewExecution(false)}
                                header="New Execution"
                                draggable={false}
                                style={{width: '90vw'}}
                        >
                            <QueryExecution dcMarketNodes={props.dcMarketNodes}
                                            query={props.query}
                                            updateQueries={props.updateQueries}
                                            hideDialog={() => setNewExecution(false)}

                            />
                        </Dialog>
                    </div>
                </Panel>
            </Card>
        </Auxiliary>
    )
}
export default QueryConfiguration;
