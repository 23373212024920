import React from "react";
import "../../../../QueryConfiguration.css"
import "./ValuesChip.css";
import {Chip} from "primereact/chip";


const ValuesChip = (props) => {
    const removeChip = (value) => {
        let s = props.values.filter(i => i !== value);
        props.setValues(s);
    }

    return (
        <div className="grid chip mt-1 pl-2">
            {props.values.map((v, i) => {
                let index = i + (Math.random() * 1000);
                return <Chip label={v}
                             key={index}
                             className="mb-1"
                             style={{"wordBreak": "break-word"}}
                             onRemove={() => removeChip(v)}
                             removable/>

            })}
        </div>

    )
}

export default ValuesChip;