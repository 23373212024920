import React from "react";
import ColumnHeader from "./Header/ColumnHeader";
import classes from "../Row/Row.module.css";
import Aux from "../../../../../hoc/Auxiliary";

const TabHeader = (props) => {
    return (<Aux>
        <div className={`grid ${classes.marginHorizontal} ${classes.header}`}>
            <ColumnHeader name={"User group"}/>
            {React.Children.toArray(props.permissionNames.map((permission, _) => (
                <Aux>
                    <ColumnHeader name={permission}/>
                </Aux>
            )))
            }
            <ColumnHeader name={"ENTSO-E Member"}/>
        </div>
    </Aux>)
}
export default TabHeader;
