import Auxiliary from "../../../../../hoc/Auxiliary";
import {Dropdown} from "primereact/dropdown";
import classes from "../DataCollectionDetails.module.css";
import React, {useEffect, useState} from "react";
import {checkModelVersion} from "../../../../../api/utils/data-collection-form";
import {updateProperty} from "../../../../../api/utils/conditions-utils";


const PEMMDBVersionField = ({
                                version,
                                handleChange,
                                setVersionName,
                                pemmdbVersionList,
                                setIsPEMMDBVersionValid,
                                onModelChange,
                                collapsed
                            }) => {

    const [isValid, setIsValid] = useState(updateProperty(collapsed, checkModelVersion(version), true));

    useEffect(() => {
        setIsPEMMDBVersionValid(isValid);
    }, [isValid])

    const checkPEMMDBVersion = () => {
        setIsValid(checkModelVersion(version))
    }

    const onSelect = (event) => {
        handleChange(event)
        setVersionName(event.value);

    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isValid) {
                checkPEMMDBVersion();
            }
        }, 5000);
        return () => clearTimeout(timer);
    }, [version])

    useEffect(() => {
        checkPEMMDBVersion();
    }, [setVersionName])

    return (
        <Auxiliary>
            <div className="col-4">
                <p><b>PEMMDB Version</b></p>
                <div className="field">
                             <span className="p-float-label">
                        <Dropdown name="model"
                                  value={version}
                                  autoFocus
                                  placeholder="PEMMDB version name"
                                  options={pemmdbVersionList}
                                  optionLabel="name"
                                  style={{borderColor: isValid ? 'lightgrey' : 'red'}}
                                  className={classes.dropDown}
                                  onChange={onSelect}
                                  onSelect={onModelChange}
                                  onHide={checkPEMMDBVersion}
                                  filter/>
                                  </span>
                    {isValid ? null
                        : <small className="p-error block">PEMMDB version is not selected</small>}
                </div>
            </div>
        </Auxiliary>
    )
}

export default PEMMDBVersionField;
