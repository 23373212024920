import React, {useEffect, useState} from "react";
import Aux from "../../hoc/Auxiliary";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {MultiSelect} from "primereact/multiselect";
import classes from "./DataCollectionEditor.module.css";
import {sortAlphabetically} from "../../api/utils/sortArrays";
import {Chip} from "primereact/chip";
import CalendarField
    from "../../components/DataCollection/DataCollectionCreator/Details/OpenFromDeadLine/CalendarField/CalendarField";
import {InputTextarea} from "primereact/inputtextarea";
import {canEditDataCollection} from "../../api/authorization/rules";
import {editPublishedDataCollection, findDataCollectionById} from "../../api/dataCollection/dataCollection-service";
import {showError} from "../../api/notification/ToastManager";
import {Button} from "primereact/button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {loadState, removeFromSessionStorage} from "../../api/State/manage-state-storage-service";
import HeaderBreadCrumb, {normalizePathItem} from "../../components/BreadCrumb/Breadcrumb";
import {getParticipants} from "../../api/dataCollection/particpants-service";
import ManagePermissions
    from "../../components/DataCollection/DataCollectionCreator/ManagePermissions/ManagePermissions";
import {getPermissions} from "../../api/dataCollection/permissions-service";
import ActionList from "../../components/DataCollection/DataCollectionCreator/ActionsList/ActionsList";
import {selectedParticipantsHavePermissions} from "../../api/dataCollection/dataCollection-utils";
import {updateProperty} from "../../api/utils/conditions-utils";
import {onServerCallError} from "../../api/utils/errors";

const DataCollectionEditor = () => {
    const urlParams = useParams();
    const [crumbs, setCrumbs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [notInDCParticipants, setNotInDCParticipants] = useState([]);
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [editBtnLoading, setEditBtnLoading] = useState({Published: false});
    const {state} = useLocation();
    const dataCollection = loadState(state, "dataCollection");
    const [notificationMsg, setNotificationMsg] = useState("");
    const navigate = useNavigate();
    const [participantsPermission, setParticipantsPermission] = useState({});
    const [showManagePermissionPopup, setShowManagePermissionPopup] = useState(false);
    const [btnPublishedIsDisable, setBtnPublishedIsDisable] = useState(true);
    const [indicationParticipantPermissions, setIndicationParticipantPermissions] = useState('');

    const [newDeadline, setNewDeadline] = useState(new Date());
    const [model, setModel] = useState();
    const [permissionNames, setPermissionNames] = useState([]);
    const [dataCollectionDetails, setDataCollectionDetails] = useState();
    const [dataCollectionNewParticipantsPermissions, setDataCollectionNewParticipantsPermissions] = useState([]);

    useEffect(() => {
        try {
            if (urlParams?.dcName && urlParams?.dcName.toLowerCase() === normalizePathItem(dataCollection?.name)) {
                findDataCollectionById(dataCollection?.id)
                    .then(async (data) => {
                        setCrumbs(["Data Collections", dataCollection?.name, "Edit"])
                        let dc = data;
                        setModel(dc.model)
                        setDataCollectionDetails(dc);
                        setIsLoading(false);
                        await searchNotInDCParticipants(getDcParticipants(dc.participants));
                    }).catch(_ => onResponseError(`An Error occurs when retrieving dataCollection "${dataCollection?.name}" details`))
                getPermissions()
                    .then(res => {
                        setPermissionNames(updateProperty(res.status === 200, [...res.content], []))
                    }).catch(_ => {
                    onResponseError(`An Error occurs when retrieving dataCollection "${dataCollection?.name}" permissions`);
                })
            } else {
                removeFromSessionStorage("dataCollection");
                onResponseError("Access to dataCollection details page from address bar is not authorized")
            }
        } catch (e) {
            removeFromSessionStorage("dataCollection");
            onResponseError("An Error occur when loading 'Edit Data Collection' page")
        }

    }, [])

    const onResponseError = (message) => {
        onServerCallError("Failed to retrieve dataCollection details",
            message,
            () => {
                navigate("/data_collections");
            }
        )
    }

    useEffect(() => {
        saveDataCollectionPermissions();
    }, [participantsPermission])
    useEffect(() => {
        if (dataCollectionDetails !== undefined) {
            let dead = new Date();
            dead.setMonth(new Date(dataCollectionDetails.deadline).getMonth())
            dead.setFullYear(new Date(dataCollectionDetails.deadline).getFullYear())
            dead.setDate(new Date(dataCollectionDetails.deadline).getDate())
            setNewDeadline(dead)
        }
    }, [dataCollectionDetails])
    useEffect(() => {
        const partPerm = updateProperty(selectedParticipants.length === 0, true,
            selectedParticipantsHavePermissions(selectedParticipants, participantsPermission));
        setBtnPublishedIsDisable((selectedParticipants.length !== 0 && !partPerm) || newDeadline === null)
        setIndicationParticipantPermissions(
            updateProperty(partPerm, "", "Missing one or more participant  permissions"));
    }, [dataCollection, participantsPermission, selectedParticipants, newDeadline])
    const saveDataCollectionPermissions = () => {
        let permissionsDataNames = permissionNames.map(p => p.name);
        let res = []
        Object.keys(participantsPermission).forEach(id => {
            let participant = selectedParticipants.filter(sp => sp.groupId === id)[0];
            Object.keys(participantsPermission[id]).forEach(dp => {
                if (permissionsDataNames.includes(dp)) {
                    let permissionName = permissionNames.filter(p => p.name === dp)[0]
                    let mz = participantsPermission[id][dp];
                    mz.map(m => {
                        let marketNode = {"id": m.id, "name": m.name};
                        res.push({participant, marketNode, permissionName})
                    })
                }
            })
        })
        setDataCollectionNewParticipantsPermissions(res)
    }

    function getDcParticipants(dc) {
        let dcParticipants = [];
        for (const dcElement of dc) {
            dcParticipants.push(dcElement.participant)
        }
        return dcParticipants;
    }

    const abortEdit = () => {
        navigate("/data_collections/")
    }

    const searchNotInDCParticipants = async (dcParticipants) => {
        let allParticipants = await getAllParticipants();
        let newParticipants = allParticipants.filter(ar => !dcParticipants.find(rm => (rm.name === ar.name)))
        setNotInDCParticipants(newParticipants)
    }

    async function getAllParticipants() {
        let allParticipants = [];
        await getParticipants()
            .then(res => {
                if (res.status === 200) {
                    allParticipants = [...res.content];
                }
            }).catch(_ => {
                onResponseError("An Error occurs when retrieving dataCollection participants.")
            })
        return allParticipants;
    }

    const handleDelete = (p) => {
        let s = selectedParticipants.filter(i => i.name !== p.name);
        setSelectedParticipants(s);
        updatePermissions(s);
    };
    const updatePermissions = (participants) => {
        let res = {};
        participants.map((par, _) => {
            let p = {};
            permissionNames.forEach((per) => {
                p[per.name] = [];
            })
            res[par.groupId] = updateProperty(Object.keys(participantsPermission).includes(par.groupId), participantsPermission[par.groupId], p)
        })
        setParticipantsPermission(res);
    }

    const editDataCollection = () => {
        setEditBtnLoading({'Published': true})
        if (canEditDataCollection()) {
            editPublishedDataCollection(dataCollectionDetails, dataCollectionNewParticipantsPermissions, newDeadline, notificationMsg)
                .then((_) => {
                    if (_) {
                        navigate("/data_collections")
                    } else {
                        setEditBtnLoading({'Published': false})
                    }
                })
        } else {
            showError("Unauthorized", "You are not authorized to edit data collection " + dataCollection?.name)
        }
    }

    const handleChangeDeadline = (event) => {
        setNewDeadline(event.target.value)
    }

    const showManagePermissions = () => {
        if (selectedParticipants.length <= 0) {
            showError("No Participants selected", "Please select Participants")
        } else {
            if (Object.keys(participantsPermission).length === 0) {
                setParticipantsPermission(initializePermissionByParticipants)
            }
            setTimeout(() => {
                setShowManagePermissionPopup(true);
            }, 100)
        }
    }
    const initializePermissionByParticipants = () => {
        const permissionsByParticipants = {};
        if (permissionNames.length !== 0) {
            selectedParticipants.forEach(x => {
                if (!permissionsByParticipants[x.groupId]) {
                    const p = {};
                    permissionNames.forEach(x => {
                        p[x.name] = [];
                    })
                    p[""] = [];
                    permissionsByParticipants[x.groupId] = p;
                }
            })
        }
        return permissionsByParticipants
    }

    return updateProperty(isLoading
        , (<LoadingScreen/>)
        , (<Aux>
            <HeaderBreadCrumb items={crumbs}/>
            <h3>Edit data collection</h3>
            <div className="col-6">
                <p><b>New Participants</b></p>
                <MultiSelect name="selectedParticipants" fixedPlaceholder={true} value={selectedParticipants}
                             className={classes.selectStyle}
                             options={sortAlphabetically(notInDCParticipants, 'name')}
                             onChange={(e) => {
                                 updatePermissions(e.value)
                                 setSelectedParticipants(e.value)
                             }}
                             optionLabel="name"
                             placeholder="Select Participants" filter
                             disabled={editBtnLoading.Published}/>
                <Button type="button"
                        className={classes.btnStyle} label="Manage permissions"
                        onClick={showManagePermissions}
                        disabled={editBtnLoading.Published}
                />
                <ManagePermissions setDialogVisible={(e) => setShowManagePermissionPopup(e)}
                                   isDialogVisible={showManagePermissionPopup}
                                   version={model}
                                   participants={selectedParticipants}
                                   permissions={participantsPermission}
                                   setPermissionPerParticipant={(e) => {
                                       setParticipantsPermission(e)
                                   }}
                                   permissionsData={permissionNames}

                />
                <br/><br/>
                {
                    selectedParticipants.map((s) => {
                        let index = 1 + (Math.random() * 1000);
                        return <Chip key={index} label={s.name} onRemove={() => handleDelete(s)}
                                     className={"mb-2"} removable/>
                    })
                }
                <p style={{color: "red"}}>{indicationParticipantPermissions}</p>
            </div>
            <div className="col-6">
                <CalendarField
                    title={" New Deadline"}
                    name={"deadline"}
                    value={newDeadline}
                    minDate={new Date()}
                    isValid={true}
                    onChange={handleChangeDeadline}
                    disabled={editBtnLoading.Published}
                />
            </div>
            <div className="col-6">
                <p><b>Message to the participants</b></p>
                <InputTextarea name="mailMessage" rows={5}
                               cols={30}
                               autoResize
                               className={classes.textArea}
                               onChange={e => {
                                   setNotificationMsg(e.target.value);
                               }}
                               disabled={editBtnLoading.Published}
                />
            </div>
            <ActionList
                saveAsPublished={() => editDataCollection()}
                buttonsLoadingState={editBtnLoading}
                publishIsDisable={btnPublishedIsDisable}
                draftIsHidden={true}
                abort={() => abortEdit()}
            />
        </Aux>));
}

export default DataCollectionEditor;
