import React from "react";

const TokenListHeader = (props) => {
    return (<div className="grid p-0 m-0">
        <div className="col">
            Access key list
        </div>
        {props.children}
    </div>)
}
export default TokenListHeader;
