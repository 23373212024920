import React, {useState} from "react";
import {StatusCode} from "../../../../entities/StatusContext";
import {isAuthorized} from "../../../../api/authorization/authorization-service";
import {Button} from "primereact/button";
import classes from "../DataCollectionList.module.css";
import {Tooltip} from "primereact/tooltip";
import {getDataCollectionDetails, sendMCInvitation} from "../../../../api/dataCollection/dataCollection-service";
import {updateDataCollectionDates} from "../../../../api/dataCollection/dataCollection-utils";

const ManualCheckColumn = ({rowData, reload, setReload, updateDataCollections}) => {
    const [rowDataContent, setRowDataContent] = useState({...rowData})
    const [btnSendMCDisabled, setBtnSendMCDisabled] = useState(false);
    const sendInvitation = async (id) => {
        setBtnSendMCDisabled(true);
        await getDataCollectionDetails(id)
            .then(async (dc) => {
                // retrieve dataCollection after sending invitation
                await sendMCInvitation(id).then(res => {
                    // format dates
                    const newDc = updateDataCollectionDates(res.data.content);
                    setRowDataContent({...newDc})
                    // update Table
                    updateDataCollections({...newDc})
                });
            })
        setReload(!reload)
        setBtnSendMCDisabled(false)
    }

    if (rowDataContent.dataStatus.code === StatusCode.MANUEL_CHECK_IN_PROGRESS ||
        rowDataContent.dataStatus.code === StatusCode.MANUAL_CHECK_COMPLETED)
        return (
            <div>
                <p style={{margin: 0}}>
                    <b>{rowDataContent['totalSuccessManualCheck']} / {rowDataContent['totalMarketNode']} [OK]</b></p>
                <p style={{margin: 0}}>
                    <b>{rowDataContent['totalFailedManualCheck']} / {rowDataContent['totalMarketNode']} [NOT
                        OK]</b></p>
            </div>
        )
    else if (rowDataContent.dataStatus.code === StatusCode.DATA_PERSISTED && isAuthorized("AR026")) {
        return (
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">

                <Button type="button"
                        className={classes.buttonSendMC}
                        disabled={btnSendMCDisabled}
                        icon=" pi pi-envelope" label="Send invitation"
                        onClick={() => sendInvitation(rowDataContent.id)}
                />
            </div>

        )
    } else {
        return (
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <Tooltip
                    target={document.getElementById(`mc-${rowDataContent.id}`)}
                    content={"Not yet executed"}
                    position={"bottom"}
                />
                <i className="pi pi-circle-fill"
                   id={`mc-${rowDataContent.id}`}
                   style={{color: "grey"}}
                />
            </div>
        )
    }
}
export default ManualCheckColumn;
