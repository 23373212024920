import React from "react";
import {Dropdown} from "primereact/dropdown";

const TemplatePaginator = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
        const dropdownOptions = [
            {label: 5, value: 5},
            {label: 10, value: 10},
            {label: 20, value: 20},
        ];

        return (
            <React.Fragment>
                    <span className="mx-1"
                          style={{color: 'var(--text-color)', userSelect: 'none'}}>Items per page: </span>
                <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange}/>
            </React.Fragment>
        );
    },
    'CurrentPageReport': (options) => {
        return (
            <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
        )
    }
};
export default TemplatePaginator;
