import React from "react";
import classes from "../TemplateGeneration.module.css";
import {isClosed} from "../../../../../../api/dataCollection/dataCollection-status";
import {Button} from "primereact/button";
import {isAuthorized} from "../../../../../../api/authorization/authorization-service";

const DownloadButton = ({templateIsDownloaded, downloadTemplate, dataCollection}) => {
    return (
        <Button className={classes.templateBtn} label="Download template" loading={templateIsDownloaded}
                icon="pi pi-download"
                onClick={downloadTemplate} disabled={!isAuthorized("AR08") && isClosed(dataCollection.status)}/>)
}

export default DownloadButton;
