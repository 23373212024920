import {hasDetailedPermission, hasWritePermission} from "./permissions";
import {loadFromSessionStorage} from "../State/manage-state-storage-service";
import {RULES_KEY} from "../static-data/Configurations";


export const canExportDataWithSpecificFormat = (participants, marketNode) => {
    const rules = loadFromSessionStorage(RULES_KEY);
    if (!rules.includes("AR09")) {
        return false;
    }
    if (rules.includes("AR017")) {
        return true;
    }
    if (rules.includes("AR018")) {
        const writePermission = hasWritePermission(participants, marketNode);
        const detailedPermission = hasDetailedPermission(participants, marketNode);
        return writePermission || detailedPermission;
    }
    return false;
}

export const canExportData = () => {
    const rules = loadFromSessionStorage(RULES_KEY)
    if (!rules.includes("AR09")) {
        return false;
    }
    return rules.includes("AR017") || rules.includes("AR018")
}

export const canUploadData = (participants, marketNode) => {
    const rules = loadFromSessionStorage(RULES_KEY)
    if (!rules.includes("AR03")) {
        return false;
    }
    if (rules.includes("AR021")) {
        return true;
    }
    if (rules.includes("AR022")) {
        return hasWritePermission(participants, marketNode);
    }
    return false;
}

export const canSendManualCheckResult = (participants, marketNode) => {
    const rules = loadFromSessionStorage(RULES_KEY)
    if (rules.includes("AR025")) {
        return hasWritePermission(participants, marketNode) || rules.includes("AR026");
    }
    return false;
}
export const canCheckIn = (participants, marketNode) => {

    return false;
}

export const canCloseDC = () => {
    const rules = loadFromSessionStorage(RULES_KEY)
    return rules.includes("AR027");
}

export const canCloseDataCollection = (status) => {
    return status === "Permanently"
        ? canCloseDCPermanently()
        : canCloseDCTemporary();
}
export const canCloseDCPermanently = () => {
    const rules = loadFromSessionStorage(RULES_KEY)
    return rules.includes("AR028");
}
export const canCloseDCTemporary = () => {
    const rules = loadFromSessionStorage(RULES_KEY)
    return rules.includes("AR029");
}
export const canOpenDataCollection = () => {
    const rules = loadFromSessionStorage(RULES_KEY)
    return rules.includes("AR08");
}
export const canEditDataCollection = () => {
    const rules = loadFromSessionStorage(RULES_KEY)
    return rules.includes("AR08");
}


