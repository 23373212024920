import React from "react";
import Aux from "../../hoc/Auxiliary"
import {Message} from 'primereact/message';

const ErrorServer = () => {
    return (
        <Aux>
            <div className="grid">
                <div className="col-12 flex align-items-center justify-content-center" style={{color: "#487c84"}}>
                    <h3> Internal Server Error </h3>
                </div>
                <div className="col-12">
                    <Message style={{width: '100%'}}
                             severity="error" icon="pi pi-wrench"
                             text="Well, this is unexpected ..."></Message>
                </div>
            </div>


        </Aux>

    );
}
export default ErrorServer;
