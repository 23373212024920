import Aux from "../../hoc/Auxiliary";
import ParticipantsSetting
    from "../../components/DataCollection/DataCollectionCreator/ParticipantsSetting/ParticipantsSetting";
import React, {useCallback, useEffect, useState} from "react";
import HeaderBreadCrumb, {normalizePathItem} from "../../components/BreadCrumb/Breadcrumb";
import DataCollectionDetails from "../../components/DataCollection/DataCollectionCreator/Details/DataCollectionDetails";
import Categories from "../../components/DataCollection/DataCollectionCreator/Categories/Categories";
import ActionList from "../../components/DataCollection/DataCollectionCreator/ActionsList/ActionsList";
import {
    deleteDataCollectionDraft,
    findDataCollectionById,
    saveDataCollection
} from "../../api/dataCollection/dataCollection-service";
import keycloak from "../../keycloak";
import Template from "../../components/DataCollection/DataCollectionCreator/Template/Template";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {
    deleteDataCollectionFromLocalStorage,
    draftButtonIsDisable,
    emptyDataCollection,
    fetchDataCollections,
    getPermissionByParticipants,
    getSelectedParticipants,
    publishButtonIsDisable,
    selectedParticipantsHavePermissions,
    updateParticipantsPermission,
} from "../../api/dataCollection/dataCollection-utils";
import {
    loadFromSessionStorage,
    loadState,
    removeFromSessionStorage
} from "../../api/State/manage-state-storage-service";
import {updateProperty} from "../../api/utils/conditions-utils";
import classes from "../../components/DataCollection/DataCollectionCreator/Details/DataCollectionDetails.module.css";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import ExistingDataCollections
    from "../../components/DataCollection/DataCollectionCreator/DataCollectionToCopy/ExistingDataCollections";
import {showError, showInfo} from "../../api/notification/ToastManager";
import {NEW_DATA_COLLECTION_PATH} from "../../api/static-data/Configurations";

const DataCollectionBuilder = () => {
    const location = useLocation();
    const {dcName} = useParams();

    const navigate = useNavigate();
    const [scrollY, setScrollY] = useState(-1);
    const [display, setDisplay] = useState(false);
    const [participantsPermission, setParticipantsPermission] = useState({});
    const [dataCollection, setDataCollection] = useState({
        ...emptyDataCollection,
        createdBy: keycloak.idTokenParsed.name
    });
    const [pemmdbVersionNameList, setPemmdbVersionNameList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [participants, setParticipants] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState("");
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [btnSaveLoading, setBtnSaveLoading] = useState({Draft: false, Published: false});
    const [btnPublishedIsDisable, setBtnPublishedIsDisable] = useState(true);
    const [btnDraftIsDisable, setBtnDraftIsDisable] = useState(true);
    const [indicationParticipantPermissions, setIndicationParticipantPermissions] = useState('');
    const [permissionsData, setPermissionsData] = useState([]);
    const [existingDataCollections, setExistingDataCollections] = useState([]);
    const [generalSettings, setGeneralSettings] = useState({});
    const [categoriesValid, setCategoriesValid] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [crumbItems, setCrumbItems] = useState(["Data Collections", "New"]);
    const [existingPublishedDCs, setExistingPublishedDCs] = useState([]);
    const [newPermission, setNewPermission] = useState({});
    const [participantsFromOtherDC, setParticipantsFromOtherDC] = useState(null);


    useEffect(() => {
        let selectedDataCollection = loadState(location?.state, "dataCollection");
        let screen = " ";
        if (location.pathname.endsWith(NEW_DATA_COLLECTION_PATH)) {
            screen = " 'Create new dataCollection' "
            removeFromSessionStorage("dataCollection");
            selectedDataCollection = null
        }
        fetchDataCollections().then((data) => {
            try {
                setPemmdbVersionNameList(data.pemmdbVersions)
                setExistingDataCollections([...data.draftDataCollections.map(e => e.name), ...data.nonDraftDataCollections.map(e => e.name)].filter(x => x !== selectedDataCollection?.name))
                setExistingPublishedDCs(data.nonDraftDataCollections);
                setParticipants([...data.participants])
                setPermissionsData([...data.permissionsData])
                if (selectedDataCollection) {
                    screen = " 'Edit dataCollection' "
                    setCrumbItems(["Data Collections", "Edit"]);
                    setIsEditMode(true);
                    searchTargetDataCollection(selectedDataCollection, data.pemmdbVersions);
                }
            } catch (e) {
                showError("Error loading page", "An error occurs while displaying" + screen + "page")
                removeFromSessionStorage("dataCollection");
                navigate('/data_collections');
            }
            setIsLoading(false);
        })

    }, [])

    const handleScroll = useCallback(() => {
        if (Math.abs(window.scrollY - scrollY) > 100) {
            setScrollY(window.scrollY);
        }
    }, [scrollY])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    const searchTargetDataCollection = (dataCollection, versionList) => {
        if (normalizePathItem(dataCollection?.name) === dcName) {
            findDataCollection(dataCollection, versionList)
        } else {
            setDataCollection({...emptyDataCollection, createdBy: keycloak.idTokenParsed.name})
            removeFromSessionStorage("dataCollection");
            navigate(NEW_DATA_COLLECTION_PATH);
        }
    }

    const findDataCollection = (dataCollection, versionList) => {
        findDataCollectionById(dataCollection?.id).then(d => {
            d.openFrom = new Date(d.openFrom)
            d.deadline = new Date(d.deadline)
            const version = versionList.find(x => x.id === d.model.id);
            setSelectedVersion(updateProperty(version !== undefined, version, ""));
            setSelectedParticipants(getSelectedParticipants(d.participants));
            setParticipantsPermission(getPermissionByParticipants(d.participants));
            setDataCollection(d)
        })
    }

    const saveAs = async (status, message) => {
        try {
            let data = {
                "status": status.toUpperCase(),
                "dataCollection": dataCollection,
                "successMsg": message

            };
            setBtnSaveLoading({...btnSaveLoading, [status]: true})
            setIsLoading(true);
            const dc = loadFromSessionStorage("dataCollection");
            deleteDataCollectionFromLocalStorage(data);
            if (dc) {
                await deleteDataCollectionDraft({"dcId": dc?.id, "dcName": dc?.name});
            }
            save(data, status)
        } catch (e) {
            showError("Error", "An error occurs while saving dataCollection")
            removeFromSessionStorage("dataCollection");
            navigate('/data_collections');
        }
    }

    const save = (data, status) => {
        const timeOut = setTimeout(() => {
            showInfo("Info", "Saving Data Collection might take several minutes")
            navigate("/data_collections/")
        }, 15000);
        data.dataCollection.modifiedIn = updateProperty(status === "Draft", new Date(), data.dataCollection.modifiedIn);
        data.dataCollection.modifiedBy = updateProperty(status === "Draft", keycloak.idTokenParsed.name, data.dataCollection.modifiedBy);
        saveDataCollection(data).then(res => {
            clearTimeout(timeOut)
            if (res === 200) {
                navigate("/data_collections/")
            } else {
                setBtnSaveLoading({...btnSaveLoading, [status]: false})
                setIsLoading(false);
            }
        });
    }

    const onModelChange = () => {
        if (selectedVersion.name !== dataCollection.model.name) {

            let marketNodeList = selectedVersion.marketNodes.map(x => x.name)

            Object.keys(participantsPermission).forEach(x => {
                const p = participantsPermission[x]
                permissionsData.forEach((y) => {
                    p[y.name] = p[y.name].filter(m => marketNodeList.includes(m.name))
                })
            })
            let sheets = selectedVersion.sheets.map(x => x.name)
            dataCollection.categories.map(y => {
                y.sheets = y.sheets.filter(s => sheets.includes(s.name))
                handleChange()


            })
        }
    }
    useEffect(() => {

        setParticipantsPermission(updateParticipantsPermission(selectedParticipants, participantsPermission))
    }, [selectedParticipants])

    useEffect(() => {
        const partPerm = selectedParticipantsHavePermissions(selectedParticipants, participantsPermission);
        setBtnPublishedIsDisable(!(partPerm && categoriesValid &&
            !publishButtonIsDisable(generalSettings, selectedParticipants, dataCollection.categories)))
        setBtnDraftIsDisable(draftButtonIsDisable(dataCollection.name, generalSettings))
        setIndicationParticipantPermissions(
            updateProperty(selectedParticipants.length === 0, 'Participant(s) should be selected',
                updateProperty(partPerm, "", "Missing one or more participant  permissions")));
    }, [dataCollection, participantsPermission, generalSettings, selectedParticipants, categoriesValid])


    const handleChange = (property, newValue) => {
        setDataCollection({...dataCollection, [property]: newValue})
        if (property === 'model') {
            dataCollection.categories.forEach(c => {
                c.sheets.forEach(s => {
                    let find = newValue.sheets.find(
                        (elt) => elt.name === s.name);
                    s.id=find.id
                    s.targetYearStart = find.targetYearStart
                    s.targetYearEnd = find.targetYearEnd
                })
            })
        }
    }

    useEffect(() => {
        if (participantsFromOtherDC) {

            handleChange("participants", participantsFromOtherDC)
            setParticipantsPermission(newPermission);
            setSelectedParticipants(getSelectedParticipants(participantsFromOtherDC));

        }
    }, [newPermission])
    const manageHideDialog = () => {
        setDisplay(false);
    }
    const abort = () => {
        navigate("/data_collections/")
    }
    return updateProperty(isLoading
        , (<LoadingScreen/>)
        , (<Aux>
            <Dialog header="Copy from another data collection" visible={display} style={{width: '60vw'}}
                    onHide={() => manageHideDialog()}>
                <ExistingDataCollections dcList={existingPublishedDCs}
                                         setSelectedPreviousDc={selectedParticipants}
                                         setParticipantsFromOtherDC={setParticipantsFromOtherDC}
                                         participants={participants}
                                         permissionsData={permissionsData}
                                         setNewPermission={setNewPermission}
                                         marketNodes={selectedVersion.marketNodes}
                                         setSelectedVersion={(vn) => setSelectedVersion(vn)}
                                         onChange={(p, v) => handleChange(p, v)}
                                         setDisplay={setDisplay}
                                         setDataCollection={setDataCollection}


                >
                </ExistingDataCollections>
            </Dialog>
            <HeaderBreadCrumb items={crumbItems}/>
            <div className={classes.container}>
                <h3>Setup new data collection</h3>
                <Button className={classes.btnStyle}
                        visible={!isEditMode}
                        label="Copy from another Data Collection" icon="pi pi-copy"
                        onClick={() => {
                            setDisplay(true)
                        }}
                />
            </div>
            <form>

                <DataCollectionDetails dataCollection={dataCollection}
                                       pemmdbVersionList={pemmdbVersionNameList}
                                       onChange={(p, v) => handleChange(p, v)}
                                       version={selectedVersion}
                                       setVersionName={(vn) => setSelectedVersion(vn)}
                                       existingDataCollections={existingDataCollections}
                                       setGeneralSettings={setGeneralSettings}
                                       onModelChange={onModelChange}
                                       scroll={scrollY}
                />
                <Categories categoryList={dataCollection.categories}
                            modelSheet={dataCollection.model.sheets}
                            onChange={(p, v) => handleChange(p, v)}
                            setCategoriesValid={setCategoriesValid}
                            isEditMode={isEditMode}
                />
                <ParticipantsSetting participants={participants}
                                     mailMessage={dataCollection.mailMessage}
                                     setParticipants={setParticipants}
                                     onChange={(p, v) => handleChange(p, v)}
                                     version={selectedVersion}
                                     selectedParticipants={selectedParticipants}
                                     setSelectedParticipants={(e) => setSelectedParticipants(e)}
                                     errorMsg={indicationParticipantPermissions}
                                     permissionsData={permissionsData}
                                     participantsPermission={participantsPermission}
                                     setParticipantsPermission={(e) => {
                                         setParticipantsPermission(e)
                                     }}
                                     existingPublishedDCs={existingPublishedDCs}
                                     setNewPermission={setNewPermission}
                                     setParticipantsFromOtherDC={setParticipantsFromOtherDC}
                                     marketNodes={selectedVersion.marketNodes}
                />
                <Template dataCollection={dataCollection}
                          modelVersion={selectedVersion}
                          generalSettings={generalSettings}
                />
                <ActionList saveAsDraft={() => saveAs("Draft", " was saved as Draft")}
                            saveAsPublished={() => saveAs("Published", " was published")}
                            buttonsLoadingState={btnSaveLoading}
                            publishIsDisable={btnPublishedIsDisable}
                            draftIsDisable={btnDraftIsDisable}
                            abort={() => abort()}

                />
            </form>
        </Aux>))
}

export default DataCollectionBuilder;
