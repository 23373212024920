import classes from "./ActionList.module.css";
import {Button} from "primereact/button";
import {saveDataModel} from "../../../../../api/DataModel/data-model";
import {showError, showSuccess} from "../../../../../api/notification/ToastManager";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import LoadingScreen from "../../../../../containers/LoadingScreen/LoadingScreen";


const ActionList = (props) => {
    const [buttonState, setButtonState] = useState(false);
    const [abortBtnDisabled, setAbortBtnDisabled] = useState(false);
    const [draftBtnDisabled, setDraftBtnDisabled] = useState(false);
    const [publishedUp, setPublishedUp] = useState(false);
    const [draftUp, setDraftUp] = useState(false);
    const [publishedBtnDisabled, setPublishedBtnDisabled] = useState(false);
    const navigate = useNavigate();

    const checkCurrentTag=()=>{
        if (!props.isCompleted && props.previousModel!==undefined && props.previousModel!==null
            && props.previousModel!==props.model){
            return props.previousModel;
        }
        return props.model;
    }

    const saveAs = (status, code, message, callbackOnSuccess, callbackOnFail) => {
        setAbortBtnDisabled(true);
        let dataModel = {
            "id": props.id,
            "name": props.name,
            "previousName":props.previousName===""?props.name:props.previousName,
            "description": props.description,
            "createdIn": props.creationDate,
            "tag": checkCurrentTag(),
            "action": status,
            "documentation": props.documentation
        };

        saveDataModel(dataModel).then(result => {
            triggerNotification(props.name, result, message);
            callbackOnSuccess()
        }).catch(_ => {
            setAbortBtnDisabled(false);
            callbackOnFail()
        })
    }
    const publish = () => {
        setPublishedUp(true);
        saveAs("PUBLISH", props.modelStatus, "published",
            () => {
                navigate("/pemmdb_versions");
            },
            () => {
                setPublishedUp(false);
                showError("Error", "An error occurred when publishing " + props.name + " PEMMDB version")
            })
    }

    const draft = () => {
        setDraftUp(true);
        saveAs("DRAFT", "DRAFT", "saved", () => {
        }, () => {
            setDraftUp(false);
            showError("Error", "An error occurred when saving " + props.name + " PEMMDB version")
        })
    }

    function triggerNotification(name, result, state) {
        if (result.data.status === 204 || result.data.status === 500) {
            showError("Error", "An error occurs when creating PEMMDB Version: " + result.data.message);
            setPublishedUp(false);
            setDraftUp(false);
        } else if (result.data.status === 200) {
            showSuccess("Success", "Pemmdb version " + name + " is " + state);
            navigate("/pemmdb_versions");
        }


    }

    function abort() {
        props.abort('');
    }

    function checkButtonState() {
        if (props.name.length === 0 || !props.isValidName) {
            setButtonState(true);
        } else setButtonState(false);
    }

    function checkButtonPublishedState() {
        if (typeof props.model === 'undefined' || props.model === null || !props.isValidName) {
            setPublishedBtnDisabled(true);
        } else setPublishedBtnDisabled(false);
    }

    function checkButtonDraftState() {
        if (props.accessWrite) {
            if (props.name.length === 0 || !props.isValidName) {
                setDraftBtnDisabled(true)
            } else setDraftBtnDisabled(false);
        } else {
            setDraftBtnDisabled(true)
        }

    }

    useEffect(() => {
        checkButtonState();
        checkButtonPublishedState();
        checkButtonDraftState();

    });

    let isPublished = publishedUp ? (
        <LoadingScreen>
            <h2 className={classes.progressTitle}>Publishing PEMMDB Version In Progress ...</h2>
        </LoadingScreen>
    ) : (<div className={classes.buttons}>
        <Button className={classes.actionBtn} label="Publish PEMMDB version" onClick={() => publish()}
                tooltip={props.tooltipVal}
                tooltipOptions={{position: 'top', showOnDisabled: true}}
                disabled={publishedBtnDisabled || buttonState || !props.isValid || props.isLoading}
                loading={publishedUp}/>
        <Button className={classes.actionBtn} label="Save Draft" onClick={() => draft()}
                disabled={draftBtnDisabled || props.isLoading || !props.isDraft} loading={draftUp}/>
        <Button className={classes.actionBtn} label="Abort" onClick={() => abort()}
                disabled={abortBtnDisabled || props.isLoading}/>
    </div>)

    return <div>{isPublished}</div>

}

export default ActionList;
