import React, {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import {getErrorsByDataCollectionsId} from "../../../../api/dataCollection/dataCollection-service";
import {showError} from "../../../../api/notification/ToastManager";
import ErrorStructure from "./ErrorsStructure/ErrorStructure";


const DataCollectionsErrorList = (props) => {
    const op = useRef(null);
    const [errors, setErrors] = useState([]);
    const [selectedElement, setSelectedElement] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnErrorListVisible, setIsBtnErrorListVisible] = useState(false);


    const close = () => {
        op.current.hide()
        setSelectedElement(0);
        setIsLoading(false);
        setErrors([]);

    }

    const searchErrors = async (e, id) => {
        op.current.toggle(e);
        if (selectedElement === 0) {
            setIsLoading(true);
            setSelectedElement(id);

            await getErrorsByDataCollectionsId(props.row.id)
                .then(res => {
                    if (res.status === 200) {
                        setErrors(res.content);
                        setIsLoading(false);
                    } else {
                        showError("Error", "A problem was occurred when retrieving list of data collection errors");
                        close();
                    }
                })
        }
    }

    useEffect(() => {
        setIsBtnErrorListVisible(props.row.errorNotificationPresent)
    }, [])

    return (
        <div className="grid">
            <div className="col-10 pb-0">{props.row.name}</div>
            <div className="col-1 pb-0">
                <Button icon="pi pi-exclamation-circle"
                        tooltipOptions={{position: 'top', showOnDisabled: true}}
                        onClick={(e) => searchErrors(e, props.row.id)}
                        visible={isBtnErrorListVisible}
                        className=" p-button-rounded-sm p-button-text"
                        style={{height: '0.5rem', width: '1.5rem', color: 'darkred'}}
                />
                <OverlayPanel ref={op} onHide={close}>
                    <ErrorStructure dataCollectionId={props.row.id}
                                    errors={errors}
                                    setIsBtnErrorListVisible={setIsBtnErrorListVisible}
                                    isLoading={isLoading}
                                    close={close}
                    />
                </OverlayPanel>
            </div>
        </div>
    )
}

export default DataCollectionsErrorList;
