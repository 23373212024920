import {Button} from "primereact/button";
import classes from "../AdminTool/AdminTools.module.css";
import React, {useEffect, useState} from "react";
import Aux from "../../../hoc/Auxiliary";
import SetupQueriesButton from "./PersistES/SetupQueriesButton";
import CloseDCButton from "./CloseDCButton/CloseDCButton";
import {isAuthorized} from "../../../api/authorization/authorization-service";
import {isPermanentlyClosed} from "../../../api/dataCollection/dataCollection-status";
import ReopenDCButton from "./ReopenDC/ReopenDCButton";

const AdminTool = (props) => {
    const [adminBtnDisabled, setAdminBtnDisabled] = useState(false);

    useEffect(() => {
        setAdminBtnDisabled(isPermanentlyClosed(props.dataCollection.status))
    }, [])

    const checkSendMCInvitationBTNDisability = () => {
        if (adminBtnDisabled) {
            return true;
        }
        if (isPermanentlyClosed(props.dataCollection.status)) {
            return true;
        }
        return isAuthorized("AR026") ? props.sendMCDisabled : true;
    }
    return (

        <Aux>
            <div className={classes.tool}>
                <CloseDCButton dataCollection={props.dataCollection}
                               setAdminToolsButtonsDisabled={setAdminBtnDisabled}/>
                <SetupQueriesButton dataCollectionId={props.dataCollection.id}
                                    dataCollectionName={props.dataCollection.name}
                                    marketNodes={props.marketNodes}
                                    persistDisabled={props.persistDisabled}
                                    setReload={props.setReload}
                                    reload={props.reload}
                                    dataCollectionStatus={props.dataCollection.status}
                                    adminToolsButtonsDisabled={adminBtnDisabled}/>
                <Button
                    visible={isAuthorized("AR026")}
                    disabled={checkSendMCInvitationBTNDisability()}
                    label="Send manual check invitation"
                    onClick={props.onSendMCInvitation}
                    className={classes.btn}
                    icon="pi pi-envelope"/>
                <ReopenDCButton dataCollection={props.dataCollection} marketNodes={props.marketNodes}/>
            </div>
        </Aux>

    )

}
export default AdminTool;
