export const sortByDate = (data, dateField) => {
    try {
        const nd = [...data]
        return nd.sort(function (a, b) {
            const ka = new Date(a[dateField]);
            const kb = new Date(b[dateField]);
            return compare(ka, kb)
        })
    } catch (e) {
        return data;
    }
}

export const sortAlphabetically = (data, dataField) => {
    try {
        const nd = [...data]
        return nd.sort(function (a, b) {
            const ka = a[dataField].toUpperCase();
            const kb = b[dataField].toUpperCase();
            return compare(kb, ka)
        })
    } catch (e) {
        return data;
    }
}
/**
 * Sort array by idField descendant
 * New element has big id value
 * */
export const sortById = (data, idFieldName) => {
    try {
        const nd = [...data]
        return nd.sort(function (a, b) {
            return compare(a[idFieldName], b[idFieldName])
        })
    } catch (e) {
        return data;
    }
}

const compare = (oA, oB) => {
    if (oA < oB) return 1;
    if (oA > oB) return -1;
    return 0;
}


