import React, {useEffect, useState} from "react";
import {getModelInfo} from "../../../api/file/upload-service";
import {useParams} from "react-router-dom";

const ModelDocumentation = (props) => {
    let {version} = useParams();
    const [html, setHtml] = useState('');
    useEffect(() => {
        getModelInfo(version).then(res => {
            setHtml(res);

        }).catch(err => {
            setHtml("Not Implemented Yet")
        });
    }, []);

    return <iframe
        title="HTML Document"
        sandbox="allow-scripts allow-same-origin"
        srcDoc={html}
        style={{width: "100%", height: "1000px"}}
        frameBorder="0"
    />

}
export default ModelDocumentation;
