export const removeNonUtf8 = (text) => {
    return text.split('').map(c => c.charCodeAt(0) <= 127 ? c : '').join('');
}

export const removeNonAlphaNumeric = (text) => {
    return text.split('').map(c => c.match(/^\w+$/) === null ? '' : c).join('');
}

export const replaceMultiSpaceWithSingleSpace = (str) => {
    return str.replace(/\s+/g, ' ')
}
