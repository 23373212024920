import keycloak from "../../keycloak";
import axios from "axios";
import {showError, showInfo} from "../notification/ToastManager";
axios.defaults.withCredentials = true
export async function getPermissions() {

    let data = {};
    try {
        await axios.get(global.config.BACK_URL + "permissions", {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        }).then(response => {
            if (response.data.status === 200) {
                data.content = response.data.content;
                data.status = response.data.status;
                if (response.data.content.length === 0) {
                    showInfo("Info", "there is no permission saved in database");
                }
            } else {
                showError("Error", "An error was occurred when retrieving permissions list");
                data.content = [];
                data.status = response.data.status;
            }
        })
    } catch (e) {
        showError("Error", "An error was occurred when retrieving permissions list");
        data = {
            status: e.response.data.status,
            content: []
        }
        return data;
    }
    return data;
}
