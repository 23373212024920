import React from "react";
import "../../../../QueryConfiguration.css"
import {MultiSelect} from "primereact/multiselect";


const MarketsNodesComponent = (props) => {

    return (
        <div key={props.query.queryId + props.p.name + "Mn"} className="col-2">
            <h5 key={`${props.keyEl}Mn-title`}>Market nodes</h5>
            <MultiSelect value={props.marketNodes}
                         onChange={(e) => {
                             props.setMarketNodes(e.value);
                             props.setInProgress(!props.inProgress);
                         }}
                         key={`${props.keyEl}Mn-component`}
                         options={props.dcMarketNodes}
                         optionLabel="name"
                         placeholder="Market nodes"
                         maxSelectedLabels={3}
                         className="w-full md:w-13rem"
                         filter/>
        </div>
    )
}

export default MarketsNodesComponent;