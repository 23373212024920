import React, {useEffect, useState} from "react";
import classes from "./TemplateGeneration.module.css"
import {downloadExcel, generateTemplateExcel} from "../../../../../api/file/download-service";
import {showError} from "../../../../../api/notification/ToastManager";
import DownloadButton from "./DownloadButton/DownloadButton";
import {
    isTemplateGenerationFailed,
    isTemplateGenerationInProgress
} from "../../../../../api/MarketNode/marketNode-status";

const TemplateGen = (props) => {
    const [templateIsDownloaded, setTemplateIsDownloaded] = useState(false)
    const [downloadCall, setDownloadCall] = useState(0)

    /**
     * Download existing excel template
     * <p>
     * use downloadCall state to assert that download is called only once
     */
    const download = async () => {
        setDownloadCall(1);
        let fileName = `${props.dataCollection.name}_${props.marketNode.name}_${props.category['categoryDTO'].name.replaceAll(" ", "_")}.xlsx`
        await downloadExcel(fileName, props.category.template)
            .then(_ => {
                setTemplateIsDownloaded(false);
            })
    }

    /**
     * Check if template generation is in progress
     * - if true : set button loading
     * - else : generation has finished
     * <p>
     *     if button is already loading ( there was a generation in progress ) download the generation result
     * <p>
     *     else stop button loading
     * */
    useEffect(() => {
        if (isTemplateGenerationInProgress(props.category)) {
            setTemplateIsDownloaded(true);
        } else if (isTemplateGenerationFailed(props.category)) {
            setTemplateIsDownloaded(false);
        } else if (templateIsDownloaded && downloadCall === 0) {
            download().then();
        } else {
            setTemplateIsDownloaded(false);
        }
    }, [props.category, downloadCall])

    /**
     * Call generate template service
     * - if response is 'true' (call is in waiting execution list) : update categories and continue loading
     * - else : stop loading button
     * */
    const generateTemplate = async () => {

            setTemplateIsDownloaded(true);
            setDownloadCall(0);
            let fileName = `${props.dataCollection.name}_${props.marketNode.name}_${props.category['categoryDTO'].name.replaceAll(" ", "_")}.xlsx`
            try {
                await generateTemplateExcel({
                    "modelPath": props.modelPath,
                    "category": {id: props.category['categoryDTO'].id, name: props.category['categoryDTO'].name},
                    "sheets": [],
                    "copiedFrom": {dcId: 0, reuseData: false},
                    "dataCollection": props.dataCollection,
                    "marketNode": props.marketNode
                }, fileName)
                    .then(isDownloaded => {
                        setTemplateIsDownloaded(isDownloaded);
                        if (isDownloaded) {
                            props.findByDCAndMN()
                        }
                    });
            } catch (e) {
                showError("Error", `An error occurs when generating ${props.category['categoryDTO'].name} template.`);
                setTemplateIsDownloaded(false);
            }

    }

    return (

        <div className="flex align-items-center justify-content-center">
            <div className="grid">
                <div className="col-12 flex align-items-center justify-content-center">
                    <span>
                    <label className={classes.title}>Template generation</label>
                </span>
                </div>
                <div className="col-12 flex align-items-center justify-content-center">
                    <DownloadButton templateIsDownloaded={templateIsDownloaded}
                                    downloadTemplate={() => generateTemplate()}
                                    dataCollection={props.dataCollection}
                    />
                </div>
                {props.dataCollection.copiedFrom !== null && props.dataCollection.copiedFrom.reuseData
                    ? (<div className="col-12 flex align-items-center justify-content-center mt-0 mb-0 pt-0 pb-0">
                        <span className="pi pi-info-circle pl-1"/>
                        <small className={classes.small}>Copied from Data Collection
                            "{props.dataCollection.copiedFrom.copiedFromDc.name}"</small>
                    </div>)
                    : null}

            </div>
        </div>
    )
}

export default TemplateGen;
