import React, {useEffect, useState} from "react";
import Auxiliary from "../../../hoc/Auxiliary";
import HeaderBreadCrumb, {normalizePathItem} from "../../../components/BreadCrumb/Breadcrumb";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {retrieveSparqlQueries} from "../../../api/dataCollection/sparql-queries-service";

import {Card} from "primereact/card";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import QueryConfiguration
    from "../../../components/StudyTeams/DataCollectionList/ActionTemplate/QueryConfiguration/QueryConfiguration";
import {findDataCollectionById} from "../../../api/dataCollection/dataCollection-service";
import {loadState, removeFromSessionStorage} from "../../../api/State/manage-state-storage-service";
import {showError} from "../../../api/notification/ToastManager";
import {STUDY_TEAM_SPACE_PATH} from "../../../api/static-data/Configurations";

const DataCollectionQueriesForStudyTeams = () => {
    const [crumbs, setCrumbs] = useState([]);
    const [queries, setQueries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dcMarketNodes, setDcMarketNodes] = useState([]);
    const {state} = useLocation();
    const dataCollection = loadState(state, "dataCollection");
    const urlParams = useParams();
    const navigate = useNavigate();

    const fetchQueries = async (dcId) => {
        let data = [];
        await retrieveSparqlQueries(dcId)
            .then(res => {
                if (res.status === 200) {
                    data = res.content;
                } else {
                    navigate(STUDY_TEAM_SPACE_PATH);
                }
            })
        return data;
    }
    const findMarketNodesByDC = (dcId) => {

        findDataCollectionById(dcId).then(r => {
            let m = []
            r.participants.forEach(x => {

                m.push(x.marketNode)

            });

            let marketNodes = m.filter((ele, ind) => ind === m.findIndex(elem => elem.name === ele.name))
            setDcMarketNodes(marketNodes)
        })
    }
    useEffect(() => {
        try {
            if (urlParams?.dcName && urlParams.dcName.toLowerCase() === normalizePathItem(dataCollection?.name)) {
                setCrumbs(["Study team space", dataCollection?.name, "Queries"])
                fetchQueries(dataCollection?.id).then(res => {
                    setQueries(res);
                    findMarketNodesByDC(dataCollection?.id);
                    setIsLoading(false);

                }).catch(_ => {
                    onResponseError("Failed to retrieve queries for dataCollection " + dataCollection?.name)
                });
            } else {
                onResponseError("Access to dataCollection queries details from address bar is not authorized")
            }
        } catch (e) {
            onResponseError("An error occurs while displaying 'Queries' page for dataCollection " + dataCollection?.name)
        }
    }, [])

    const onResponseError = (message) => {
        showError("Error loading page", message)
        removeFromSessionStorage("dataCollection");
        navigate(STUDY_TEAM_SPACE_PATH);
    }

    useEffect(() => {
        const status = queries
            .map(q => q["executions"])
            .flat()
            .map(ex => ex.status.code);
        if (status.includes('EXECUTION_IN_PROGRESS')) {
            setTimeout(() => {
                updateQueries();
            }, 30000)
        }
    }, [queries])


    const updateQueries = () => {
        fetchQueries(dataCollection?.id).then(res => {
            setQueries(res);
        });
    }

    const content = isLoading
        ? (<LoadingScreen/>)
        : (<Auxiliary>
            <HeaderBreadCrumb items={crumbs}/>
            <br/>
            {queries.length > 0 ?
                queries.map((e, i) => (
                    <div className="mb-3" key={`cat-${i + 1}`}>
                        <QueryConfiguration dcMarketNodes={dcMarketNodes} query={e} updateQueries={updateQueries}/>
                    </div>
                ))
                :
                <Card role="region">
                    No query is defined !
                </Card>

            }
        </Auxiliary>)

    return (
        <Auxiliary>
            {content}
        </Auxiliary>)

}
export default DataCollectionQueriesForStudyTeams;
