import React, {useEffect, useState} from "react";
import Aux from "../../../../../hoc/Auxiliary";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import classes from "../SparqlQuery.module.css";
import ParamDialog from "./ParameterDialog/ParamDialog";
import {categories, checkPropertiesValidity} from "../../../../../api/utils/SparqlQueries";
import {removeNonAlphaNumeric} from "../../../../../api/utils/Strings";


const SparqlParameter = ({
                             param,
                             allParameters,
                             addNewParameter,
                             deleteParameter,
                             updateParameter,
                             index,
                             readOnly
                         }) => {
    const [isPramDialogVisible, setIsPramDialogVisible] = useState(false);
    const [isValid, setIsValid] = useState(checkPropertiesValidity(param.properties));

    useEffect(() => {
        if (allParameters.length === (index + 1)) {
            setIsValid(checkPropertiesValidity(param.properties))
        } else {
            setIsValid(false);
        }
    }, [param.properties, allParameters])

    return (
        <Aux>
            <div className="grid">
                <div className="col-4 mr-5">
                    <InputText name="param-name"
                               value={param.name}
                               placeholder={"Parameter name"}
                               onChange={(e) => {
                                   const pp = param.properties?.type === undefined ? {type: categories[1].name} : {...param.properties};
                                   updateParameter(removeNonAlphaNumeric(e.target.value), pp)
                               }}
                    />
                </div>
                <div className="col-3">
                    <Button type="button"
                            className={classes.customButton}
                            icon="pi pi-pencil"
                            onClick={() => {
                                setIsPramDialogVisible(true)
                            }}
                            disabled={param.name.length === 0}

                    />
                </div>
                <div className="col-2">

                    <Button type="button"
                            className={classes.customButton}
                            icon="pi pi-trash"
                            onClick={deleteParameter}
                    />
                </div>
                <div className="col-2">
                    <Button type="button"
                            className={classes.customButton}
                            icon="pi pi-plus"
                            disabled={!isValid}
                            onClick={addNewParameter}

                    />
                </div>
            </div>
            <ParamDialog parameter={param}
                         isDialogVisible={isPramDialogVisible}
                         closeDialog={(e) => {
                             updateParameter(param.name, {...e})
                             setIsPramDialogVisible(false)
                         }}/>
        </Aux>
    )
}
export default SparqlParameter;
