import React, {useEffect, useState} from "react";
import {searchAllModels} from "../../api/DataModel/data-model";
import PemmdbVersionsControl from "../../components/PemmdbVersion/PemmdbVersionsControl";
import {useNavigate} from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {onServerCallError} from "../../api/utils/errors";
import {ERROR_PATH, NOT_AUTHORIZED_PATH} from "../../api/static-data/Configurations";

const PemmdbVersionBuilder = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [versions, setVersions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        try {
            searchAllModels()
                    .then(res => {
                        if (res.status === 403) {
                            navigate(NOT_AUTHORIZED_PATH);
                        }
                        if (res.content !== null) {
                            setVersions(res.content)
                        }
                        setIsLoading(false);
                    })
                .catch(_ => {
                    onServerCallError("Error loading page", "An Error occur when loading PEMMDB versions",
                        () => {
                            navigate(ERROR_PATH)
                        });
                })
        } catch (e) {
            onServerCallError("Error loading page", "An Error occur when loading PEMMDB versions",
                () => {
                    navigate(ERROR_PATH)
                });
        }
        }, []
    );

    let content = isLoading
        ? (<LoadingScreen/>)
        : (<PemmdbVersionsControl allVersions={versions} setVersions={(e) => setVersions(e)}/>)
    return <div>{content}</div>
};

export default PemmdbVersionBuilder;
