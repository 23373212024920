import React from "react";
import DCButton from "../Button/DCButton";
import {convert_dd_mm_yyyy_to_Date} from "../../../../api/utils/DateFormatter";
import {normalizePathItem} from "../../../BreadCrumb/Breadcrumb";

const DataCollectionDetailsButton = ({rowData}) => {
    const isDisabled = convert_dd_mm_yyyy_to_Date(rowData.startFrom) >= new Date();
    return (<DCButton
        rowData={rowData}
        icon={"pi pi-angle-right"}
        tooltipText={`Data collection details\n${isDisabled ? "Will be enabled when dataCollection starts." : ""}`}
        navigationPath={"/data_collections/" + normalizePathItem(rowData?.name)}
        disabled={isDisabled}
    />)
}

export default DataCollectionDetailsButton;
