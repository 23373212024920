import React, {useCallback, useEffect, useState} from "react";
import Aux from "../../../../../hoc/Auxiliary"
import {DataTable} from "primereact/datatable";
import TemplatePaginator from "../../../../commun/TemplatePaginator/TemplatePaginator";
import {Column} from "primereact/column";
import classes from "../DataVisualisation.module.css";
import {Button} from "primereact/button";
import {getDashboardsByDC, shareDashboard} from "../../../../../api/dataCollection/dataCollection-service";
import {isAuthorized} from "../../../../../api/authorization/authorization-service";
import {showError} from "../../../../../api/notification/ToastManager";
import LongTextColumn from "../../../../commun/Table/LongTextColumn/LongTextColumn";


const Dashboards = (props) => {
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [sharedId, setSharedId] = useState("");
    const [dashboardsList, setDashboardsList] = useState([])


    const share = (rowData) => {
        setSharedId(rowData.id)
        shareDashboard({
            "dashboardInfo": rowData,
            "dataCollectionName": props.dcName
        }).then(r => {
            retrieveDashboards();
            setSharedId("")
        }).catch(err => {
            setSharedId("")
        })
    }

    const retrieveDashboards=()=>{
        getDashboardsByDC({"dataCollectionName": props.dcName, "groups": props.groups})
            .then((r) => {
                if (r.status === 200) {
                    setDashboardsList(r.data.content);
                } else {
                    props.setIsError(true);
                }
            })
            .catch(_ => {
                showError("Error", "An error was occurred when retrieving dashboard for data collection " + props.dcName);
            })
    }

    const descriptionBody = (row) => (<LongTextColumn row={row} fieldName={"description"} idField={"id"}/>)

    const check = (rowData) => {
        return rowData.isShared === "Yes";
    }
    useEffect(() => {
        retrieveDashboards();
    }, [props.dcName, props.groups])

    const Action = (rowData) => {
        return (<div key={rowData.id} className=" col p-0 m-0 flex align-items-center justify-content-center">
            <Button key={rowData.id} type="button" className={classes.actionBtn} onClick={() => share(rowData)}
                    label="Share Dashboard"
                    loading={rowData.id === sharedId}
                    disabled={check(rowData)}
            /></div>);
    }
    const Title = (rowData) => {
        return (<h5><a
            href={global.config.KIBANA_URL + "/s/" + rowData.namespace + "/app/dashboards#/view/" + rowData.id}
            target="_blank" className="underline-link">{rowData.title}</a></h5>);
    }
    const onCustomPage = useCallback((event) => {
        setFirst(event.first);
        setRows(event.rows);
    }, [])

    return (
        <Aux>
            {
                isAuthorized("AR030") ? <div>
                    <div className=" card">
                        <DataTable
                            tableClassName={"justify-content-center"}
                            value={dashboardsList}
                            size="small"
                            resizableColumns
                            stripedRows
                            showGridlines
                            paginator
                            paginatorTemplate={TemplatePaginator}
                            first={first}
                            rows={rows}
                            onPage={onCustomPage}
                            paginatorClassName="justify-content-end" className="mt-6"
                            responsiveLayout=" scroll">
                            <Column field='title' header="Name" body={Title}
                                    style={{width: '400px'}} filter/>
                            <Column sortable field="description" header="Description"
                                    style={{
                                        maxWidth: '500px',
                                        width: '500px'
                                    }} body={descriptionBody}/>
                            <Column sortable field="createdIn" header="Created In"
                                    style={{width: '400px'}} filter/>
                            <Column sortable field="isShared" header="Is Shared"
                                    style={{width: '400px'}} filter/>
                            <Column header="Actions" body={Action} style={{width: '300px'}}
                                    headerStyle={{width: '300px'}} alignHeader={"center"}/>


                        </DataTable>
                    </div>
                </div> : <div>
                    <div className=" card">
                        <DataTable
                            tableClassName={"justify-content-center"}
                            value={dashboardsList}
                            size="small"
                            resizableColumns
                            stripedRows
                            showGridlines
                            paginator
                            paginatorTemplate={TemplatePaginator}
                            first={first}
                            rows={rows}
                            onPage={onCustomPage}
                            paginatorClassName="justify-content-end" className="mt-6"
                            responsiveLayout=" scroll">
                            <Column field="title" body={Title} header="Name"
                                    style={{width: '400px'}} filter/>
                            <Column sortable field="namespace" header="Space"
                                    style={{width: '400px'}} filter/>
                            <Column sortable field="description" header="Description"
                                    style={{
                                        maxWidth: '500px',
                                        width: '500px'
                                    }} body={descriptionBody}/>
                            <Column sortable field="createdIn" header="Created In"
                                    style={{width: '400px'}} filter/>

                        </DataTable>
                    </div>
                </div>

            }


        </Aux>

    )
}

export default Dashboards;
