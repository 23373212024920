import React from "react";
import classes from "./ActionsList.module.css"
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";

const actions = (props) => {

    return (
        <div className="grid p-0 m-0">
            <div className="col-4"></div>
            <div className="col-8 text-right  mr-0 pr-0">
                <Button type="button" className={classes.actionBtn}
                        disabled={props.publishIsDisable}
                        label="Publish Data Collection"
                        onClick={props.saveAsPublished}
                        loading={props.buttonsLoadingState.Published}/>
                {!props.draftIsHidden && <Button type="button" className={classes.actionBtn}
                                                 disabled={props.draftIsDisable}
                                                 label="Draft"
                                                 hidden={props.draftIsHidden}
                                                 onClick={props.saveAsDraft}
                                                 loading={props.buttonsLoadingState.Draft}/>}
                <Button type="button" className={classNames(classes.actionBtn, classes.noMarginRight)}
                        label="Abort"
                        onClick={props.abort}
                        loading={false}
                        disabled={props.buttonsLoadingState.Published}/>
            </div>
        </div>
    )

}

export default actions;
