import React, {useState} from "react";
import {DataTable} from "primereact/datatable";
import TemplatePaginator from "../../../commun/TemplatePaginator/TemplatePaginator";
import {Column} from "primereact/column";
import {ConfirmDialog} from "primereact/confirmdialog";
import ModelVersionColumn from "../ModelVersionColumn/ModelVersionColumn";
import DraftActionButtons from "./ActionBodyTemplate/DraftActionButtons";

const DraftDataCollections = ({draftList, onCustomPage, first, rows, header, dcListDraft, setDraftList}) => {

    const [idDeleting, setIdDeleting] = useState([]);
    const isSelectable = (id) => !idDeleting.includes(id);
    const rowClassName = (data) => {
        return isSelectable(data.id) ? '' : 'p-disabled'
    };
    const modelVersionColumnTemplate = (rowData) => (<ModelVersionColumn rowData={rowData}/>)

    const actionsBodyTemplateForDraft = (rowData) => (idDeleting.includes(rowData.id)?
        <div className="flex justify-content-around">
            <span className="vertical-align-middle">Deleting</span>
            <i className="pi pi-spin pi-spinner" style={{fontSize: '1.2rem', padding: '0.15rem'}}></i></div> :
        <DraftActionButtons rowData={rowData} dcListDraft={dcListDraft} setDraftList={setDraftList} setIdDeleting={setIdDeleting} idDeleting={idDeleting}/>)

    return (<div>
        <div className=" card">
            <DataTable value={draftList}
                       header={header}
                       size="small"
                       resizableColumns
                       stripedRows
                       showGridlines
                       paginator paginatorTemplate={TemplatePaginator}
                       first={first} rows={rows} onPage={onCustomPage}
                       paginatorClassName="justify-content-end" className="mt-6"
                       rowClassName={rowClassName}
            >
                <Column  field="name" sortable header="Name"
                        filter  style={{minWidth: "8rem",maxWidth:"10rem",whiteSpace:'normal',wordWrap:'break-word'}}/>
                <Column  field="startFrom" header="Open from" style={{width: "6rem"}}/>
                <Column  field="deadline" header="Deadline" style={{width: "6rem"}}/>
                <Column  field="participatingCountries"
                        header="Total participants" style={{width: "8rem"}}/>
                <Column  body={modelVersionColumnTemplate}
                        header="PEMMDB Version"
                        style={{minWidth: "8rem",maxWidth:"10rem",whiteSpace:'normal',wordWrap:'break-word'}}/>
                <Column field="copiedFrom.copiedFromDc"
                        header="Copied From"/>
                <Column  body={actionsBodyTemplateForDraft} header="Actions " style={{minWidth: "10rem",width:'10rem'}}/>
            </DataTable>
            <ConfirmDialog/>
        </div>
    </div>)
}
export default DraftDataCollections;
