import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from 'primereact/dialog';
import classes from "./ReopenDC.module.css";
import {isAuthorized} from "../../../../api/authorization/authorization-service";
import {sortAlphabetically} from "../../../../api/utils/sortArrays";
import {MultiSelect} from "primereact/multiselect";
import {Chip} from "primereact/chip";
import CalendarField
    from "../../../DataCollection/DataCollectionCreator/Details/OpenFromDeadLine/CalendarField/CalendarField";
import {InputTextarea} from "primereact/inputtextarea";
import {
    isInDataPersistedStep,
    isInManualCheckCompleted,
    isInManualCheckStep,
    isTemporaryClosed
} from "../../../../api/dataCollection/dataCollection-status";
import {canOpenDataCollection} from "../../../../api/authorization/rules";
import {reopenDataCollection} from "../../../../api/dataCollection/dataCollection-service";
import {showError} from "../../../../api/notification/ToastManager";
import {useNavigate} from "react-router-dom";

const ReopenDCButton = (props) => {
    const [display, setDisplay] = useState(false);
    const [initialMarketNodes, setInitialMarketNodes] = useState([]);
    const [selectedMarketNodes, setSelectedMarketNodes] = useState([]);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [resendInvitationBtnEnabled, setResendInvitationBtnEnabled] = useState(isTemporaryClosed(props.dataCollection?.status)
        || isInManualCheckStep(props.dataCollection?.dataStatus)
        || isInDataPersistedStep(props.dataCollection?.dataStatus)
        || isInManualCheckCompleted(props.dataCollection?.dataStatus)
    );
    const [disabledSendBtn, setDisabledSendBtn] = useState(false);
    const [btnSaveLoading, setBtnSavedLoading] = useState(false);
    const navigate = useNavigate();
    const [newDeadline, setNewDeadline] = useState(new Date());

    useEffect(() => {
        if(new Date(props.dataCollection.deadline) < new Date()){
            setNewDeadline(new Date());
        } else {
            setNewDeadline(new Date(props.dataCollection.deadline));
        }
    }, []);
    const checkReopenButtonVisibility = () => {
        return isAuthorized("AR01");
    }
    const handleDelete = (p) => {
        let s = selectedMarketNodes.filter(i => i.marketNode.name !== p.marketNode.name);
        setSelectedMarketNodes(s);
    };
    const handleChange = (event) => {
        setNewDeadline(event.target.value)
    }
    const send = () => {
        setBtnSavedLoading(true);
        if (canOpenDataCollection()) {

            reopenDataCollection(props.dataCollection, selectedMarketNodes, newDeadline, notificationMsg)
                .then((s) => {
                    setDisplay(false);
                    navigate("/data_collections")
                })
                .catch(_ => {
                    setDisplay(false);
                    showError("Error", "An error was occurred when reopening data collection " + props.dataCollection.name);
                })
        } else {
            showError("Unauthorized", "You are not authorized to reopen data collection " + props.dataCollection.name)
        }

    }

    const footer = (
        (<div>
            <Button className={classes.btnStyle} onClick={send} icon="pi pi-check"
                    disabled={selectedMarketNodes.length === 0 || newDeadline === null}
                    loading={btnSaveLoading} label="Send"/>

            <Button className={classes.btnStyle}
                    onClick={() => manageHideDialog()}
                    disabled={btnSaveLoading} label="Close"/></div>)

    );
    const manageSendBtn = () => {
        selectedMarketNodes.length === 0 ?
            setDisabledSendBtn(true) : setDisabledSendBtn(false);
    }
    const manageHideDialog = () => {
        setDisplay(false);
        setSelectedMarketNodes([])
    }

    function hasPublishedMarketNode(marketNodes) {
        const hasPublishedOrPersisted = marketNodes.filter(m => m.marketNodeStatus.code === "PUBLISHED" || m.marketNodeStatus.code === "DATA_PERSISTED")

        return hasPublishedOrPersisted.length > 0;
    }

    useEffect(() => {
        manageSendBtn();
        isTemporaryClosed(props.dataCollection?.status)
        || isInManualCheckStep(props.dataCollection?.dataStatus)
        || isInDataPersistedStep(props.dataCollection?.dataStatus)
        || isInManualCheckCompleted(props.dataCollection?.dataStatus)
        || hasPublishedMarketNode(props.marketNodes) ?
            setResendInvitationBtnEnabled(true) : setResendInvitationBtnEnabled(false)
    })
    const mns = [];

    function initializeMarketNodes() {
        const isTemporaryClosedDataCollection = props.dataCollection.status.code === "TEMPORARILY_CLOSED"
        for (const m of props.marketNodes) {
            if (isTemporaryClosedDataCollection || m.marketNodeStatus.code === "PUBLISHED" || m.marketNodeStatus.code === "DATA_PERSISTED") {
                mns.push({
                    "id": m.id,
                    "marketNode": m.marketNode
                })
            }

        }
        setInitialMarketNodes(mns);
    }

    const reopenDC = checkReopenButtonVisibility() ? (<div className={classes.position}>
        <Button className={classes.btnStyle}
                label="Resend invitation" icon="pi pi-envelope"
                disabled={!resendInvitationBtnEnabled}
                onClick={() => {
                    initializeMarketNodes();
                    setDisplay(true)
                }}
        />
        <Dialog header="Resend invitation " visible={display} footer={footer} style={{width: '55vw'}}
                onHide={() => manageHideDialog()

                }>
            <div className="col-12">
                <p><b>Market Nodes</b></p>
                <MultiSelect name="selectedMarketNodes" fixedPlaceholder={true} value={selectedMarketNodes}
                             className={classes.selectStyle}
                             options={sortAlphabetically(initialMarketNodes, 'marketNode.name')}
                             onChange={(e) => setSelectedMarketNodes(e.value)}
                             optionLabel="marketNode.name"
                             placeholder="Select Market Nodes" filter/>
            </div>

            {
                selectedMarketNodes.map((s) => {
                    let index = 1 + (Math.random() * 1000);
                    return <Chip key={index} label={s.marketNode.name} onRemove={() => handleDelete(s)}
                                 className={"mb-2"} removable/>
                })
            }
            <div className="col-6">
                <CalendarField
                    title={" New Deadline"}
                    name={"deadline"}
                    value={newDeadline}
                    minDate={new Date()}
                    isValid={true}
                    onChange={handleChange}
                />
            </div>
            <div className="col-6">
                <p><b>Message to the participants</b></p>
                <InputTextarea name="mailMessage" rows={5}
                               cols={30}
                               autoResize
                               className={classes.textArea}
                               onChange={e => {
                                   setNotificationMsg(e.target.value);
                               }}
                />
            </div>

        </Dialog>

    </div>) : null
    return (
        <div>{reopenDC}</div>
    )
}
export default ReopenDCButton;
